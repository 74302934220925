import Joi from 'joi';
import * as contacts from './contact_base';
import joiPhoneNumber from 'joi-phone-number'

const joiPhoneNumberVal = Joi.extend(joiPhoneNumber);

export const contactUI = Joi.object({
  firstName: contacts.firstName,
  lastName: contacts.lastName,
  email: contacts.email,
  linkedinProfile: Joi.any().optional(),
  workCompany: Joi.any().optional(),
  phoneNumber: joiPhoneNumberVal.string().required().phoneNumber({ defaultCountry: 'US', strict: true}),
  zipCode: Joi.string().required(),
  birthDate: Joi.any().optional(),
}).messages({
  "any.required": "Required",
  "any.empty": "Required",
  "string.empty": "Required",
  "phoneNumber.invalid": "Not a valid phone number"
});

export const roleUI = Joi.object({
  roleId: Joi.number().min(1).integer(),
}).messages({
  "*": "Required",
});

export const mentorRoleUI = Joi.object({
  mentorRole: Joi.number().min(1).integer(),
}).messages({
  "*": "Required",
});

export const educationInfoUI = Joi.object({
  university: Joi.string().required(),
  firstMajorDegree: Joi.string().required(),
  secondMajorDegree: Joi.any().optional(),
}).messages({
  "any.required": "Required",
  "any.empty": "Required",
  "string.empty": "Required",
});

export const personalInfoUI = Joi.object({
  hasDisability: Joi.boolean(),
genderId: Joi.number().min(1).integer(),
minorityId: Joi.number().min(0).integer(),
otherGender: Joi.string().allow(''),
otherMinority: Joi.string().allow(''),
}).messages({
  "any.required": "Required",
  "any.empty": "Required",
  "string.empty": "Required",
  "*": "Required",
});

export const profileCompleteUI = Joi.object({
  sourceId: Joi.number().min(1).integer(),
}).messages({
  "any.required": "Required",
  "any.empty": "Required",
  "string.empty": "Required",
  "*": "Required",
});

export const enneagramUI = Joi.object({
  enneagramScoreId: Joi.number().min(1).integer(),
}).messages({
  "any.required": "Required",
  "any.empty": "Required",
  "string.empty": "Required",
  "*": "Required",
});


export const emptySchemaUI = Joi.object({
  //enneagramScoreId: Joi.string().allow(''),
}).messages({
  "any.required": "Required",
  "any.empty": "Required",
  "string.empty": "Required",
  "*": "Required",
});
