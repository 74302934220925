import { useState, useRef } from 'react';
import { Modal, Button, } from 'react-bulma-components';
import ManualMatch from './ManualMatch';

function ManualMatchModal({ show, onOk, onClose, groupId }) {
  const manualMatchCompRef = useRef(null);
  const [ isMatching, setIsMatching ] = useState(false);
  const [ matchingAvailable, setMatchingAvailable ] = useState(false);

  async function close() {
    const doneResult = await manualMatchCompRef.current.onDoneClicked();
    if (doneResult) {
      if (onClose) {
        onClose();
      }
      else {
        console.error('No onClose function provided');
      }
    }
  }

  async function cancel() {
    if (onClose) {
      onClose();
    }
    else {
      console.error('No onCancel function provided');
    }
  }

  return (
    <Modal showClose={true} show={show}>
      <Modal.Card>
        <Modal.Card.Header showClose={false}>
          <p className="modal-card-title">Manually Match</p>
          <button className="delete" aria-label="close" onClick={() => cancel(false)}></button>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <ManualMatch groupId={groupId} ref={manualMatchCompRef} onIsMatching={setIsMatching} onMatchingAvailable={setMatchingAvailable} />
        </Modal.Card.Body>
        <Modal.Card.Footer className="modal-card-foot is-justify-content-right">
          <Button color="danger" outlined={true} onClick={() => cancel(false)}>Cancel</Button>
          <Button className="is-success" onClick={close} disabled={matchingAvailable} loading={isMatching} >Match</Button>
          {/* <Button className="is-success" disabled={!manualMatchMentorId || !manualMatchMenteeId} loading={manualMatchInProgress} onClick={doManualMatch}>Match</Button> */}
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>

  )
};

export default ManualMatchModal;