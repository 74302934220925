import { useState, useEffect} from 'react';
import { getOffboardingQuestionsPages, getOffboardingQuestions, addOffboardingQuestionsPage, editOffboardingQuestionsPage, addOffboardingQuestionToGroup, editOffboardingQuestionForGroup, 
  removeOffboardingQuestion, deleteOffboardingQuestionsPage, swapOffboardingQuestionsPages, swapOffboardingQuestions } from '../../lib/api';

import Questions from './Questions';

function OffboardingQuestions({groupId}) {
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    let pages = [];
    if (groupId > 0) {
      setIsLoading(true);
      getOffboardingQuestionsPages(groupId).then((thePages) => {
        pages = thePages;
        return getOffboardingQuestions(groupId);
      })
        .then((questions) => {
          questions = questions || [];

          let stats = { totalPages: pages.length, totalQuestions: 0, totalAnyone: 0, totalMentee: 0, totalMentor: 0};

          questions.forEach((question, idx) => {
            let group = pages.find(f => f.pageId === question.pageId);
            if (group) {
              if (!group?.questions) group.questions = [];
              group.questions.push({...question, pageId: group.pageId});
              stats.totalQuestions++;

              if (question.userType === 1) stats.totalMentee++;
              if (question.userType === 2) stats.totalMentor++;
              if (question.userType === 3) stats.totalAnyone++;
            }
          });

          setPages(pages);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [groupId])

  async function handleOnSavePage(pageId, page) {
    if (!pageId) {
      return addOffboardingQuestionsPage(groupId, page);
    }
    else {
      return editOffboardingQuestionsPage(groupId, page.pageId, page);
    }
  }

  async function handleOnQuestionForPage(isEditing, questionId, optional, isShared, pageId) {
    let data = {};
    let func = null;
    if (isEditing) {
      // Editing
      data = {
        id: questionId,
        optional: optional,
        shared: isShared,
      }
      func = editOffboardingQuestionForGroup;
    }
    else {
      // Adding
      if (groupId > 0 && questionId > 0) {
        data = {
          id: questionId,
          pageId: pageId,
          optional: optional,
          shared: isShared,
        }
        func = addOffboardingQuestionToGroup;
      }
    }

    if (func) {
      return func(groupId, data)
    }

    return Promise.reject();
  }

  async function handleOnDeleteGroupMetricsPage(groupId, pageId) {
    return deleteOffboardingQuestionsPage(groupId, pageId);
  }

  async function handleOnRemoveQuestionFromPage(questionId) {
    return removeOffboardingQuestion(questionId, groupId);
  }

  async function handleOnSwapPages(groupId, firstPageId, secondPageId) {
    return swapOffboardingQuestionsPages(groupId, firstPageId, secondPageId);
  }

  async function handleOnSwapQuestions(groupId, firstQuestionId, secondQuestionId) {
    return swapOffboardingQuestions(groupId, firstQuestionId, secondQuestionId);
  }

  return <Questions groupId={groupId} isLoading={isLoading} pages={pages} setPages={setPages} onSavePage={handleOnSavePage} onQuestionToPage={handleOnQuestionForPage} onRemoveQuestionFromPage={handleOnRemoveQuestionFromPage} onDeletePage={handleOnDeleteGroupMetricsPage} 
    onSwapPages={handleOnSwapPages} onSwapQuestions={handleOnSwapQuestions} />
}

export default OffboardingQuestions;