import { useEffect, useState, useMemo } from 'react';
import { Heading, Container } from 'react-bulma-components';
import { arrayToCsvCapitalized, notProvidedString, locationString, defaultDateString, defaultDateTimeString } from '../../lib/stringHelpers.js';
import { Link } from 'react-router-dom';
import { alphaSort99 } from '../../lib/formHelpers';
import { getUserActivity } from '../../lib/api';
import { parseISO } from 'date-fns';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture.js';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import TernTable from '../../components/TernTable/TernTable';
import OnboardingProfile from '../../components/OnboardingProfile/OnboardingProfile.js';
import CreateProfileDetailsModal from '../../components/CreateProfileDetails/CreateProfileDetailsModal.js';

function UserProfile(props) {
  const [activityData, setActivityData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [viewingTab, setViewingTab] = useState('profile');
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);

  const contactId = props.profile.contactId;

  const isMentor = props.profile.activeProfile.contactType.id === 2;

  console.log('props.profile', props.profile)
  useEffect(() => {
    async function getTheActivity() {
      const res = await getUserActivity(contactId);
      setActivityData(res);
    }

    getTheActivity();
  }, [contactId])

  const data = useMemo(
    () => tableData,
    [tableData]
  )

  useEffect(() => {
    setTableData(activityData.filter(f => f.event_name !== 'session created').map((item) => {
      return {
        timestamp: parseISO(item.event_date),
        eventname: item.event_name,
        eventdata: getDataText(item),
      }
    }));
  }, [activityData])

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'timestamp',
        sortType: 'datetime',
        Cell: props => defaultDateTimeString(props.value)
      },
      {
        Header: 'Activity',
        accessor: 'eventname',
        Cell: props => <span className="is-capitalized">{props.value}</span>
      },
      {
        Header: 'Data',
        accessor: 'eventdata',
      },
    ],
    []
  )

  function getDataText(data) {
    let textToReturn = '';

    if (data.event_name === 'session created') {
      if (data.event_data_3) {
        return `Session ${data.event_json.session_num} scheduled for ${defaultDateString(parseISO(data.event_data_3))}`;
      }
      else {
        return `Session ${data.event_json.session_num} (Not scheduled yet)`;
      }
    }
    else if (data.event_name === 'survey completed') {
      return `Session ${data.event_json.session_num} survey completed`;
    }
    else if (data.event_name === 'email sent') {
      return `${data.event_data}`;
    }

    return textToReturn;
  }

  return (
    <Container className="is-fullwidth">
      <div className="is-flex is-justify-content-space-between" style={{ width: '100%' }}>
        <div>
          <Heading>
            {props.profile.firstName} {props.profile.lastName}
          </Heading>
          <Heading subtitle className="mb-1" style={{ textTransform: 'capitalize' }}>{props.profile.activeProfile.contactType.description} {props.profile.preferredPronouns && `(${props.profile.preferredPronouns})`}</Heading>
          <Heading subtitle style={{ textTransform: 'capitalize', marginBottom: '0.75rem' }}><Link to={`/groups/${props.profile.group.id}?tab=users`}>{props.profile.group.name}</Link></Heading>
 
          <div style={{ lineHeight: '1.25em' }}>
            <ContactInfo profile={props.profile} showCopyEmail={true} />
          </div>
          <button className="button is-info is-outlined" onClick={() => setShowContactInfoModal(true)}>Change User Info</button>
        </div>
        <div className="" style={{ textAlign: 'right', height: 250, width: 250 }}>
          <ProfilePicture picture={props.profile.profilePic} />
        </div>
      </div>

      <div className="tabs is-medium">
        <ul>
          <li className={`${viewingTab === 'profile' ? ' is-active' : ''}`} onClick={() => setViewingTab('profile')}><a>Profile</a></li>
          <li className={`${viewingTab === 'activity' ? ' is-active' : ''}`} onClick={() => setViewingTab('activity')}><a>Activity</a></li>
        </ul>
      </div>
      {viewingTab === 'profile' &&
        props.profile.groupId !== 1 ? <OnboardingProfile contactId={props.profile.contactId} groupId={props.profile.group.id} />
        :
        <>
          <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap">

            <div className="info-block constrain-info-block">
              <Heading size={4} className="has-text-grey">Personal Details</Heading>
              <ul>
                <li><label>Birthdate:</label>{notProvidedString(defaultDateString(props.profile.birthDate))}</li>
                <li><label>ZIP code:</label>{notProvidedString(props.profile.zipCode)}</li>
                <li><label>Identifies As:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.identities.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherRace, props.profile.infoSheet.otherGender))}</li>
                <li><label>Languages:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.languages.map(e => e.description).sort(alphaSort99)))}</li>
              </ul>
            </div>
            
            <div className="info-block constrain-info-block">
              <Heading size={4} className="has-text-grey">Location</Heading>
              <ul>
               {isMentor && <li><label>Company:</label>{notProvidedString(props.profile.infoSheet?.workCompany)}</li>}
                {/* {isMentor && <li><label>Roles:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.roleTypes.map(e => e.description).sort(alphaSort99)))}</li>} */}
                {isMentor && <li><label>Work Location:</label>{notProvidedString(locationString(props.profile.infoSheet?.workCity, props.profile.infoSheet?.workState, props.profile.infoSheet?.workCountry))}</li>}
                <li><label>Location:</label>{notProvidedString(locationString(props.profile.infoSheet?.currentCity, props.profile.infoSheet?.currentState, props.profile.infoSheet?.currentCountry))}</li>
                <li><label>Hometown:</label>{notProvidedString(locationString(props.profile.infoSheet?.homeTown, props.profile.infoSheet?.hometownState, props.profile.infoSheet?.hometownCountry))}</li>
              </ul>
            </div>

            <div className="info-block constrain-info-block">
              <Heading size={4} className="has-text-grey">Education</Heading>
              <ul>
                {isMentor ?
                  <li><label>Alma Mater:</label>{props.profile.education.university}</li>
                  :
                  <li><label>University:</label>{props.profile.education.university}</li>
                }
                <li><label>Graduation Date:</label>{notProvidedString(defaultDateString(props.profile.education.graduationDate))}</li>
                <li><label>Major Degrees:</label>{arrayToCsvCapitalized(props.profile.education.majorDegrees)}</li>
                <li><label>Minor Degrees:</label>{notProvidedString(props.profile.education.minorDegrees)}</li>
                <li><label>Advanced Degrees:</label>{notProvidedString(props.profile.education.advancedDegrees)}</li>
              </ul>
            </div>

          </div>

          <div className="info-block">
              <Heading size={4} className="has-text-grey">Interests</Heading>
              <ul>
                <li><label>Personal Interests:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.personalInterests.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherPersonalInterest))}</li>
                <li><label>Business Interests:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.businessInterests.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherBusinessInterest))}</li>
                <li><label>Affinity Groups:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.affinityGroups.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherAffinityGroup))}</li>
              </ul>
            </div>

          <div className="info-block">
            <Heading size={4} className="has-text-grey">Statements</Heading>

            <ul>
              {isMentor && <li><label>Mentor Roles:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.mentorRoles.map(e => e.description).sort(alphaSort99)))}</li>}
              <li><label>Goals Statement:</label>{notProvidedString(props.profile.infoSheet?.goalsStatement)}</li>
              {!isMentor && <li><label>Formative Experience:</label>{notProvidedString(props.profile.infoSheet?.formativeExperience)}</li>}
              {!isMentor && <li><label>Career Aspiration:</label>{notProvidedString(props.profile.infoSheet?.careerInspiration)}</li>}
              {!isMentor && <li><label>Strengths:</label>{notProvidedString(props.profile.infoSheet?.strength)}</li>}
              {isMentor && <li><label>Advice:</label>{notProvidedString(props.profile.infoSheet?.reflectiveAdvice)}</li>}
              <li><label>Would Like To:</label>{notProvidedString(props.profile.infoSheet?.toImprove)}</li>
              <li><label>Additional Info:</label>{notProvidedString(props.profile.infoSheet?.additionalInfo)}</li>
              <li><label>Recommendations:</label>{(props.profile.infoSheet.recommendations && props.profile.infoSheet.recommendations.length > 0) ? props.profile.infoSheet.recommendations.map((m, idx) => <div key={`recommendation_${idx}`}>{m}</div>) : 'None provided'}</li>
            </ul>
          </div>

        </>
      }

      {viewingTab === 'activity' &&
        <div>
          <TernTable columns={columns} data={data} />
        </div>
      }
      <CreateProfileDetailsModal user={props.profile} isMentor={isMentor} open={showContactInfoModal} onClose={() => setShowContactInfoModal(false)} onCancel={() => setShowContactInfoModal(false)} onSaved={() => setShowContactInfoModal(false)}/>

    </Container>
  )


}

export default UserProfile;