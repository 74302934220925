import { useAuthenticator } from '@aws-amplify/ui-react';
import { Route, Redirect } from "react-router-dom";
import MainNavNoDropdown from '../MainNavNoDropdown';

export function ProtectedRoute({ children, location, adminOnly, ...rest }) {
  const { route } = useAuthenticator((context) => [context.user]);

  // let redirectParam = '';
  // if (location.pathname && !location.pathname.includes('signout')) {
  //   redirectParam = `?redirect=${encodeURIComponent(location.pathname)}${encodeURIComponent(location.search)}`;
  // }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        ['authenticated', 'signOut'].includes(route) ? (
          <MainNavNoDropdown>
            <div className="container is-widescreen" style={{paddingTop: '3.25rem'}}>
              { children }
            </div>
          </MainNavNoDropdown>
        ) : (
          <Redirect to={{pathname: "/login", state: { from: location }}} />
        )
      }
    />)
}
