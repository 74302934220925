import { Modal } from 'react-bulma-components';
import { useState, useRef } from 'react';
import SessionComponent from '../SessionComponent/SessionComponent';

function SessionModal(props) {
  const childRef = useRef(null);

  const [isCompleting, setIsCompleting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  function completionStateChanged(newState) {
    setIsCompleting(newState);
  }

  function onFormValidChange(isValid) {
    setIsFormValid(isValid);
  }

  async function close() {
    const doneResult = await childRef.current.onDoneClicked();
    if (doneResult) {
      if (props.onClose) {
        props.onClose();
      }
      else {
        console.error('No onClose function provided');
      }
    }
  }

  return (
    <Modal show={props.open} onClose={() => props.onClose()}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title" style={{width: '97%'}}>{props.session.title}</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
            <SessionComponent groupId={props.groupId} isMentor={props.isMentor} ref={childRef} session={props.session} showTitle={false} 
              completionStateChanged={completionStateChanged} isCompleting={isCompleting} 
              onCheckSessionTime={props.onCheckSessionTime}
              formValidChange={onFormValidChange} formValid={isFormValid}
              />
          </Modal.Card.Body>
          <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
            {/* <button className="button is-danger is-outlined" onClick={() => props.onClose()}>Cancel</button> */}
            <button className="button is-success" disabled={!isFormValid} onClick={close}>Done</button>
          </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default SessionModal;