import logo from '../assets/logo-horizontal-white.png';
import { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
// import { useStore } from '../store/Store';
import { getNonProdName } from '../lib/auth';
import FeedbackModal from './FeedbackModal/FeedbackModal';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCommentAlt } from '@fortawesome/free-solid-svg-icons'

function MainNavNoDropdown({ children }) {
  // const { state } = useStore();
  const [ feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [ isActive, setisActive] = useState(false);

  const navStyle = getNonProdName() ? { backgroundColor : 'orange' } : {};

  return (
    <div style={{ paddingTop: '1.25rem' }}>
      <nav className="navbar is-fixed-top" style={navStyle} role="navigation" aria-label="main navigation">
        <div className="container is-widescreen">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src={logo} alt="TERN Mentoring Logo" style={{ paddingLeft: 20, paddingRight: 20 }} />
              {/* { getNonProdName() && <span className="is-uppercase">{getNonProdName()} Server</span>} */}
            </a>

            <a onClick={() => setisActive(!isActive)} role="button" 
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
            <div className="navbar-start">
              <NavLink className="navbar-item" to="/dashboard" exact={true} activeClassName="is-active">Dashboard</NavLink>
              <NavLink className="navbar-item" to="/users" exact={true} activeClassName="is-active">All Users</NavLink>
              <NavLink className="navbar-item" to="/groups" activeClassName="is-active">Cohorts</NavLink>
              <NavLink className="navbar-item" to="/mentorships" exact={true} activeClassName="is-active">Mentorships</NavLink>
              {/* <NavLink className="navbar-item" to="/mentorships" exact={true} activeClassName="is-active">Sales</NavLink> */}
              <NavLink className="navbar-item" to="/metrics" exact={true} activeClassName="is-active">Metrics</NavLink>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <Link to="/signout" className="button is-light">
                    Sign Out
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <FeedbackModal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)} />
      { children }
    </div>
  )
}

export default MainNavNoDropdown;