import { useState, useEffect } from 'react';
import { Box, Heading } from 'react-bulma-components';
import { arrayToCsvCapitalized, notProvidedString } from '../../lib/stringHelpers.js';
import { profileOnboardingAnswers } from '../../lib/api.js';
import ProfilePicture from '../ProfilePicture/ProfilePicture.js';
import ContactInfo from '../ContactInfo/ContactInfo.js';
import ProgressComponent from '../ProgressComponent/ProgressComponent.js';

function OnboardingProfile({ contactId, groupId }) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ onboardingData, setOnboardingData ] = useState([]);

  useEffect(() => {
    profileOnboardingAnswers(contactId, groupId)
      .then((data) => {
        console.log('profileOnboardingAnswers', data)
        setOnboardingData(data);
      })
      .catch(err => {
        console.error('Could not retrieve onboarding data', err);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [contactId, groupId])

  function answerString(answer) {
    if (answer?.answer_value && answer.answer_value.startsWith('[')) {
      try {
        return arrayToCsvCapitalized(JSON.parse(answer.answer_value));
      }
      catch {
        return notProvidedString(answer.answer_value);
      }
    }

    return notProvidedString(answer.answer_value);
  }

  return (<>
      {isLoading ? <ProgressComponent /> :
        <div>
          <div className="pb-3"><span className="tag is-success" style={{borderRadius: '100%'}}>S</span>&nbsp;&nbsp;-&nbsp;&nbsp;Shared with counterpart</div>
          { onboardingData.length > 0 ? 
            onboardingData.map(answer => {
              return <div key={`question_${answer.questionId}`} className="is-flex py-1">
                
                <div className="has-text-grey has-text-weight-bold" style={{ width: '30%', overflow: 'hidden', flexGrow: 0, flexShrink: 0 }}>{answer.name || answer.description} {answer.shared && <span className="tag is-success" style={{borderRadius: '100%', fontSize: 'xx-small', verticalAlign: 'middle'}}>S</span> } </div>
                <div className="ml-6" style={{ flexGrow: 0 }}>{answerString(answer)}</div>
              </div>
            })
          :
            <div className="mt-3 has-text-centered">No additional information available</div>
          }
        </div>
      }
    </>)
}

export default OnboardingProfile;