import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Form, Button } from 'react-bulma-components';
import Select from 'react-select';

import { getMe, getNotificationTypeNames, getCohortNotifications, updateCohortNotification, getPreviewNotificationText, sendNotificationPreview } from '../../lib/api';

function NotificationEditor({ groupId }) {
  const [emailBody, setEmailBody] = useState('');
  const [smsBody, setSmsBody] = useState('');
  const [subject, setSubject] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState();
  const [emailBodyEditorState, setEmailBodyEditorState] = useState('editor')
  const [emailBodyPreview, setEmailBodyPreview] = useState('');
  const [smsBodyEditorState, setSMSBodyEditorState] = useState('editor')
  const [smsBodyPreview, setSMSBodyPreview] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [origNotif, setOrigNotif] = useState();
  const [notificationNames, setNotificationNames] = useState([]);
  const [selectedNotificationSel, setSelectedNotificationSel] = useState();

  const reactEmailBodyQuillRef = useRef(null);
  const reactSMSBodyQuillRef = useRef(null);

  const fields = [
    { id: 'firstName', label: 'First name'},
    { id: 'lastName', label: 'Last name'},
    { id: 'email', label: 'Email'},
    { id: 'phoneNumber', label: 'Phone number'},
    { id: 'counterpartFirstName', label: 'Counterpart First name'},
    { id: 'counterpartLastName', label: 'Counterpart Last name'},
    { id: 'counterpartEmail', label: 'Counterpart Email'},
    { id: 'counterpartPhoneNumber', label: 'Counterpart phone number'},
    { id: 'counterpartType', label: 'Counterpart noun'},
    { id: 'mentorMentee', label: 'Counterpart full name'},
    { id: 'guidanceHtml', label: 'Guidance (HTML)'},
    { id: 'guidance', label: 'Guidance (plain text)'},
    // { id: 'hostname', label: 'Main site URL'},
  ].sort((a,b) => a.label.localeCompare(b.label));

  useEffect(() => {
    getCohortNotifications(groupId)
      .then(data => {
        console.log('getCohortNotifications', data);
        setNotifications(data);
        return getNotificationTypeNames(groupId);
      })
      .then(data => {
        console.log('getNotificationTypeNames', data);
        setNotificationNames(data);
      })
      .catch(err => {
        console.error('Error', err);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [groupId])

  useEffect(() => {
    if (origNotif) {
      const changes = ((subject !== origNotif.email_subject) ||
        (emailBody !== origNotif.email_html_body) ||
        (smsBody !== origNotif.sms_body))
        && subject;

      setHasChanges(changes);
      console.log(changes)
    }
  }, [origNotif, subject, emailBody, smsBody]);

  function changeToNotification(selection) {
    const notifId = selection.value;
    const notif = notifications.find(f => f.notif_type === notifId);
    setOrigNotif({...notif});

    setSelectedNotificationSel(selection);

    setSelectedNotification(notifId);
    setSubject(notif.email_subject || '');
    setEmailBody(notif.email_html_body || '');
    setSmsBody(notif.sms_body || '');
    setEmailBodyPreview('');
    setSMSBodyPreview('');
    setEmailBodyEditorState('editor');
    setSMSBodyEditorState('editor');
  }

  function insertIntoEmailBodyQuill(text) {
    const editor = reactEmailBodyQuillRef.current.getEditor();
    editor.focus();
    let range = editor.getSelection();
    let position = range ? range.index : 0;
    editor.insertText(position, '{' + text + '}');
  }

  function insertIntoSmsBodyQuill(text) {
    const position = reactSMSBodyQuillRef.current.selectionStart;
    const newText = smsBody.slice(0, position) + '{' + text + '}' + smsBody.slice(position);
    setSmsBody(newText);
  }

  async function save() {
    try {
      setIsSaving(true);
      await updateCohortNotification(groupId, selectedNotification, subject, emailBody, smsBody);
      toast.success(`Notification updated!`);
    }
    catch (err) {
      console.error('Notification update failed: ',err);
      toast.error('Notification update failed.');
    }
    setIsSaving(false);
  }

  function cancel() {

  }

  async function generatePreview(text) {
    let previewText = '';

    try {
      const previewTextRes = await getPreviewNotificationText(groupId, text);
      console.log('previewText', previewTextRes);
      previewText = previewTextRes.preview;
    }
    catch (error) {
      console.error('previewText error', error);
      toast.error('Preview could not be generated');
    }

    return previewText;
  }

  async function switchEmailEditorMode(mode) {
    if (mode !== 'preview') {
      setEmailBodyPreview('');
    }

    setEmailBodyEditorState(mode);
    if (mode === 'preview') {
      setEmailBodyPreview(await generatePreview(emailBody));
    }
  }

  async function switchSMSEditorMode(mode) {
    if (mode !== 'preview') {
      setSMSBodyPreview('');
    }

    setSMSBodyEditorState(mode);
    if (mode === 'preview') {
      setSMSBodyPreview(await generatePreview(smsBody));
    }
  }

  async function sendPreview(notifType, sendType, templateText, subject) {
    try {
      const user = await getMe(); 
      let email = sendType === 'email' ? user.email : '';
      let phoneNumber = sendType === 'sms' ? user.phoneNumber : '';
      await sendNotificationPreview(groupId, notifType, templateText, email, phoneNumber, subject);
      toast.success(`Preview sent to ${email || phoneNumber}`);
    }
    catch (err) {
      console.error('Cant get me', err);
      toast.error('Could not send preview');
    }
  }

  const formatOptionLabel = ({ value, label, data }) => (
    <div>
      <div>{label}</div>
      <div className="has-text-grey" style={{ fontSize: 'smaller' }}>
        <div>{data.notif_desc}</div>
      </div>
    </div>
  );

  const EditorModeTabs = ({editorState, onSelect, onInsertField}) => {
    return <div className="tabs is-toggle mb-0">
      <ul>
        <li className={editorState !== 'preview' ? 'is-active' : ''} onClick={() => onSelect('editor')}><a>Editor</a></li>
        <li className={editorState === 'preview' ? 'is-active' : ''} onClick={() => onSelect('preview')}><a>Preview</a></li>
      </ul>
      <Select placeholder="Insert field..." isDisabled={editorState === 'preview'} value={null} onChange={(e) => { onInsertField(e.id) }} options={fields}  menuPortalTarget={document.body}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999, right: 100 }), menu: provided => ({...provided, width: 250, right: 0}) }} />
    </div>
  }

  return <>
    {/* { isLoading ? <ProgressComponent /> :  */}
    <>
      <Form.Field>
        <Form.Label>Notification</Form.Label>
        <Select placeholder="Select notification..." isLoading={isLoading} value={selectedNotificationSel} onChange={(e) => changeToNotification(e)} formatOptionLabel={formatOptionLabel}
          options={notificationNames.map((m) => { return { value: m.notif_type, label: m.notif_name, data: m } }).sort((a,b) => a.label.localeCompare(b.label))}>
        </Select>
      </Form.Field>

      { selectedNotification && <>
        <Form.Field>
            <Form.Control className="is-expanded">
              <Form.Label>Email subject</Form.Label><Form.Input color={!subject ? 'danger' : ''} value={subject} onChange={e => { setSubject(e.target.value); }}></Form.Input>
              { !subject && <Form.Help color="danger">Subject is required</Form.Help>}
            </Form.Control>
        </Form.Field>


        <Form.Field>
          <Form.Label>Email body</Form.Label>
          <EditorModeTabs editorState={emailBodyEditorState} onSelect={switchEmailEditorMode} onInsertField={insertIntoEmailBodyQuill} />
          { emailBodyEditorState !== 'preview' && <ReactQuill className="ql-default-height" placeholder="No custom email text is present for this notification, so the default email text will be used. You can enter customized email text here to override the default." ref={reactEmailBodyQuillRef} value={emailBody} onChange={setEmailBody} /> }
          { emailBodyEditorState === 'preview' && <ReactQuill className="ql-default-height no-toolbar" placeholder={(emailBody && emailBody !== '') ? "Generating preview..." : "No preview available"} readOnly={true} modules={{ toolbar: false}} value={emailBodyPreview} /> }
        </Form.Field>

        <Form.Field>
          <Form.Label>SMS body</Form.Label>
          <EditorModeTabs editorState={smsBodyEditorState} onSelect={switchSMSEditorMode} onInsertField={insertIntoSmsBodyQuill} />
          { smsBodyEditorState !== 'preview' && <Form.Textarea rows={4} domRef={reactSMSBodyQuillRef} value={smsBody} placeholder="No custom SMS text is present for this notification, so the default SMS text will be used. You can enter a customized SMS message here to override the default SMS message." onChange={(e) => setSmsBody(e.target.value)} /> }
          { smsBodyEditorState === 'preview' && <Form.Textarea rows={4} placeholder={(smsBody && smsBody !== '') ? "Generating preview..." : "No preview available"} readOnly={true} value={smsBodyPreview} /> }
          {/* { smsBodyEditorState !== 'preview' && <ReactQuill className="ql-default-height no-toolbar" ref={reactSMSBodyQuillRef} modules={{ toolbar: false}} value={smsBody} onChange={setSmsBody} /> }
          { smsBodyEditorState === 'preview' && <ReactQuill className="ql-default-height no-toolbar" placeholder={smsBody ? "Generating preview..." : "No preview available"} readOnly={true} modules={{ toolbar: false}} value={smsBodyPreview} /> } */}
        </Form.Field>

        <Button.Group className="is-flex is-justify-content-flex-end">
          <Button className="is-info" disabled={isSaving} onClick={() => sendPreview(selectedNotification, 'email', emailBody, subject)}>Send Preview Email</Button>
          <Button className="is-info" disabled={isSaving} onClick={() => sendPreview(selectedNotification, 'sms', smsBody, subject)}>Send Preview SMS</Button>

          <Button className="is-primary" disabled={isSaving || !hasChanges} onClick={save}>
            Save
          </Button>

          <Button className="is-danger is-outlined" disabled={isSaving || !hasChanges} onClick={cancel}>
            Cancel
          </Button>
        </Button.Group>

      </>}
    </> 
    {/* } */}
  </>
}

export default NotificationEditor;