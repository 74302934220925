import { Form } from 'react-bulma-components';
import SurveyStars from '../SurveyStars/SurveyStars';

function SurveyQuestionComponent({ alreadyCompleted, question, updateAnswer }) {

  function ratingComponent() {
    return <div className="is-flex is-justify-content-space-between is-align-items-center">
      <p>{question.description}</p>
      <div style={{ flexShrink: 0, width: 180 }}>
        <SurveyStars disabled={alreadyCompleted} onChange={(val) => updateAnswer(question.id, val)} value={question.answer} />
      </div>
    </div>
  }

  function boolComponent() {
    return <div className="py-2 is-flex is-justify-content-space-between is-align-items-center">
      <p className="is-flex-grow-1">{question.description}</p>
      <div style={{ flexShrink: 0, width: 180, paddingLeft: 10 }}>
      <Form.Select value={question.answer} disabled={alreadyCompleted} onChange={e => updateAnswer(question.id, e.target.value)} >
        <option value={null}>N/A</option>
        <option value="1">Yes</option>
        <option value="0">No</option>
      </Form.Select>
      </div>
      {/* <div style={{ flexShrink: 0, width: 180, paddingLeft: 10 }}>
        <input className="is-checkradio has-background-color" id={`surveyid_${question.id}_checkbox`} disabled={alreadyCompleted} type="checkbox" name={`surveyid_${question.id}_checkbox`} defaultChecked={question.answer === 'true' || question.answer === 'yes'} onChange={e => updateAnswer(question.id, e.target.checked ? 'true' : 'false')} />
        <label htmlFor={`surveyid_${question.id}_checkbox`}></label>
      </div> */}
    </div>
  }

  function textComponent() {
    return <Form.Field className="mt-2 mb-4">
      <Form.Label>{question.description}</Form.Label>
      <Form.Control>
        {alreadyCompleted ?
          (question.answer ?
            <div>{question.answer}</div>
            :
            <span className="explanationText has-text-grey-light">Not provided</span>
          )
          :
          <textarea rows={2} onChange={(e) => updateAnswer(question.id, e.target.value)} className="textarea mb-2" value={question.answer} />
        }
      </Form.Control>
    </Form.Field>
  }

  return <>
    {question.uiType === 'rating' && ratingComponent()}
    {question.uiType === 'bool' && boolComponent()}
    {question.uiType === 'text' && textComponent()}
  </>
}

export default SurveyQuestionComponent;