import { useState, useEffect } from 'react';
import { Modal, Form, } from 'react-bulma-components';

function SelectMetricsModal(props) {
  const [metricsList, setMetricsList] = useState([]);
  const [currentMetrics, setCurrentMetrics] = useState(props?.editing || {});
  const [selectedUserType, setSelectedUserType] = useState(props?.editing?.userType !== undefined ? props.editing.userType : 3);
  const [isRequired, setIsRequired] = useState(props?.editing?.optional !== undefined ? !props.editing.optional : true);
  const [isShared, setIsShared] = useState(props?.editing?.shared !== undefined ? props.editing.shared : false);

  const isEditing = props.editing !== undefined && props.editing !== null;

  const metricsUserTypes = [
    { id: 1, description: 'Mentees only' },
    { id: 2, description: 'Mentors only' },
    { id: 3, description: 'Mentees and mentors' },
  ]

  useEffect(() => {
    //only keep metrics for selected user recipient and not already in group
    let metricsToKeep = props.metrics.filter(currentMetrics => currentMetrics.userType === selectedUserType && !props.alreadyUsed.includes(currentMetrics.id));
    setMetricsList(metricsToKeep);
  }, [props.metrics, props.alreadyUsed, selectedUserType])

  async function addMetrics() {
    if (props.onSave) {
      props.onSave(currentMetrics.id, isRequired, isShared);
    }
    else {
      console.error('No onSave function provided');
    }
  }

  function switchMetrics(newValue) {
    let data = metricsList.filter(currentMetrics => currentMetrics.id === newValue);
    if (data.length > 0)
      setCurrentMetrics({ ...currentMetrics, id: newValue, description: data[0].description });
    else
      setCurrentMetrics({ ...currentMetrics, id: newValue, description: '' });
  }

  async function cancel() {
    if (props.onCancel) {
      props.onCancel();
    }
    else {
      console.error('No onCancel function provided');
    }
  }

  return (
    <Modal show={props.open} onClose={cancel}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">{props.title}</p>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <Form.Field>
            <Form.Label className="mr-3">Audience</Form.Label>
            <Form.Select disabled={isEditing} value={selectedUserType} onChange={e => setSelectedUserType(parseInt(e.target.value))}>
              {metricsUserTypes.map((userType) => <option key={`dropdown_metrics_usertype_${userType.id}`} value={userType.id}>{`${userType.description}`}</option>)}
            </Form.Select>
          </Form.Field>
          { !isEditing && 
            <Form.Field>
              <Form.Label>Question</Form.Label>
              <Form.Select loading={props.questionsLoading} disabled={isEditing} className="is-fullwidth" onChange={e => switchMetrics(+e.target.value)} placeholder='Metrics' >
                <option key="metricsselect" value="Select" hidden>Select a question...</option>
                {metricsList.map((metrics) =>
                  <option key={`metrics_${metrics.id}`} value={metrics.id}>{metrics.description}</option>)}
              </Form.Select>
            </Form.Field>
          }
          <div>
            <Form.Field>
              <Form.Label>Description</Form.Label>
              <Form.Textarea disabled placeholder="Metrics description" value={currentMetrics.description}></Form.Textarea>
            </Form.Field>
          </div>
          <div className="field mt-4">
            <input id="answerIsRequired" type="checkbox" name="answerIsRequired" className="switch" checked={isRequired} onChange={e => setIsRequired(e.target.checked)} />
            <label htmlFor="answerIsRequired">Required</label>
          </div>
          <div className="field mt-4">
            <input id="answerIsShared" type="checkbox" name="answerIsShared" className="switch" checked={isShared} onChange={e => setIsShared(e.target.checked)} />
            <label htmlFor="answerIsShared">Shared on profile</label>
          </div>

        </Modal.Card.Body>
        <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
          <button className="button is-success" onClick={addMetrics}>{isEditing ? 'Change' : 'Add'}</button>
          <button className="button is-outlined" onClick={cancel}>Cancel</button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default SelectMetricsModal;