import { useState, useEffect, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, } from '@fortawesome/free-solid-svg-icons';
import { Form, Icon, Button, } from "react-bulma-components";
import { toast } from 'react-toastify';
import { getPromoCode, addPromoCode, updatePromoCode } from '../../../lib/api';
import { parseISO } from 'date-fns';

import DatePicker from 'react-datepicker';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

function PromoCode(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [errors, setErrors] = useState({});
  const [promoType, setPromoType] = useState('percentage')
  const [currentCode, setCurrentCode] = useState(getDefaultCurrentCode());

  function getDefaultCurrentCode() {
    return {
      promoId: '', name: '', enabled: true, percentDiscount: 0,
      amountDiscount: 0, startDate: '', endDate: '', groupId: 1, amount: ''
    };
  }

  useEffect(() => {
    async function getData() {
      // const groupData = await 

      if (props.promoId) {
        setIsLoading(true);
        setEditMode(true);

        getPromoCode(props.promoId).then((data) => {
          setCurrentCode({
            promoId: data.promoId,
            name: data.name,
            enabled: data.enabled,
            percentDiscount: data.percentDiscount,
            amountDiscount: data.amountDiscount,
            amount: data.percentDiscount ? data.percentDiscount : data.amountDiscount,
            startDate: data.startDate ? parseISO(data.startDate) : null,
            startDateWithTime: data.startDate ? parseISO(data.startDate) : null,
            endDateWithTime: data.endDate ? parseISO(data.endDate) : null,
            endDate: data.endDate ? parseISO(data.endDate) : null,
            groupId: data.groupId,
          });
        })
          .catch((err) => {
            console.error('err', err)
          })
          .finally(() => setIsLoading(false));
      }
      else {
        setEditMode(false);
        setCurrentCode(getDefaultCurrentCode());
      }
    }
    getData();
  }, [props.promoId])

  async function saveNewPromoCode() {
    if (validateForm()) {
      try {
        if (promoType === 'percentage') {
          currentCode.percentDiscount = currentCode.amount;
          currentCode.amountDiscount = 0;
        }
        else {
          currentCode.percentDiscount = 0;
          currentCode.amountDiscount = currentCode.amount;
        }

        await addPromoCode(currentCode.promoId, currentCode);
        if (props.onDone) {
          props.onDone(currentCode);
        }
        toast.success('Promo created');
      }
      catch (err) {
        console.error(err);
        toast.error('Promo could not be saved');
      }
    }
  }

  async function updateCode() {
    if (validateForm()) {
      try {
        await updatePromoCode(currentCode.promoId, currentCode);
        if (props.onDone) {
          props.onDone(currentCode);
        }
        toast.success('Promo updated');
      }
      catch (err) {
        console.error(err);
        toast.error('Promo could not be updated');
      }
    }
  }

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control>
      <input className="input" type="text" readOnly onFocus={(e) => { onClick(e); }} placeholder="Select date" value={value} ref={ref} />
      <Icon align="left">
        <FontAwesomeIcon icon={faCalendarAlt} />
      </Icon>
    </Form.Control>
  ));

  function updateStartDate(myDate) {
    //for some reason, creating a new Date inside datepicker component won't work
    //we use extra var startDateWithTime as a workaround
    if (myDate) {
      setCurrentCode({ ...currentCode, startDate: myDate.toDateString(), startDateWithTime: myDate })
    }
    else {
      setCurrentCode({ ...currentCode, startDate: null, startDateWithTime: null })
    }
  }

  function updateEndDate(myDate) {
    //for some reason, creating a new Date inside datepicker component won't work
    //we use extra var endDateWithTime as a workaround
    if (myDate) {
      setCurrentCode({ ...currentCode, endDate: myDate.toDateString(), endDateWithTime: myDate });
    }
    else {
      setCurrentCode({ ...currentCode, endDate: null, endDateWithTime: null });
    }
  }

  function updateCodeProperty(property, value) {
    let newObj = { ...currentCode };
    newObj[property] = value;
    setCurrentCode(newObj);

    let newErrorObj = { ...errors };
    delete newErrorObj[property];
    setErrors(newErrorObj);
  }

  function validateForm() {
    const errors = {};

    if (!currentCode.promoId || currentCode.promoId.trim() === '') {
      errors.promoId = 'Promo code is required';
    }

    if (!currentCode.name || currentCode.name.trim() === '') {
      errors.name = 'Name is required';
    }

    const floatAmount = parseFloat(currentCode.amount);
    if (isNaN(floatAmount) || floatAmount < 0) {
      errors.amount = 'Amount must be a number more than 0';
    }
    else {
      if (promoType === 'percentage') {
        if (floatAmount > 100) {
          errors.amount = 'Percentage must be less than 100';
        }
      }
    }

    if (currentCode.startDateWithTime && currentCode.endDateWithTime && currentCode.startDateWithTime.valueOf() > currentCode.endDateWithTime.valueOf()) {
      errors.startDateWithTime = 'Start date must be before end date';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  }

  return (
    <>
      {isLoading ? <ProgressComponent /> :
        <div className="edit-promo-page">
          {/* { editMode === true ? <Heading>Edit Promo Code</Heading> :
        <Heading>New Promo Code</Heading>} */}
          <Form.Field>
            <Form.Label>Promo code</Form.Label>
            <Form.Input value={currentCode.promoId} color={errors.promoId && 'danger'} placeholder="e.g. DISC100" disabled={editMode} onChange={e => updateCodeProperty('promoId', e.target.value)}></Form.Input>
            {errors.promoId && <Form.Help color="danger">{errors.promoId}</Form.Help>}
          </Form.Field>
          <Form.Field>
            <Form.Label>Name</Form.Label>
            <Form.Input placeholder="e.g. Free Mentorship" color={errors.name && 'danger'} value={currentCode.name} onChange={e => updateCodeProperty('name', e.target.value)}></Form.Input>
            {errors.name && <Form.Help color="danger">{errors.name}</Form.Help>}
          </Form.Field>
          {/* <Form.Field>
            <Form.Label>Groups</Form.Label>
            <Form.Input value={currentCode.promoId} placeholder="e.g. DISC100" disabled={editMode} onChange={e => setcurrentCode({ ...currentCode, promoId: e.target.value })}></Form.Input>
          </Form.Field> */}
          <div className="is-flex">
            <Form.Field style={{ width: '10rem' }}>
              <Form.Label>Discount Type</Form.Label>
              <Form.Select value={promoType} disabled={editMode} onChange={e => setPromoType(e.target.value)} >
                <option key="percentage" value="percentage">Percentage</option>
                <option key="amount" value="amount">Amount</option>
              </Form.Select>
            </Form.Field>
            <Form.Field style={{ width: '10rem' }} className="ml-3">
              <Form.Control style={{ width: '10rem' }}>
                <Form.Label>Amount</Form.Label>
                <Form.Input style={{ width: '10rem' }} color={errors.amount && 'danger'} placeholder={promoType === 'amount' ? 'Amount' : 'Percentage'} value={currentCode.amount} disabled={editMode} onChange={e => updateCodeProperty('amount', e.target.value)}></Form.Input>
                {errors.amount && <Form.Help color="danger">{errors.amount}</Form.Help>}
              </Form.Control>
            </Form.Field>
          </div>
          <div className="is-flex">
            <Form.Field style={{ width: '10rem' }}>
              <Form.Label>Start Date</Form.Label>
              <DatePicker
                popperPlacement="right" selected={currentCode.startDateWithTime}
                onChange={(date) => updateStartDate(date)}
                isClearable={true}
                customInput={<CustomDateInput />}
              />
              {errors.startDateWithTime && <Form.Help color="danger">{errors.startDateWithTime}</Form.Help>}
            </Form.Field>
            <Form.Field style={{ width: '10rem' }} className="ml-3">
              <Form.Label>End Date</Form.Label>
              <DatePicker
                popperPlacement="right" selected={currentCode.endDateWithTime}
                onChange={(date) => updateEndDate(date)}
                isClearable={true}
                customInput={<CustomDateInput />}
              />
            </Form.Field>
          </div>

          { editMode && 
            <div className="field mt-4">
              <input id="enabledSwitch" type="checkbox" name="enabledSwitch" className="switch" checked={currentCode.enabled} onChange={e => setCurrentCode({ ...currentCode, enabled: e.target.checked })} />
              <label htmlFor="enabledSwitch">Enabled</label>
            </div>
          }

          <Button.Group className="is-pulled-right">
            {editMode === false ? <Button className="is-success" onClick={saveNewPromoCode}>Save</Button> :
              <Button className="is-success" onClick={updateCode}>Update</Button>
            }
            <Button className="is-danger" onClick={() => props.onCancel()}>Cancel</Button>
          </Button.Group>

        </div>}
    </>)

}

export default PromoCode;
