import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Heading, Form, Button, Dropdown, Menu } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faRedo, faTrash, faAngleDown, faAngleUp, faArrowUp, faArrowDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import { userTypeString } from '../../lib/ternTypeHelper';
import { boolString, defaultDateString } from '../../lib/stringHelpers';
import { useHistory, useLocation, NavLink, Switch } from 'react-router-dom';
import { parseISO } from 'date-fns';
import QRCode from "qrcode.react";
import ProgressComponent from '../ProgressComponent/ProgressComponent';
import SelectMetricsModal from '../Metrics/SelectMetricsModal';
import BannerPicture from '../BannerPicture/BannerPicture';
import PictureUploaderModal from '../PictureUploaderModal/PictureUploaderModal';
import { getMentorships, getGroup, addGroup, updateGroup, updateGroupSession, createGroupSession, getGroupSessions, 
  deleteGroupSession, getGroupSurveys, updateGroupSurveyQuestion, createGroupSurveyQuestion, deleteGroupSurveyQuestion,
  getOnboardingQuestions, removeGroupMetrics, addGroupMetrics, swapGroupMetrics,
  getCompanyUsers, getImportableUsers, moveUserToGroup, addGroupMetricsPage, editGroupMetrics, editGroupMetricsPage, getOnboardingQuestionsPages, deleteGroupMetricsPage, setNewPageOrder, getMetrics, deleteMetrics } from '../../lib/api';
import { ChromePicker } from 'react-color';
import TernTable from '../TernTable/TernTable';
import SessionModal from '../Session/SessionModal';
import SurveyQuestionModal from '../Survey/SurveyQuestionModal';
import config from '../../config';
import UserPickModal from '../UserPickModal/UserPickModal';
import AddPageModal from './AddPageModal';
import Mentorships from '../../screens/Admin/Mentorships/Mentorships';
import Reports from '../Reports/Reports';
import OffboardingQuestions from './OffboardingQuestions';
import NotificationEditor from '../NotificationEditor/NotificationEditor';

function Group(props) {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showBannerDialog, setShowBannerDialog] = useState(false);
  const [bannerChanged, setBannerChanged] = useState(false);
  const [sessionTable, setSessionTable] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [currentSession, setCurrentSession] = useState({ id: 0 });
  const [signupUrl, setSignupUrl] = useState('');
  const [currentGroup, setCurrentGroup] = useState(getDefaultData());
  const [currentGroupId, setCurrentGroupId] = useState(props.groupId);
  const [updateSessionData, setUpdateSessionData] = useState(false);
  const [viewingTab, setViewingTab] = useState(getQueryTab());
  const [formErrors, setFormErrors] = useState({});
  const [surveyTable, setSurveyTable] = useState([]);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState({ id: 0 });
  const [updateSurveyData, setUpdateSurveyData] = useState(false);
  const [surveyData, setSurveyData] = useState([]);
  const [selectedSurveyTypes, setSelectedSurveyTypes ] = useState([1])
  const [selectedUserTypes, setSelectedUserTypes ] = useState([3]);
  const [selectedUserTypesForUserTable, setSelectedUserTypesForUserTable ] = useState([1,2]);
  const [metrics, setMetrics] = useState([]);
  const [metricsData, setMetricsData ] = useState([]);
  const [metricsTable, setMetricsTable] = useState([]);
  const [currentMetrics, setCurrentMetrics] = useState({ id: 0 });
  const [updateMetricsData, setUpdateMetricsData] = useState(false);
  const [showMetricsModal, setShowMetricsModal] = useState(false);
  const [metricsIdList,setMetricsIdList] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [usersTable, setUsersTable] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [switchableUsers,setSwitchableUsers] = useState([]);
  const [updateUsersData,setUpdateUsersData] = useState(false);
  const [mentorships, setMentorships] = useState(false);
  const [ counts, setCounts ] = useState({ total: 0, mentors: 0, mentees: 0})

  const [selectedQuestion, setSelectedQuestion] = useState();

  const [pages, setPages ] = useState([]);
  const [collapsedCards, setCollapsedCards] = useState([]);
  const [ questionStats, setQuestionStats ] = useState({ totalPages: 0, totalQuestions: 0, totalAnyone: 0, totalMentee: 0, totalMentor: 0});

  function getQueryTab() {
    const search = new URLSearchParams(location.search);
    return search.get('tab') ? search.get('tab').toLowerCase() : null;
  }

  function selectQuestion(question) {
    setSelectedQuestion(question);
    setShowMetricsModal(true);
  }

  function getDefaultData() {
    return {
      id: null,
      name: '',
      description: '',
      companyName: '',
      companyWebsite: '',
      enabled: false,
      signupCode: getRandomSignupCode(),
      bannerPic: '',
      mentorshipDuration: '12',
      color1: 'rgba(47,16,119,1)',
      color2: 'rgba(247,200,47,1)'
    }
  }

  const metricsUserTypes = [
    { id: 1, description: 'Mentees only'},
    { id: 2, description: 'Mentors only'},
    { id: 3, description: 'Mentees and Mentors'},
  ];

  const userTypesForUserTable = [
    { id: 1, description: 'Mentee'},
    { id: 2, description: 'Mentor'},
    { id: 3, description: 'Donor'},
    { id: 4, description: 'Admin'},
  ]
  const surveyTypes = [
    { id: 1, description: 'Mentee Session Feedback'},
    { id: 2, description: 'Mentor Session Feedback'},
    { id: 3, description: 'Mentee Final Feedback'},
    { id: 4, description: 'Mentor Final Feedback'},
  ]

  function getRandomSignupCode() {
    return Math.floor(200000 + Math.random() * 900000);
  }

  // Remember our selected tab
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    if (viewingTab) {
      searchParams.set('tab', viewingTab);
    }
    else {
      searchParams.delete('tab');
    }
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }, [history, location.pathname, location.search, viewingTab]);

  useEffect(() => {
    if (props.groupId > 0) {
      setIsLoading(true);
      setCurrentGroupId(props.groupId);
      getGroup(props.groupId)
        .then((data) => {
          const defaultData = getDefaultData();
          setCurrentGroup({
            id: data.id,
            name: data.name,
            description: data.description,
            companyName: data.companyName,
            companyWebsite: data.companyWebsite,
            mentorshipDuration: data.mentorshipDuration,
            enabled: data.enabled,
            signupCode: data.signupCode,
            bannerPic: data.bannerPic,
            color1: data.color1 || defaultData.color1,
            color2: data.color2 || defaultData.color2,
          });

          return getMetrics();
        })
        .then((metrics) => {
          setMetrics(metrics);

          // return getMentorships();
        })
        // .then((metrics) => {
        //   setMentorships(metrics);
        // })
        .catch((err) => {
          console.error('err', err)
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
  }, [props.groupId])

  useEffect(() => {
    if (currentGroupId > 0) {
      getCompanyUsers(currentGroupId)
      .then((data) => {
        setUsersData(data);
        
        if (data){
          let newCounts = {
            total: data.length,
            mentors: data.filter(f => f.mentor_id).length,
            mentees: data.filter(f => f.mentee_id).length,
          }

          setCounts(newCounts);
        }
      })
      .catch((err) => {
        console.error('err', err)
      })
      .finally(() => setIsLoading(false));
    }
    setUpdateUsersData(false);
  }, [updateUsersData,currentGroupId])


  useEffect(() => {
    if (showSessionModal === false) {
      if (sessionData && sessionData.length > 0) {
        setSessionTable(sessionData.map((session) => {
          return {
            id: session.sessionId,
            num: session.sessionNum,
            title: session.title,
            description: session.description,
          }
        }));
      }
      else setSessionTable([]);
    }
  }, [sessionData, showSessionModal])

  useEffect(() => {
    if (showSurveyModal === false) {
      if (surveyData && surveyData.length > 0) {
        let theData;
        if (selectedSurveyTypes.length < 4) //exclude some surveys
          theData = surveyData.filter(survey => 
              (selectedSurveyTypes.includes(1) === true  || (survey.userType !== 1 || survey.surveyType !== 1))
              && (selectedSurveyTypes.includes(2) === true  || (survey.userType !== 2 || survey.surveyType !== 1))
              && (selectedSurveyTypes.includes(3) === true || (survey.userType !== 1 || survey.surveyType !== 2))
              && (selectedSurveyTypes.includes(4) === true || (survey.userType !== 2 || survey.surveyType !== 2))
                );
        else theData = surveyData;
        setSurveyTable(theData.map((survey,idx) => {
          return {
            id: survey.id,
            orderNumber: survey.orderNum, 
            rank: idx+1,
            description: survey.description,
            values: getQuestionTypeAsInt(survey.values),
            valuesText: getQuestionType(survey.values),
            userTypeText: survey.userType === 1 ? 'Mentee' : 'Mentor', 
            userType: survey.userType,
            surveyType: survey.surveyType,
            surveyTypeText: survey.surveyType === 1 ? 'Quick Survey' : 'Final Survey'
          }
        }));
      }
      else setSurveyTable([]);
    }
  }, [surveyData, showSurveyModal,selectedSurveyTypes])

  useEffect(() => {
    if (showUserModal === false) {
      if (usersData && usersData.length > 0) {
        let theData = usersData.filter(user => selectedUserTypesForUserTable.includes(user.active_profile));
        setUsersTable(theData.map((user) => { return {
            id: user.cognito_id,
            last_name: user.last_name,
            first_name: user.first_name,
            profiletype: userTypeString(user.active_profile),
            status: getStatusString(user),
            statusBadge: getStatusBadge(user),
            available: getAvailableString(user),
            email: user.email,
            creation_date: parseISO(user.creation_date),
            profile_complete: boolString(user.profile_complete),
            ready: boolString(user.match_ready_at !== null)
          }}));
      }
      else setUsersTable([]);
    }
  }, [usersData,selectedUserTypesForUserTable,showUserModal])

  useEffect(() => {
    if (currentGroupId > 0) {
      setIsLoading(true);
      getGroupSurveys(currentGroupId)
        .then((surveys) => {
          setSurveyData(surveys);
        })
        .finally(() => {
          setCurrentSurvey({ id: 0 });
          setIsLoading(false);
        });
    }
    else { //new Group
      //setUpdateSessionData(false);
      setSurveyData([]);
      setCurrentSurvey({ id: 0 });
    }
    setUpdateSurveyData(false);
  }, [updateSurveyData, currentGroupId])

  useEffect(() => {
    let pages = [];
    if (currentGroupId > 0) {
      setIsLoading(true);
      getOnboardingQuestionsPages(currentGroupId).then((thePages) => {
        pages = thePages;
        return getOnboardingQuestions(currentGroupId);
      })
        .then((metrics) => {
          metrics = metrics || [];
          setMetricsData(metrics);

          let stats = { totalPages: pages.length, totalQuestions: 0, totalAnyone: 0, totalMentee: 0, totalMentor: 0};

          metrics.forEach((question, idx) => {
            let group = pages.find(f => f.pageId === question.pageId);
            if (group) {
              if (!group?.questions) group.questions = [];
              group.questions.push({...question, pageId: group.pageId});
              stats.totalQuestions++;

              if (question.userType === 1) stats.totalMentee++;
              if (question.userType === 2) stats.totalMentor++;
              if (question.userType === 3) stats.totalAnyone++;
            }
          });
          console.log('pages', pages);
          setPages(pages);

          let collapsed = pages.map(page => page.pageId).slice(1);
          setCollapsedCards(collapsed);
          setQuestionStats(stats);
        })
        .finally(() => {
          setCurrentMetrics({ id: 0 });
          setIsLoading(false);
        });
    }
    else { //new Group
      //setUpdateSessionData(false);
      setMetricsData([]);
      setCurrentMetrics({ id: 0 });
    }
    setUpdateMetricsData(false);
  }, [updateMetricsData, currentGroupId])

  useEffect(() => {
    if (metricsData && metricsData.length > 0) {
        
        let theData = metricsData.filter(metrics => 
        metrics.userType === selectedUserTypes[0]);
        const newMetricsIdList = [];
        setMetricsTable(metricsData.map((metrics,idx) => {
          let valuesAsText = metrics.values.slice(1,metrics.values.length-1); //remove {}
          newMetricsIdList.push(metrics.id);
        return {
          id: metrics.id,
          name: metrics.name,
          description: metrics.description,
          matchOnCount: metrics.matchOnCount,
          values: valuesAsText,
          orderNum: metrics.orderNum, 
          userType: metrics.userType,
          metricsType: metrics.metricsType,
          metricsTypeId: metrics.metricsTypeId,
          pageTitle: metrics.pageTitle,
          min: metrics.min,
          max: metrics.max,
        }
        }));
        setMetricsIdList(newMetricsIdList);
      }
      else setMetricsTable([]);
  }, [metricsData,selectedUserTypes])

  useEffect(() => {
    if (currentGroupId > 0) {
      setIsLoading(true);
      getGroupSessions(currentGroupId)
        .then((sessions) => {
          setSessionData(sessions);
        })
        .finally(() => {
          setCurrentSession({ id: 0 });
          setIsLoading(false);
        });
    }
    else { //new Group
      //setUpdateSessionData(false);
      setSessionData([]);
      setCurrentSession({ id: 0 });
    }
    setUpdateSessionData(false);
  }, [updateSessionData, currentGroupId])

  const permuteMetricsByIndex = useCallback ((callingMetric, sourceIdx, destIdx) => {

    const pageIdx = pages.findIndex(p => p.pageId === callingMetric.pageId);
    if (pageIdx > -1) {
      let page = pages[pageIdx];
      let sourceQuestion = page.questions[sourceIdx];
      let destQuestion = page.questions[destIdx];

      const origSourceOrderNum = sourceQuestion.orderNum;

      sourceQuestion = {
        ...sourceQuestion,
        orderNum: destQuestion.orderNum
      }

      destQuestion = {
        ...destQuestion,
        orderNum: origSourceOrderNum
      }
      
      const filteredPages = page.questions.filter(q => q.id !== sourceQuestion.id && q.id !== destQuestion.id);
      pages[pageIdx].questions = [...filteredPages, sourceQuestion, destQuestion].sort((a,b) => a.orderNum - b.orderNum);
      setPages([...pages]);

      swapGroupMetrics(currentGroupId, callingMetric.pageId, sourceQuestion.id, destQuestion.id)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.err(err);
        })
    }

  }, [currentGroupId, pages])
  
  const removeMetricsFromGroup = useCallback((deletedMetrics) => {
    if (window.confirm('Do you really want to remove this metrics?')) {
      if (currentGroupId > 0 && deletedMetrics.id > 0) {
        //delete question in db
        removeGroupMetrics(deletedMetrics.id, currentGroupId)
          .then(() => { 
            // What page was it on?
            const newPages = pages.map(thePage => {
              return {
                ...thePage, 
                questions: thePage.questions.filter(q => q.id !== deletedMetrics.id)
              }
            })

            setPages(newPages);
          })
      }
    }
  }, [currentGroupId, pages]);

  const data = useMemo(
    () => sessionTable,
    [sessionTable]
  )

  const surveyMemoData = useMemo(
    () => surveyTable,
    [surveyTable]
  )

  const metricsMemoData = useMemo(
    () => metricsTable,
    [metricsTable]);

  const usersMemoData = useMemo(
    () => usersTable,
    [usersTable]
  )
  
  const downloadQR = () => {
    const canvas = document.getElementById("signupQRCode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${currentGroup.companyName.toLowerCase().replaceAll(/ /g, '-')}-qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  function getAvailableString(user) {
    let availString = '-';
    if (user.active_profile === 1 || user.active_profile === 2) {
      availString = user.active_profile === 1 ? boolString(user.mentee_available) : boolString(user.mentor_available);
    }

    return availString;
  }
  
  function getStatusString(user) {
    return user.match_id ? 'Matched' : 'Unmatched';
  }

  function getStatusBadge(user) {
    let statusColor = user.match_id ? 'is-success' : 'is-info';
    let statusString = user.match_id ? 'Matched' : 'Unmatched';

    // if (user.product_id >= 1) {
    //   statusColor = 'is-success has-text-weight-bold';
    //   statusString = user.product_id === 1 ? 'Regular' : 'Journey';
    // }
    // else if (user.active_profile === 2) {
    //   statusColor = 'is-warning';
    //   statusString = 'Unpaid';
    // }
    // else if (user.active_profile === 1 && user.match_id) {
    //   statusColor = 'is-success has-text-weight-bold';
    //   statusString = 'Paid';
    // }
    // else if (user.active_profile === 1 && !user.match_id) {
    //   statusColor = 'is-info';
    //   statusString = 'Unmatched';
    // }

    if (statusString) {
      return <span className={`tag mr-2 ${statusColor}`}>{statusString}</span>
    }
  }

  function getDefaultSelectedUserTypes() {
    let userTypesFilter = [1,2];
    const search = new URLSearchParams(location.search);
    if (search.get('usertypes')) {
      try
      {
        userTypesFilter = JSON.parse(`[${search.get('usertypes')}]`);
      }
      catch { }
    }
    return userTypesFilter;
  }
  function updateSelectedSurveyTypes(surveyTypeId, include) {
    if (include) {
      setSelectedSurveyTypes([...selectedSurveyTypes, surveyTypeId]);
    }
    else {
      setSelectedSurveyTypes(selectedSurveyTypes.filter(id => id !== surveyTypeId));
    }
    
  }
  function getQuestionType(possibleValues){
    if (possibleValues === '{"1","2","3","4","5"}')
      return 'Rating (1 to 5)';
    else if (possibleValues === '{"yes","no","na"}')
      return 'Yes/No/NA';
    else
      return 'Free text';
  }
  function getQuestionTypeAsInt(possibleValues){
    if (possibleValues === '{"1","2","3","4","5"}')
      return 1;
    else if (possibleValues === '{"yes","no","na"}')
      return 2;
    else
      return 3;
  }
  function setQuestionType(possibleValues){
    if (possibleValues === 1)
      return '{"1","2","3","4","5"}';
    else if (possibleValues === 2)
      return '{"yes","no","na"}';
    else
      return '';
  }
  async function newGroup() {
    if (validateForm()) {
      try {
        const newGroupData = await addGroup(currentGroup);
        if (props.onCreateGroup) {
          props.onCreateGroup(newGroupData);
        }
      }
      catch (err) {
        console.error('newGroup err', err);
        toast.error('Error adding group')
      }
    }
  }

  async function saveChanges() {
    if (validateForm()) {
      try {
        await updateGroup(currentGroup.id, currentGroup);
        if (props.onUpdateGroup) {
          props.onUpdateGroup(currentGroup);
        }
      }
      catch (err) {
        console.error('newGroup err', err);
        toast.error('Error updating group')
      }
    }
  }

  function newSurveyQuestion() {
    let userType = 1;
    let surveyType = 1;
    if (selectedSurveyTypes.includes(2) === true)
      userType = 2;
    else if (selectedSurveyTypes.includes(3) === true)
      surveyType = 2;
    else if (selectedSurveyTypes.includes(4) === true) {
      userType = 2;
      surveyType = 2;
    }
    setCurrentSurvey({ id: 0, description: '', values: 1, userType: userType, surveyType: surveyType, orderNum: surveyMemoData.length + 1});
    setShowSurveyModal(true);
  }
  function onSurveyTableRowClick(row) {
    setCurrentSurvey({ id: row.id, description: row.description, values: row.values, userType: row.userType, surveyType: row.surveyType, orderNum: row.orderNumber });
    setShowSurveyModal(true);

  }
  async function newUser() {
    //Get list of users that are not in the group and not
    //in an active mentorship
    const usersFromOtherGroups = await getImportableUsers(currentGroupId);
    setSwitchableUsers(usersFromOtherGroups);
    setShowUserModal(true);
  }
  function newSession() {
    setCurrentSession({ id: 0, title: '', description: '', num: sessionData.length + 1 });
    setShowSessionModal(true);
  }
  function onTableRowClick(row) {
    setCurrentSession({ id: row.id, title: row.title, description: row.description, num: row.num });
    setShowSessionModal(true);

  }
  
  const onUsersTableRowClick = (row) => {
    history.push(`/users/${row.id}`);
  }
  const usersTableColumns = useMemo(
    () => [
      {
        Header: 'Last Name',
        accessor: 'last_name', 
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Type',
        accessor: 'profiletype',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Profile',
        accessor: 'profile_complete',
        collapse: true,
      },
      {
        Header: 'Ready',
        accessor: 'ready',
        collapse: true,
      },
      {
        Header: 'Available',
        accessor: 'available',
        collapse: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        collapse: true,
        Cell: props => props.row.original.statusBadge
      },
      {
        Header: 'Member Since',
        accessor: 'creation_date',
        sortType: 'datetime',
        Cell: props => defaultDateString(props.value)
      },
    ],
    []
  )
  
  const sessionTableColumns = useMemo(
    () => [
      {
        Header: 'Session',
        accessor: 'num',
        disableSortBy: true,
        collapse: true,
      },
      {
        Header: 'Title',
        disableSortBy: true,
        accessor: 'title',
      },
      {
        Header: 'Description',
        disableSortBy: true,
        accessor: 'description',
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        collapse: true,
        Cell: prop => <button name="Delete" onClick={(e) => { removeSession({ id: prop.value}); e.stopPropagation();}}><FontAwesomeIcon icon={faTrash} /></button>
      },
    ],
    []
  )

  const surveyTableColumns = useMemo(
    () => [
      // { Header: 'Number',
      //   accessor: 'orderNumber'  
      // },
      {
        Header: 'Description',
        accessor: 'description',
        disableSortBy: true,
      },
      {
        Header: 'Answer Type',
        accessor: 'valuesText',
        disableSortBy: true,
      },
      // {
      //   Header: 'Question for',
      //   accessor: 'userTypeText',
      // disableSortBy: true,
      // },
      
      // {
      //   Header: 'Survey Type',
      //   accessor: 'surveyTypeText',
      // disableSortBy: true,
      // },
      {
        Header: '',
        accessor: 'rank',
        disableSortBy: true,
        collapse: true,
        Cell: prop => { 
          return <div className="is-flex">
            <button name="MoveUp" disabled={prop.row.index === 0} onClick={(e) => { permuteSurveyQuestions({ source: prop.rows[prop.value-1].values, dest: prop.rows[prop.value-2].values}); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowUp} /></button>
            <button name="MoveDown" disabled={prop.row.index === prop.rows.length-1} onClick={(e) => { permuteSurveyQuestions({ source: prop.rows[prop.value-1].values, dest: prop.rows[prop.value].values}); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowDown} /></button>
          </div>
          },
      },
        {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        collapse: true,
        Cell: prop => <button name="Delete" onClick={(e) => { removeSurveyQuestion({ rank: prop.cell.row.values.rank, id: prop.value, rows: prop.rows});e.stopPropagation(); }}><FontAwesomeIcon icon={faTrash} /></button>
      },
    ],
    []
  )

  const metricsTableColumns = useMemo(
    () => [
      // {
      //   Header: 'Page',
      //   accessor: 'pageTitle',
      // },
      {
        Header: 'Question',
        accessor: 'description',
        disableSortBy: true,
        Cell: prop => <div className="is-link" style={{cursor: 'pointer', width: '100%'}} onClick={() => selectQuestion(prop.row.original)}><a>{prop.value}</a></div>
      },
      {
        Header: 'Audience',
        accessor: 'userType',
        collapse: true,
        disableSortBy: true,
        Cell: prop => <div className="has-text-centered"><div className={`tag ${ prop.value === 1 ? 'is-info' : (prop.value === 2 ? 'is-link' : '') }`}>{ prop.value === 1 ? 'Mentee' : (prop.value === 2 ? 'Mentor' : 'Anyone') }</div></div>
      },
      {
        Header: 'Required',
        accessor: 'optional',
        collapse: true,
        disableSortBy: true,
        Cell: prop => <div className="has-text-centered">{ prop.value ? 'No' : 'Yes' }</div>
      },
      {
        Header: 'Answer Type',
        accessor: 'metricsDescription',
        disableSortBy: true,
        mediumcolumn: true,
      },  
      {
        Header: '',
        accessor: 'orderNum',
        disableSortBy: true,
        collapse: true,
        Cell: prop => { 
          return <div className="is-flex">
            <button name="MoveUp" title="Move Question Up" disabled={prop.row.index === 0} onClick={(e) => { permuteMetricsByIndex(prop.rows[prop.row.index].original, prop.row.index, prop.row.index-1); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowUp} /></button>
            <button name="MoveDown" title="Move Question Down" disabled={prop.row.index === prop.rows.length-1} onClick={(e) => { permuteMetricsByIndex(prop.rows[prop.row.index+1].original, prop.row.index, prop.row.index+1); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowDown} /></button>
          </div>
          },
      },
      {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      collapse: true,
      Cell: prop => <button name="Delete" title="Remove Question" onClick={(e) => { removeMetricsFromGroup({ id: prop.value, rows: prop.rows});e.stopPropagation(); }}><FontAwesomeIcon icon={faTrash} /></button>
    },
    ],
    [permuteMetricsByIndex, removeMetricsFromGroup]
  )
  async function cancelSession(updatedSession) {
    setShowSessionModal(false);
  }
  async function cancelUser() {
    setShowUserModal(false);
  }

  async function cancelSurveyQuestion(updatedSession) {
    setShowSurveyModal(false);
  }
  function addMetricsToGroup(metricsId, isRequired, isShared){
    setShowMetricsModal(false);

    let data = {};
    let func = null;
    if (selectedQuestion) {
      // Editing
      data = {
        id: selectedQuestion.id,
        optional: !isRequired,
        shared: isShared,
      }
      func = editGroupMetrics;
    }
    else {
      // Adding
      if (currentGroupId > 0 && metricsId > 0) {
        data = {
          id: metricsId,
          pageId: addingtoPageId,
          optional: !isRequired,
          shared: isShared,
        }
        func = addGroupMetrics;
      }
    }

    if (func) {
      func(currentGroupId, data)
        .then((added) => 
        { 
          setMetricsIdList([...metricsIdList, metricsId]);

          const pageIdx = pages.findIndex(p => p.pageId === added.pageId);
          if (pageIdx > -1) {
            if (selectedQuestion) {
              const questionIdx = pages[pageIdx].questions.findIndex(q => q.id === selectedQuestion.id);
              pages[pageIdx].questions[questionIdx] = { ...pages[pageIdx].questions[questionIdx], optional: !isRequired};
              pages[pageIdx].questions = [...pages[pageIdx].questions];
            }
            else {
              if (pages[pageIdx].questions) {
                pages[pageIdx].questions = [...pages[pageIdx].questions, added];
              }
              else {
                pages[pageIdx].questions = [added];
              }
            }
            setPages([...pages]);
          }
          setSelectedQuestion(null);
        })
    }
    
  }

  function removeSurveyQuestion(deletedQuestion) {
    let questionData;
    if (window.confirm('Do you really want to delete this question?')) {
      if (currentGroupId > 0 && deletedQuestion.id > 0) {
        //delete question in db
        deleteGroupSurveyQuestion(deletedQuestion.id, currentGroupId)
          .then(() => { 
            //update order num for subsequent questions
            for (let idx = deletedQuestion.rank;idx < deletedQuestion.rows.length; idx++){
              questionData = {
                description: deletedQuestion.rows[idx].values.description,
                values: deletedQuestion.rows[idx].values.valuesText,
                orderNum: deletedQuestion.rows[idx].values.rank-1, 
            };
              updateGroupSurveyQuestion(deletedQuestion.rows[idx].values.id, currentGroupId, questionData)
                .then(() => {
              ; 
             })
            }
            setUpdateSurveyData(true); })
      }
    }
  }

  function permuteSurveyQuestions(movedUpQuestion){
    if (currentGroupId > 0) {
      //permute question in db
      let questionData = {
          description: movedUpQuestion.source.description,
          values: movedUpQuestion.source.valuesText,
          orderNum: movedUpQuestion.dest.rank, 
      };
      updateGroupSurveyQuestion(movedUpQuestion.source.id, currentGroupId, questionData)
        .then(() => { 
          questionData = {
            description: movedUpQuestion.dest.description,
            values: movedUpQuestion.dest.valuesText,
            orderNum: movedUpQuestion.source.rank, 
          };
            updateGroupSurveyQuestion(movedUpQuestion.dest.id, currentGroupId, questionData)
            .then(() => {
              setUpdateSurveyData(true); 
             })
        })
    }

  }

  function removeSession(deletedSession) {
    if (window.confirm('Do you really want to delete this session?')) {
      setShowSessionModal(false);
      if (currentGroupId > 0 && deletedSession.id > 0) {
        //delete session in db
        deleteGroupSession(deletedSession.id, currentGroup.id)
          .then(() => {  
            setUpdateSessionData(true); })
      }
    }
  }

  function addMetrics(){
    setShowMetricsModal(true);
  }
  function saveSurveyQuestion(updatedQuestion) {
    setShowSurveyModal(false);
    if (currentGroupId > 0) {
      //save survey in db
      let questionData = {
        description: updatedQuestion.description,
        values: setQuestionType(updatedQuestion.values),
        userType: updatedQuestion.userType,
        surveyType: updatedQuestion.surveyType,
        orderNum: updatedQuestion.orderNum,
      };
      if (updatedQuestion.id > 0) 
        updateGroupSurveyQuestion(updatedQuestion.id, currentGroupId, questionData)
          .then(() => { setUpdateSurveyData(true); })
      else
        createGroupSurveyQuestion(currentGroupId, questionData)
          .then(() => { setUpdateSurveyData(true); })

    }

  }
  function switchUser(addedUserId){
    if (window.confirm('This will move user to this company. The user will need to redo their onboarding. Are you sure you want to do this?')) {
      if (addedUserId !== '' && currentGroupId > 0){
        moveUserToGroup(addedUserId,currentGroupId)
        .then(() => { setUpdateUsersData(true); })

       }
       setShowUserModal(false);
    }
  }

  function saveSession(updatedSession) {
    setShowSessionModal(false);
    if (currentGroup.id > 0) {
      //save session in db
      if (updatedSession.id > 0)
        updateGroupSession(updatedSession.id, currentGroup.id, updatedSession)
          .then(() => { setUpdateSessionData(true); })
      else
        createGroupSession(currentGroup.id, updatedSession)
          .then(() => { setUpdateSessionData(true); })

    }

  }

  useEffect(() => {
    setSignupUrl(`${config.appUrl}signup?signupcode=${currentGroup.signupCode}`)
  }, [currentGroup.signupCode])

  async function onBannerSaved() {
    try {
      setBannerChanged(true);
    }
    catch (err) {
      console.error('Error refreshing banner');
    }
    finally {
      setShowBannerDialog(false);
    }
  }

  function validateForm() {
    const currentErrors = {};

    if (!(parseFloat(currentGroup.signupCode) >= 100000 && parseFloat(currentGroup.signupCode) <= 999999)) {
      currentErrors.signupCode = 'Code must be six digits';
    }

    if (!currentGroup.companyName || !currentGroup.companyName.trim()) {
      currentErrors.companyName = 'Required';
    }

    // if (!currentGroup.companyWebsite || !currentGroup.companyWebsite.trim()) {
    //   currentErrors.companyWebsite = 'Required';
    // }

    if (!currentGroup.mentorshipDuration || isNaN(parseInt(currentGroup.mentorshipDuration))) {
      currentErrors.mentorshipDuration = 'Required';
    }

    setFormErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  }

  function updateCurrentGroupProp(property, value) {
    setCurrentGroup(prev => ({...prev, [property]: value }))

    let newErrorObj = { ...formErrors };
    delete newErrorObj[property];
    setFormErrors(newErrorObj);
  }

  function updateSelectedUserTypesForTable(userTypeId, include) {
    if (include) {
      setSelectedUserTypesForUserTable([...selectedUserTypesForUserTable, userTypeId]);
    }
    else {
      setSelectedUserTypesForUserTable(selectedUserTypesForUserTable.filter(id => id !== userTypeId));
    }
  }

  function askAndGenerateNewSignupCode() {
    if (window.confirm('Are you sure you want to generate a new signup code for this group?  Once a new signup code is create, the old one will no longer work.')) {
      updateCurrentGroupProp('signupCode', getRandomSignupCode());
      toast.success('New signup code generated');
    }
  }

  const BrandingSection = () => {
    return <>
      { currentGroup.id && <BannerPicture useEmptyBannerPic={currentGroup.id === 0} picture={currentGroup.bannerPic} color1={currentGroup.color1} color2={currentGroup.color2} onChangeClick={() => setShowBannerDialog(true)} /> }

      <PictureUploaderModal format="landscape" groupId={currentGroup.id} show={showBannerDialog} onClose={() => setShowBannerDialog(false)} onCancel={() => setShowBannerDialog(false)} onSaved={onBannerSaved} />

      <div className="mt-3">
        <Form.Field className="is-grouped">
          <Form.Control className="is-expanded">
            <Form.Label>Company name</Form.Label><Form.Input color={formErrors.companyName ? 'danger' : ''} value={currentGroup.companyName} onChange={e => { updateCurrentGroupProp('companyName', e.target.value); updateCurrentGroupProp('name', e.target.value)}}></Form.Input>
            { formErrors.companyName && <Form.Help color="danger">{formErrors.companyName}</Form.Help>}
          </Form.Control>
          <Form.Control className="is-expanded">
            <Form.Label>Company website</Form.Label><Form.Input color={formErrors.companyWebsite ? 'danger' : ''} value={currentGroup.companyWebsite} onChange={e => updateCurrentGroupProp('companyWebsite', e.target.value)}></Form.Input>
            { formErrors.companyWebsite && <Form.Help color="danger">{formErrors.companyWebsite}</Form.Help>}
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Description<span style={{color: '#999', fontSize: 'x-small', paddingLeft: 7}}>(internal only - will not be displayed to users)</span></Form.Label><Form.Input value={currentGroup.description} onChange={e => setCurrentGroup({ ...currentGroup, description: e.target.value })}></Form.Input>
        </Form.Field>

        { currentGroup.id > 0 &&
          <Form.Field>
            <Form.Label>Cohort Status</Form.Label>
            <div className="field">
              <input id="enabledSwitch" type="checkbox" name="enabledSwitch" className="switch" checked={currentGroup.enabled} onChange={e => changeGroupEnabledState(e.target.checked)} />
              <label htmlFor="enabledSwitch">Active</label>
            </div>
          </Form.Field>        
        }

        <Form.Field>
            <Form.Label>Mentorship duration</Form.Label>
            <div className='is-flex is-align-items-baseline'>
            <Form.Input style={{width: '5rem'}} type="number" color={formErrors.mentorshipDuration ? 'danger' : ''} value={currentGroup.mentorshipDuration} onChange={e => updateCurrentGroupProp('mentorshipDuration', e.target.value)}></Form.Input>
            <Form.Label className="ml-2">weeks.</Form.Label>
            </div>
            { formErrors.mentorshipDuration && <Form.Help color="danger">{formErrors.mentorshipDuration}</Form.Help>}
        </Form.Field>  

        { currentGroup.id &&
          <Form.Field className="is-grouped">
            <Form.Control>
              <Form.Label>Color 1</Form.Label>
              <ChromePicker color={currentGroup.color1} onChangeComplete={(color) => setCurrentGroup({ ...currentGroup, color1: color.hex })} />
            </Form.Control>
            <Form.Control className="pl-4">
              <Form.Label>Color 2</Form.Label>
              <ChromePicker color={currentGroup.color2} onChangeComplete={(color) => setCurrentGroup({ ...currentGroup, color2: color.hex })} />
            </Form.Control>
          </Form.Field>
        }

        <div className="is-flex mt-6" style={{ width: '100%' }}>
          <div>
            <Form.Control>
              <Form.Label>Signup QR Code</Form.Label>
              <QRCode id="signupQRCode" size={128} value={signupUrl} />
              <Button className="is-outlined is-small" style={{width: 128}} onClick={() => downloadQR()}>Download</Button>
            </Form.Control>
          </div>
          <div className="ml-4" style={{ width: '100%' }}>
            <Form.Label>Signup Code</Form.Label>
            <Form.Field color="danger" className="has-addons is-danger">
              <Form.Control style={{ width: '10rem' }}>
                <Form.Input color={formErrors.signupCode ? 'danger' : ''} value={currentGroup.signupCode} onChange={e => updateCurrentGroupProp('signupCode', e.target.value)}></Form.Input>
                { formErrors.signupCode && <Form.Help color="danger">{formErrors.signupCode}</Form.Help>}
              </Form.Control>
              <Form.Control color={formErrors.signupCode ? 'danger' : ''}>
                <Button title="New Signup Code" onClick={() => askAndGenerateNewSignupCode()}><FontAwesomeIcon icon={faRedo} /></Button>
              </Form.Control>
            </Form.Field>
            <Form.Label>Signup Link</Form.Label>
            <Form.Field className="has-addons">
              <Form.Control className="is-expanded">
                <Form.Input value={signupUrl} disabled={true} style={{ backgroundColor: 'white' }}></Form.Input>
              </Form.Control>
              <Form.Control>
                <Button title="Copy" onClick={() => { navigator.clipboard.writeText(signupUrl); toast.success('Signup URL copied to clipboard'); }}><FontAwesomeIcon icon={faCopy} /></Button>
              </Form.Control>
            </Form.Field>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <Button.Group>
          {currentGroup.id ? <Button className="is-success" onClick={saveChanges}>Save</Button> :
            <Button className="is-success" onClick={newGroup}>Save</Button>
          }
          <Button className="is-outlined" onClick={props.onCancel}>Cancel</Button>
        </Button.Group>
      </div>
    </>
  }

  const SessionsSection = () => {
    return <>
      {currentGroup.id > 0 && <>
            <div className="is-flex is-justify-content-flex-end">
              { data && data.length > 0 && <Button className="is-success" onClick={newSession}>New Session</Button> }
            </div>

          <div className="mt-3">
            { data && data.length > 0 ? <>
              <TernTable hover={true} columns={sessionTableColumns} data={data} onRowClick={onTableRowClick} />
              </>
            : <>
              <div className="has-text-centered">
                <div>No sessions yet.</div>
                <Button className="mt-3 is-success" onClick={newSession}>Create A Session</Button>
              </div>
            </>}
          {showSessionModal === true &&
            <SessionModal onSave={saveSession} open={showSessionModal === true} onCancel={cancelSession} onDelete={removeSession} session={currentSession} />
          }
          </div>
      </>
      }
    </>
  }
  
  const MentorshipsSection = () => {
    return <>
    {currentGroupId > 0 && <>
      <Mentorships showButtons={true} showHeading={false} groupId={currentGroupId} /> </>
    }</>
  }

  const UsersSection = () => {
    return <>
    {currentGroupId > 0 && <>
      <nav class="level has-background-light py-3">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Mentors</p>
            <p class="title">{counts.mentors}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Mentees</p>
            <p class="title">{counts.mentees}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Total</p>
            <p class="title">{counts.total}</p>
          </div>
        </div>
      </nav>

    <div className="is-flex is-align-items-end is-justify-content-space-between mb-3">
      <Dropdown
          right={true}
          closeOnSelect={false}
          color=""
          icon={<FontAwesomeIcon icon={faAngleDown} className="ml-3" />}
          label="User types"
          value=""
        >
        {userTypesForUserTable.map((userType) =>
            <Dropdown.Item key={`dropdownitem_usertype_input_${userType.id}`} renderAs="div">
              <div>
                <label className="checkbox">
                  <input id={`usertype_input_${userType.id}`} 
                    onChange={e => updateSelectedUserTypesForTable(userType.id, e.target.checked)}
                    type="checkbox" 
                    name={`usertype_input_${userType.id}`} 
                    checked={selectedUserTypesForUserTable.includes(userType.id)} className="mr-2" />
                      {userType.description}
                </label>
              </div>
            </Dropdown.Item>
          )}
          </Dropdown>
          <Button className="is-success" onClick={newUser}>Transfer User</Button>
    </div>
    
    <TernTable hover={true} showTotal={false} columns={usersTableColumns} data={usersMemoData} onRowClick={onUsersTableRowClick} initialSort={[{id: 'last_name', desc: false}]} />
    {showUserModal === true &&
            <UserPickModal users={switchableUsers} onSwitchUser={switchUser} open={showUserModal === true} onCancel={cancelUser} />
          }
    </>}
    </>
  }

  const SurveysSection = () => {
    return <>
      {currentGroupId > 0 && <>
            <div className="is-flex is-justify-content-space-between">
            <Form.Field>
              {/* <Form.Label>Survey Type</Form.Label> */}
                    <Form.Select value={selectedSurveyTypes[0]} onChange={e => setSelectedSurveyTypes([parseInt(e.target.value)])}>
                    
                    {surveyTypes.map((surveyType) => <option key={`dropdown_surveytype_${surveyType.id}`} value={surveyType.id}>{`${surveyType.description}`}</option>)}
                    </Form.Select>
              </Form.Field>

              { surveyData && surveyData.length > 0 && <Button className="is-success" onClick={newSurveyQuestion}>New Question</Button> }
            </div>
            { surveyData && surveyData.length > 0 ? <>
              <div className="level-left mt-3">
              {/* <Dropdown
                right={true}
                closeOnSelect={false}
                color=""
                icon={<FontAwesomeIcon icon={faAngleDown} className="ml-3" />}
                label="Survey types"
                value=""
               >
              {surveyTypes.map((surveyType) =>
              <Dropdown.Item key={`dropdownitem_surveytype_input_${surveyType.id}`} renderAs="div">
              <div>
                <label className="checkbox">
                  <input id={`surveytype_input_${surveyType.id}`} 
                    onChange={e => updateSelectedSurveyTypes(surveyType.id, e.target.checked)}
                    type="checkbox" 
                    name={`surveytype_input_${surveyType.id}`} 
                    checked={selectedSurveyTypes.includes(surveyType.id)} className="mr-2" />
                      {surveyType.description}
                </label>
              </div>
            </Dropdown.Item> 
          )}
            </Dropdown>*/}
            </div>
              <TernTable hover={true} columns={surveyTableColumns} data={surveyMemoData} onRowClick={onSurveyTableRowClick} />
              </>
            : <>
              <div className="has-text-centered">
                <div>No survey yet.</div>
                <Button className="mt-3 is-success" onClick={newSurveyQuestion}>Create A Question</Button>
              </div>
            </>}
          {showSurveyModal === true &&
            <SurveyQuestionModal title= {getCurrentSurveyTitle()} onSave={saveSurveyQuestion} open={showSurveyModal === true} onCancel={cancelSurveyQuestion} surveyQuestion={currentSurvey} />
          }
      </>
      }
    </>

  }

  const [showAddPageModal, setShowAddPageModal] = useState();
  const [editingPage, setEditingPage] = useState();
  const [addingtoPageId, setAddingtoPageId] = useState();

  const bottomRef = useRef(null);

  function toggleCollapsedPage(pageId) {
    if (collapsedCards.includes(pageId)) {
      setCollapsedCards(collapsedCards.filter(f => f !== pageId));
    }
    else {
      setCollapsedCards([...collapsedCards, pageId]);
    }
  }

  function permutePageOrder(toPermute){
    const purmutationData = {source: { ...toPermute.source}, dest: { ...toPermute.dest}};

    setNewPageOrder(currentGroupId,toPermute.source.pageId, toPermute.dest.pageOrder, toPermute.dest.pageId, toPermute.source.pageOrder);
    let pageOneIdx = pages.findIndex(p => p.pageId === purmutationData.source.pageId);
    pages[pageOneIdx].pageOrder = purmutationData.dest.pageOrder;

    let pageTwoIdx = pages.findIndex(p => p.pageId === purmutationData.dest.pageId);
    pages[pageTwoIdx].pageOrder = purmutationData.source.pageOrder;

    setPages([...pages].sort((a,b) => a.pageOrder - b.pageOrder));
  }

  async function savePage(page) {
    let result = null;
    try {
      if (!page.pageId) {
        result = await addGroupMetricsPage(currentGroupId, page);
      }
      else {
        result = await editGroupMetricsPage(currentGroupId, page.pageId, page);
      }

      if (!page.pageId) {
        page = {...result};
        page.questions = [];
        setPages([...pages, page]);
      }
      else {
        let existingPageIdx = pages.findIndex(f => f.pageId === page.pageId);
        if (existingPageIdx > -1) {
          pages[existingPageIdx] = { ...pages[existingPageIdx], ...page };
          setPages([...pages]);
        }
      }

      setEditingPage(null); 
      setShowAddPageModal(false);
      setTimeout(() => {
      bottomRef.current?.scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "smooth",
        alignToTop: false
      })}, 200);
    }
    catch (err) {
      console.error(`Error ${page.pageId ? 'editing' : 'adding'} page`, err);
    }
  }

  async function deletePage(pageId) {
    try {
      if (window.confirm('Are you sure you want to delete this page?')) {
        await deleteGroupMetricsPage(currentGroupId, pageId);
        setPages(pages.filter(p => p.pageId !== pageId));
      }
    }
    catch (err) {
      console.error(`Error deleting ${pageId}`, err);
    }
  }


  const MetricsSection = () => {
    return <>
      { isLoading && currentGroupId > 0 ? <ProgressComponent /> : 
        <>
          <div className="mb-5 has-text-weight-bold is-flex is-justify-content-space-between">
            <div>Anyone Questions: {questionStats.totalAnyone}</div>
            <div>Mentee Questions: {questionStats.totalMentee}</div>
            <div>Mentor Questions: {questionStats.totalMentor}</div>
            <div>TOTAL: {questionStats.totalQuestions}</div>
          </div>
            <div className="is-flex is-justify-content-flex-start mb-3">
              {/* <Form.Field>
                <Form.Select value={selectedUserTypes[0]} onChange={e => setSelectedUserTypes([parseInt(e.target.value)])}>                
                      {metricsUserTypes.map((userType) => <option key={`dropdown_metrics_user_type_${userType.id}`} value={userType.id}>{`${userType.description}`}</option>)}
                      </Form.Select>
                </Form.Field> */}
                
                <Button className="is-success" onClick={() => { setEditingPage(null); setShowAddPageModal(true); }}>Add A Page</Button>
              
              </div>
              { pages && pages.length > 0 ? <>

                { pages.map((page, idx) =>
                  <div key={`page_${page.pageId}`} className="mb-4 card is-fullwidth">
                    <header className="card-header">
                      <p className="card-header-title" style={{cursor: 'pointer'}} onClick={() => toggleCollapsedPage(page.pageId)}>{page.pageTitle}</p>
                      {/* <p className="card-header-title" style={{cursor: 'pointer'}} onClick={() => toggleCollapsedPage(page.pageId)}>{page.pageOrder ? `Page ${idx+1}: ${page.pageTitle}` : `${page.pageTitle}`}</p> */}
                      <a className="card-header-icon" onClick={() => { setEditingPage(page); setShowAddPageModal(true); }}>
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                      <button className="card-header-icon" title="Move up" disabled={idx === 0} onClick={(e) => { permutePageOrder({ source: pages[idx], dest: pages[idx-1]}); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowUp} /></button>
                      <button className="card-header-icon" title="Move down" disabled={idx === pages.length - 1} onClick={(e) => { permutePageOrder({ source: pages[idx], dest: pages[idx+1]}); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowDown} /></button>
                      <button className="card-header-icon has-text-danger" title="Remove" onClick={() => { deletePage(page.pageId); }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      <button className="card-header-icon card-toggle" title="Remove" onClick={() => toggleCollapsedPage(page.pageId)}>
                        <FontAwesomeIcon icon={collapsedCards.includes(page.pageId) ? faAngleDown : faAngleUp} />
                      </button>
                    </header>
                    <div className={`card-content ${collapsedCards.includes(page.pageId) ? 'is-hidden' : ''}`}>
                      <div className="content">
                        { page.questions && page.questions.length ? 
                          <TernTable columns={metricsTableColumns} data={page.questions} showTotal={true} />
                        :
                          <div className="has-text-centered">No questions on this page</div>
                        }
                        <div className="has-text-centered mt-3"><Button className="is-success" onClick={() => { setAddingtoPageId(page.pageId); addMetrics()}}>Add A Question</Button></div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="level-left mt-3"></div>
                  <TernTable hover={true} columns={metricsTableColumns} data={metricsMemoData} /> */}
                </>
              : <>
                <div className="has-text-centered">
                  <div>No onboarding questions associated with this company yet.</div>
                </div>
              </>}
              <div className="bottomContainerElement" ref={bottomRef} />
              {showMetricsModal === true &&
                <SelectMetricsModal editing={selectedQuestion} title= {getCurrentMetricsTitle()} alreadyUsed = {metricsIdList} metrics={metrics} userType = {selectedUserTypes[0]} groupId={currentGroupId} onSave={addMetricsToGroup} open={showMetricsModal === true} onCancel={cancelAddMetrics}/> 
              }
              { showAddPageModal && <AddPageModal page={editingPage} open={showAddPageModal} onSave={savePage} onCancel={() => setShowAddPageModal(false)} /> }
        </>
        }
    </>

  }

  function cancelAddMetrics(){
    setShowMetricsModal(false);
    setSelectedQuestion();
  }
  
  function getCurrentMetricsTitle(){
    
    if (selectedUserTypes[0] === 1)
      return 'Metrics for Mentees only';
    else if (selectedUserTypes[0] === 2)
      return 'Metrics for Mentors only';
    else if (selectedUserTypes[0] === 3)
      return 'Metrics for Mentees and Mentors';

  }

  function changeGroupEnabledState(newState) {
    if (window.confirm(`Do you want to really want to ${newState ? 'enable' : 'disable'} access to the public for this group?`)) {
      const newGroupState = { ...currentGroup, enabled: newState };
      setCurrentGroup(newGroupState);
      updateGroup(currentGroup.id, newGroupState);
    }
  }

function getCurrentSurveyTitle(){
    let title = "";
    if (currentSurvey.id === 0)
      title = 'New ';
    if (currentSurvey.userType === 1 && currentSurvey.surveyType === 1)
      return title + 'Mentee Quick Survey #' + currentSurvey.orderNum;
    else if (currentSurvey.userType === 1 && currentSurvey.surveyType === 2)
      return title + 'Mentee Final Survey #' + currentSurvey.orderNum;
    else if (currentSurvey.userType === 2 && currentSurvey.surveyType === 1)
      return title + 'Mentor Quick Survey #' + currentSurvey.orderNum;
    else if (currentSurvey.userType === 2 && currentSurvey.surveyType === 2)
      return title + 'Mentor Final Survey #' + currentSurvey.orderNum;
  }

  return (
    <>
      {isLoading ? <ProgressComponent /> : 
      <div className="is-flex">
        <div className="left-nav">
          <Menu>
            <Menu.List>
              <Menu.List.Item active={!viewingTab} onClick={()=>setViewingTab(null)}>Branding</Menu.List.Item>
              <Menu.List.Item active={viewingTab === 'users'} onClick={()=>setViewingTab('users')}>Users</Menu.List.Item>
              <Menu.List.Item active={viewingTab === 'mentorships'} onClick={()=>setViewingTab('mentorships')}>Mentorships</Menu.List.Item>
              <Menu.List.Item active={viewingTab === 'onboarding'} onClick={()=>setViewingTab('onboarding')}>Onboarding</Menu.List.Item>
              <Menu.List.Item active={viewingTab === 'sessions'} onClick={()=>setViewingTab('sessions')}>Sessions</Menu.List.Item>
              <Menu.List.Item active={viewingTab === 'surveys'} onClick={()=>setViewingTab('surveys')}>Surveys</Menu.List.Item>
              <Menu.List.Item active={viewingTab === 'offboarding'} onClick={()=>setViewingTab('offboarding')}>Offboarding</Menu.List.Item>
              <Menu.List.Item active={viewingTab === 'reports'} onClick={()=>setViewingTab('reports')}>Reports</Menu.List.Item>
              <Menu.List.Item active={viewingTab === 'notifications'} onClick={()=>setViewingTab('notifications')}>Notifications</Menu.List.Item>

              {/* <NavLink to="/admin/dashboard" exact={true} activeClassName="is-active">Branding</NavLink>
              <NavLink to="/admin/users" exact={true} activeClassName="is-active">Users</NavLink>
              <NavLink to="/admin/users" exact={true} activeClassName="is-active">Mentorships</NavLink>
              <NavLink to="/admin/users" exact={true} activeClassName="is-active">Onboarding</NavLink>
              <NavLink to="/admin/users" exact={true} activeClassName="is-active">Sessions</NavLink>
              <NavLink to="/admin/users" exact={true} activeClassName="is-active">Surveys</NavLink>
              <NavLink to="/admin/users" exact={true} activeClassName="is-active">Offboarding</NavLink>
              <NavLink to="/admin/users" exact={true} activeClassName="is-active">Reports</NavLink>
              <NavLink to="/admin/users" exact={true} activeClassName="is-active">Notifications</NavLink> */}
            </Menu.List>
          </Menu>
        </div>
        <div className="left-nav-right-content">
          <Switch>
            {/* <ProtectedRoute exact path="/admin/dashboard">
              <AdminDashboard />
            </ProtectedRoute> */}

          </Switch>

          <div className="edit-group-page">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li><NavLink to="/groups">Cohorts</NavLink></li>
                {/* <li><a href="#">Documentation</a></li>
                <li><a href="#">Components</a></li> */}
                { currentGroup?.id && <li className="is-active"><a href="#">{currentGroup.companyName}</a></li> }
                { currentGroup?.id && <li className="is-active is-capitalized"><a href="#" aria-current="page">{viewingTab ? viewingTab : 'Branding'}</a></li> }
                { !currentGroup?.id && <li className="is-active"><a href="#">( New cohort )</a></li> }
              </ul>
            </nav>

            {/* <div className="is-flex is-justify-content-space-between is-align-items-center">
              <div className="mb-5">
                {currentGroup.id > 0 ? <>
                  <Heading>{currentGroup.companyName}</Heading>
                  <Heading subtitle>Company</Heading>
                  </>
                  :
                  <Heading>New Company</Heading>
                }
              </div>
            </div> */}

            {/* { currentGroup.id && 
              <div className="tabs is-toggle is-toggle-rounded is-fullwidth">
                <ul>
                  <li className={`${!viewingTab ? ' is-active' : ''}`} onClick={() => setViewingTab(null)}><a>Branding</a></li>
                  <li className={`${viewingTab === 'onboarding' ? ' is-active' : ''}`} onClick={() => setViewingTab('onboarding')}><a>Onboarding</a></li>
                  <li className={`${viewingTab === 'offboarding' ? ' is-active' : ''}`} onClick={() => setViewingTab('offboarding')}><a>Offboarding</a></li>
                  <li className={`${viewingTab === 'sessions' ? ' is-active' : ''}`} onClick={() => setViewingTab('sessions')}><a>Sessions</a></li>
                  <li className={`${viewingTab === 'surveys' ? ' is-active' : ''}`} onClick={() => setViewingTab('surveys')}><a>Surveys</a></li>
                  <li className={`${viewingTab === 'users' ? ' is-active' : ''}`} onClick={() => setViewingTab('users')}><a>Users</a></li>
                  <li className={`${viewingTab === 'mentorships' ? ' is-active' : ''}`} onClick={() => setViewingTab('mentorships')}><a>Mentorships</a></li>
                  <li className={`${viewingTab === 'reports' ? ' is-active' : ''}`} onClick={() => setViewingTab('reports')}><a>Reports</a></li>
                </ul>
              </div>
            } */}

            { !viewingTab && BrandingSection() }
            { viewingTab === 'sessions' && SessionsSection() }
            { viewingTab === 'surveys' && SurveysSection() }
            { viewingTab === 'onboarding' && MetricsSection() }
            { viewingTab === 'offboarding' && <OffboardingQuestions groupId={currentGroupId} /> }
            { viewingTab === 'users' && UsersSection() }
            { viewingTab === 'mentorships' && MentorshipsSection() }
            { viewingTab === 'reports' && <Reports groupId={currentGroupId} groupName={currentGroup.name}/> }
            { viewingTab === 'notifications' && <NotificationEditor groupId={currentGroupId} /> }
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default Group;