import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faAngleDown, faAngleUp, faArrowUp, faArrowDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import { getMetrics } from '../../lib/api';

import ProgressComponent from '../ProgressComponent/ProgressComponent';
import TernTable from '../TernTable/TernTable';
import AddPageModal from './AddPageModal';
import SelectMetricsModal from '../Metrics/SelectMetricsModal';

function Questions({groupId, isLoading, pages, setPages, onSavePage, onQuestionToPage, onRemoveQuestionFromPage, onDeletePage, onSwapPages, onSwapQuestions}) {
  const [ questionsLoading, setQuestionsLoading ] = useState(true);
  const [ questionStats, setQuestionStats ] = useState({ totalPages: 0, totalQuestions: 0, totalAnyone: 0, totalMentee: 0, totalMentor: 0});

  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showMetricsModal, setShowMetricsModal] = useState(false);

  const [showAddPageModal, setShowAddPageModal] = useState();
  const [collapsedCards, setCollapsedCards] = useState();
  const [editingPage, setEditingPage] = useState();
  const [addingtoPageId, setAddingtoPageId] = useState();

  const bottomRef = useRef(null);

  function selectQuestion(question) {
    setSelectedQuestion(question);
    setShowMetricsModal(true);
  }

  function addQuestionToPage(){
    setShowMetricsModal(true);
  }

  function cancelAddQuestionToPage() {
    setShowMetricsModal(false);
    setSelectedQuestion(null);
  }

  // Get all questions from catalog
  useEffect(() => {
    console.log('CATALOG');
    if (groupId > 0) {
      setQuestionsLoading(true);
      getMetrics()
        .then((catalogQuestions) => {
          setQuestions(catalogQuestions);
        })
        .catch((err) => {
          console.error('getMetrics err', err)
        })
        .finally(() => {
          setQuestionsLoading(false);
        })
    }
  }, [groupId])

  // Update question stats
  useEffect(() => {
    let stats = { totalPages: pages.length, totalQuestions: 0, totalAnyone: 0, totalMentee: 0, totalMentor: 0};

    pages.forEach((pages) => {
      if (pages.questions) {
        pages.questions.forEach(question => {
          stats.totalQuestions++;

          if (question.userType === 1) stats.totalMentee++;
          if (question.userType === 2) stats.totalMentor++;
          if (question.userType === 3) stats.totalAnyone++;
        });
      }
    });

    setQuestionStats(stats);

    if (!collapsedCards && pages.length > 0) {
      setCollapsedCards(pages.map(page => page.pageId).slice(1));
    }

  }, [pages, collapsedCards])

  const removeQuestionFromPage = useCallback((questionToDelete) => {
    if (onRemoveQuestionFromPage) {
      if (window.confirm('Do you really want to remove this question from the page?')) {
          onRemoveQuestionFromPage(questionToDelete.id, groupId)
            .then(() => { 
              // What page was it on?
              const newPages = pages.map(thePage => {
                return {
                  ...thePage, 
                  questions: thePage?.questions ? thePage.questions.filter(q => q.id !== questionToDelete.id) : null,
                }
              })

              setPages(newPages);
            })
            .catch(err => {
              console.error('Error removing question from page', err);
            })
        }
      }
    
  }, [onRemoveQuestionFromPage, setPages, groupId, pages]);

  async function questionOnPage(questionId, isRequired, isShared) {
    setShowMetricsModal(false);

    if (onQuestionToPage) {
      try {
        const editing = selectedQuestion !== null;
        const result = await onQuestionToPage(editing, editing ? selectedQuestion.id : questionId, !isRequired, isShared, addingtoPageId);

        //setMetricsIdList([...metricsIdList, metricsId]);  TODO?

        const pageIdx = pages.findIndex(p => p.pageId === result.pageId);
        if (pageIdx > -1) {
          if (selectedQuestion) {
            const questionIdx = pages[pageIdx].questions.findIndex(q => q.id === selectedQuestion.id);
            pages[pageIdx].questions[questionIdx] = { ...pages[pageIdx].questions[questionIdx], optional: !isRequired};
            pages[pageIdx].questions = [...pages[pageIdx].questions];
          }
          else {
            if (pages[pageIdx].questions) {
              pages[pageIdx].questions = [...pages[pageIdx].questions, result];
            }
            else {
              pages[pageIdx].questions = [result];
            }
          }
          setPages([...pages]);
        }
        setSelectedQuestion(null);
      }
      catch (err) {
        console.error('Error processing question for page', err);
        toast.error('Error processing question for page');
      } 
    }
  }

  // Pages
  async function savePage(page) {
    let result = null;
    try {
      if (onSavePage) {
        result = await onSavePage(page.pageId, page);

        if (!page.pageId) {
          page = {...result};
          page.questions = [];
          setPages([...pages, page]);
        }
        else {
          let existingPageIdx = pages.findIndex(f => f.pageId === page.pageId);
          if (existingPageIdx > -1) {
            pages[existingPageIdx] = page;
            setPages([...pages]);
          }
        }

        setEditingPage(null); 
        setShowAddPageModal(false);
        setTimeout(() => {
        bottomRef.current?.scrollIntoView({
          block: "nearest",
          inline: "center",
          behavior: "smooth",
          alignToTop: false
        })}, 200);
      }
      }
    catch (err) {
      console.error(`Error ${page.pageId ? 'editing' : 'adding'} page`, err);
    }
  }
  
  function toggleCollapsedPage(pageId) {
    if (collapsedCards.includes(pageId)) {
      setCollapsedCards(collapsedCards.filter(f => f !== pageId));
    }
    else {
      setCollapsedCards([...collapsedCards, pageId]);
    }
  }
  
  async function swapPages(firstPageId, secondPageId) {
    if (onSwapPages) {
      const newOrderInfo = await onSwapPages(groupId, firstPageId, secondPageId);
      console.log('newOrderInfo', newOrderInfo)

      const pageOneIdx = pages.findIndex(p => p.pageId === newOrderInfo[0].pageId);
      const pageTwoIdx = pages.findIndex(p => p.pageId === newOrderInfo[1].pageId);

      pages[pageOneIdx].pageOrder = newOrderInfo[0].orderNum;
      pages[pageTwoIdx].pageOrder = newOrderInfo[1].orderNum;

      setPages([...pages].sort((a,b) => a.pageOrder - b.pageOrder));
    }
  }
  
  const swapQuestions = useCallback((pageId, firstQuestionId, secondQuestionId) => {
    if (onSwapQuestions) {
      onSwapQuestions(groupId, firstQuestionId, secondQuestionId)
        .then(newOrderInfo => {
          const pageIdx = pages.findIndex(p => p.pageId === pageId);
          if (pageIdx > -1) {
            let page = pages[pageIdx];

            let sourceQuestion = page.questions.find(f => f.id === firstQuestionId);
            let destQuestion = page.questions.find(f => f.id === secondQuestionId);
      
            const origSourceOrderNum = sourceQuestion.orderNum;
      
            sourceQuestion = {
              ...sourceQuestion,
              orderNum: destQuestion.orderNum
            }
      
            destQuestion = {
              ...destQuestion,
              orderNum: origSourceOrderNum
            }
            
            const filteredPages = page.questions.filter(q => q.id !== sourceQuestion.id && q.id !== destQuestion.id);
            pages[pageIdx].questions = [...filteredPages, sourceQuestion, destQuestion].sort((a,b) => a.orderNum - b.orderNum);
            setPages([...pages]);
          }
        })
      }
  }, [groupId, pages, setPages, onSwapQuestions]);

  async function deletePage(pageId) {
    if (onDeletePage) {
      try {
        if (window.confirm('Are you sure you want to delete this page?')) {
          await onDeletePage(groupId, pageId);
          setPages(pages.filter(p => p.pageId !== pageId));
        }
      }
      catch (err) {
        console.error(`Error deleting ${pageId}`, err);
      }
    }
  }

  const questionsTableColumns = useMemo(
    () => [
      // {
      //   Header: 'Page',
      //   accessor: 'pageTitle',
      // },
      {
        Header: 'Question',
        accessor: 'description',
        disableSortBy: true,
        Cell: prop => <div className="is-link" style={{cursor: 'pointer', width: '100%'}} onClick={() => selectQuestion(prop.row.original)}><a>{prop.value}</a></div>
      },
      {
        Header: 'Audience',
        accessor: 'userType',
        collapse: true,
        disableSortBy: true,
        Cell: prop => <div className="has-text-centered"><div className={`tag ${ prop.value === 1 ? 'is-info' : (prop.value === 2 ? 'is-link' : '') }`}>{ prop.value === 1 ? 'Mentee' : (prop.value === 2 ? 'Mentor' : 'Anyone') }</div></div>
      },
      {
        Header: 'Required',
        accessor: 'optional',
        collapse: true,
        disableSortBy: true,
        Cell: prop => <div className="has-text-centered">{ prop.value ? 'No' : 'Yes' }</div>
      },
      {
        Header: 'Answer Type',
        accessor: 'metricsDescription',
        disableSortBy: true,
        mediumcolumn: true,
      },  
      {
        Header: '',
        accessor: 'orderNum',
        disableSortBy: true,
        collapse: true,
        Cell: prop => { 
          return <div className="is-flex">
            <button name="MoveUp" title="Move Question Up" disabled={prop.row.index === 0} onClick={(e) => { swapQuestions(prop.rows[prop.row.index].original.pageId, prop.rows[prop.row.index].original.id, prop.rows[prop.row.index-1].original.id); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowUp} /></button>
            <button name="MoveDown" title="Move Question Down" disabled={prop.row.index === prop.rows.length-1} onClick={(e) => { swapQuestions(prop.rows[prop.row.index+1].original.pageId, prop.rows[prop.row.index+1].original.id, prop.rows[prop.row.index].original.id); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowDown} /></button>
          </div>
          },
      },
      {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      collapse: true,
      Cell: prop => <button name="Delete" title="Remove Question" onClick={(e) => { removeQuestionFromPage({ id: prop.value, rows: prop.rows}); e.stopPropagation(); }}><FontAwesomeIcon icon={faTrash} /></button>
    },
    ],
    [swapQuestions, removeQuestionFromPage]
  )

  return <>
    { isLoading && groupId > 0 ? <ProgressComponent /> : 
      <>
        <div className="mb-5 has-text-weight-bold is-flex is-justify-content-space-between">
          <div>Anyone Questions: {questionStats.totalAnyone}</div>
          <div>Mentee Questions: {questionStats.totalMentee}</div>
          <div>Mentor Questions: {questionStats.totalMentor}</div>
          <div>TOTAL: {questionStats.totalQuestions}</div>
        </div>
          <div className="is-flex is-justify-content-flex-start mb-3">
            {/* <Form.Field>
              <Form.Select value={selectedUserTypes[0]} onChange={e => setSelectedUserTypes([parseInt(e.target.value)])}>                
                    {metricsUserTypes.map((userType) => <option key={`dropdown_metrics_user_type_${userType.id}`} value={userType.id}>{`${userType.description}`}</option>)}
                    </Form.Select>
              </Form.Field> */}
              
              <Button className="is-success" onClick={() => { setEditingPage(null); setShowAddPageModal(true); }}>Add A Page</Button>
            
            </div>
            { pages && pages.length > 0 ? <>

              { pages.map((page, idx) =>
                <div key={`page_${page.pageId}`} className="mb-4 card is-fullwidth">
                  <header className="card-header">
                    <p className="card-header-title" style={{cursor: 'pointer'}} onClick={() => toggleCollapsedPage(page.pageId)}>{page.pageTitle}<span className="ml-2 is-size-7 has-text-weight-normal has-text-grey">{ page.questions && page.questions.length ? `${page.questions.length} questions` : `0 questions`}</span></p>
                    <Button className="card-header-icon" onClick={() => { setEditingPage(page); setShowAddPageModal(true); }}><FontAwesomeIcon icon={faEdit} /></Button>

                    <Button className="card-header-icon" title="Move up" disabled={idx === 0} onClick={(e) => { swapPages(pages[idx].pageId, pages[idx-1].pageId); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowUp} /></Button>
                    <Button className="card-header-icon" title="Move down" disabled={idx === pages.length - 1} onClick={(e) => { swapPages(pages[idx].pageId, pages[idx+1].pageId); e.stopPropagation();}}><FontAwesomeIcon icon={faArrowDown} /></Button>

                    <Button className="card-header-icon has-text-danger" title="Remove" onClick={() => { deletePage(page.pageId); }}><FontAwesomeIcon icon={faTrash} /></Button>
                    <Button className="card-header-icon card-toggle" title="Remove" onClick={() => toggleCollapsedPage(page.pageId)}><FontAwesomeIcon icon={collapsedCards && collapsedCards.includes(page.pageId) ? faAngleDown : faAngleUp} /></Button>
                  </header>
                  <div className={`card-content ${collapsedCards && collapsedCards.includes(page.pageId) ? 'is-hidden' : ''}`}>
                    <div className="content">
                      { page.questions && page.questions.length ? 
                        <TernTable columns={questionsTableColumns} data={page.questions} showTotal={true} />
                      :
                        <div className="has-text-centered">No questions on this page</div>
                      }
                      <div className="has-text-centered mt-3"><Button className="is-success" onClick={() => { setAddingtoPageId(page.pageId); addQuestionToPage()}}>Add A Question</Button></div>
                    </div>
                  </div>
                </div>
              )}
              </>
            : <>
              <div className="has-text-centered">
                <div>No offboarding questions associated with this company yet.</div>
              </div>
            </>}
            <div className="bottomContainerElement" ref={bottomRef} />
            { showMetricsModal && <SelectMetricsModal open={showMetricsModal} questionsLoading={questionsLoading} editing={selectedQuestion} title={selectedQuestion ? "Edit question" : "Add question to page"} alreadyUsed = {[]} metrics={questions} groupId={groupId} onSave={questionOnPage} onCancel={cancelAddQuestionToPage}/> }
            { showAddPageModal && <AddPageModal page={editingPage} open={showAddPageModal} onSave={savePage} onCancel={() => setShowAddPageModal(false)} /> } 
      </>
      }
  </>

}

export default Questions;