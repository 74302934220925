import { useState, useEffect } from 'react';
import { Modal, Form, } from 'react-bulma-components';

function AddPageModal( { page, onSave, onCancel, open } ) {
  
  const [pageTitle, setPageTitle] = useState(page?.pageTitle || '');
  const [pageHeading, setPageHeading] = useState(page?.pageHeading || '');
  const [pageSubheading, setPageSubheading] = useState(page?.pageSubheading || '');
  const [isHorizontal, setIsHorizontal] = useState(page?.pageParams?.layout === 'horizontal' || false);
  const [showPageTitle, setShowPageTitle] = useState(page?.pageParams?.showPageTitle || false);

  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setCanSave(pageTitle.trim());
  }, [page, pageTitle])

  async function save() {
      if (onSave) {
        let newData = {
          pageId: page?.pageId,
          pageOrder: page?.pageOrder, 
          pageTitle: pageTitle, 
          pageHeading: pageHeading, 
          pageSubheading: pageSubheading, 
          pageParams: {
            showPageTitle,
          }
        }

        if (isHorizontal) {
          newData.pageParams.layout = 'horizontal';
        }

        onSave(newData);
      }
      else {
        console.error('No onSave function provided');
      }
  }
  async function cancel() {
    if (onCancel) {
      onCancel();
    }
    else {
      console.error('No onCancel function provided');
    }
}

// async function deleteSession() {
//   if (props.onDelete) {
//     props.onDelete(currentSession);
//   }
//   else {
//     console.error('No onDelete function provided');
//   }
// }

  return (
    <Modal show={open} onClose={cancel}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">{page?.pageId ? 'Edit' : 'Add'} Page</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
          <Form.Field>
              <Form.Label>Title</Form.Label>
              <Form.Input autoFocus placeholder="" value={pageTitle} onChange={e => setPageTitle(e.target.value)}></Form.Input>
          </Form.Field>
          <Form.Field>
              <Form.Label>Heading</Form.Label>
              <Form.Textarea placeholder="Description or instructions that will appear at the top of the page" value={pageHeading} onChange={e => setPageHeading(e.target.value)}></Form.Textarea>
          </Form.Field>
          {/* <Form.Field>
              <Form.Label>Heading</Form.Label>
              <Form.Textarea placeholder="Description or instructions that will appear at the top of the page" value={pageHeading} onChange={e => setPageHeading(e.target.value)}></Form.Textarea>
          </Form.Field> */}
          <Form.Field>
            <label className="checkbox">
              <input id="show-page-title" onChange={e => setShowPageTitle(e.target.checked)} type="checkbox" name="show-page-title" checked={showPageTitle} className="mr-2" />
                Show page title
            </label>
          </Form.Field>
          <Form.Field>
            <label className="checkbox">
              <input id="is-horizontal" onChange={e => setIsHorizontal(e.target.checked)} type="checkbox" name="is-horizontal" checked={isHorizontal} className="mr-2" />
                Use horizontal layout
            </label>
          </Form.Field>
        </Modal.Card.Body>
        <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
          <button className="button is-success" disabled={!canSave} onClick={save}>Save</button>
          <button className="button is-outlined" onClick={cancel}>Cancel</button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default AddPageModal;