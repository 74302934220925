import { useMemo, useState, useEffect } from 'react';
import { Heading, Modal, Button } from 'react-bulma-components';
import { getPromoCodes } from '../../../lib/api';

import TernTable from '../../../components/TernTable/TernTable';
import PromoCode from '../PromoCodes/PromoCode'
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

function PromoCodes(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [codesData, setCodesData] = useState([]);
  const [updateTable, setUpdateTable] = useState(true);
  const [hideEditFields, setHideEditFields] = useState(true);
  const [editingPromoId, setEditingPromoId] = useState();

  // const [selectedCodeStatus, setSelectedCodeStatus] = useState([1])
  // const [ currentCode, setcurrentCode ] = useState({ promoId: '', name: '', enabled: false, percentDiscount: 0,
  // amountDiscount: 0, startDate: '',endDate: '', groupId: 1});
  // const codeStatus = [
  //   { id: 1, description: 'Active'},
  //   { id: 2, description: 'Inactive'},
  // ]

  useEffect(() => {
    if (updateTable) {
      getPromoCodes().then((data) => {
        setCodesData(data);
      })
        .catch((err) => {
          console.error('err', err)
        })
        .finally(() => { setIsLoading(false); setUpdateTable(false) });
    }
  }, [updateTable])

  useEffect(() => {
    if (codesData) {
      let theData = codesData.sort((a, b) => a.promoId.localeCompare(b.promoId));
      // let theData = codesData.filter(code => (selectedCodeStatus.includes(1) && code.active === true) || 
      //                                     (selectedCodeStatus.includes(2) && code.active === false));
      setTableData([]);
      setTableData(theData.map((promo) => {
        return {
          promoId: promo.promoId,
          name: promo.name,
          enabled: promo.enabled ? "Yes" : "No",
          active: activeTag(promo.active),
          discount: promo.percentDiscount === 0 ? '$'.concat(promo.amountDiscount) : promo.percentDiscount.toString().concat('%'),

          start: promo.startDate,
          end: promo.endDate,
          groupId: promo.groupId,
        }
      }));
    }
  }, [/*selectedCodeStatus,*/ codesData])

  function activeTag(active) {
    const classes = `tag mr-2 ${active ? 'is-success' : 'is-warning'}`;
    const text = `${active ? 'Active' : 'Expired'}`;

    return <span className={classes}>{text}</span>
  }

  const data = useMemo(
    () => tableData,
    [tableData]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'promoId',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Discount',
        accessor: 'discount',
      },
      {
        Header: 'Active',
        accessor: 'active',
      },
      {
        Header: 'Enabled',
        accessor: 'enabled',
      },
      // {
      //   Header: 'Group',
      //   accessor: 'groupId',
      // },
    ],
    []
  )

  const onTableRowClick = (row) => {
    setEditingPromoId(row.promoId);
    setHideEditFields(false);
  }

  function newCode() {
    setEditingPromoId(null);
    setHideEditFields(false);
  }

  function onPromoCodeDone() {
    setEditingPromoId(null);
    setHideEditFields(true);
    setUpdateTable(true);
  }

  // function updateSelectedCodeStatus(codeStatusId, include) {
  //   if (include) {
  //     setSelectedCodeStatus([...selectedCodeStatus, codeStatusId]);
  //   }
  //   else {
  //     setSelectedCodeStatus(selectedCodeStatus.filter(id => id !== codeStatusId));
  //   }
  // }

  return (
    <>
      {isLoading ? <ProgressComponent /> :
        <div className="admin-promo-page">
          <Heading>Promo Codes</Heading>
          {tableData.length > 0 &&
            <div className="has-text-right">
              <Button className="is-success" onClick={newCode}>Create Promo Code</Button>
            </div>
          }
          {/* <Level>
        <Dropdown className="level-right"
          right={true}
          closeOnSelect={false}
          color=""
          icon={<FontAwesomeIcon icon={faAngleDown} className="ml-3" />}
          label="Coupon Code Status"
          value=""
        >
          {codeStatus.map((status) =>
            <Dropdown.Item key={`dropdownitem_codestatus_input_${status.id}`} renderAs="div">
              <div>
                <label className="checkbox">
                  <input id={`codestatus_input_${status.id}`} 
                    onChange={e => updateSelectedCodeStatus(status.id, e.target.checked)}
                    type="checkbox" 
                    name={`codestatus_input_${status.id}`} 
                    checked={selectedCodeStatus.includes(status.id)} className="mr-2" />
                      {status.description}
                </label>
              </div>
            </Dropdown.Item>
          )}

        </Dropdown>
        </Level> */}
          {tableData.length > 0 ?
            <div className="my-3">
              <TernTable hover={true} showTotal={false} columns={columns} data={data} onRowClick={onTableRowClick} />
            </div>
            :
            <div className="has-text-centered">
              <div>No Promo Codes yet</div>
              <Button className="mt-3 is-success" onClick={newCode}>Create Promo Code</Button>
            </div>
          }

        </div>}

      <Modal showClose={false} show={!hideEditFields} onClose={() => props.onClose()}>
        <Modal.Card>
          <Modal.Card.Header showClose={false}>
            <p className="modal-card-title">Add Promo Code</p>
            {/* <button class="delete" aria-label="close" onClick={() => props.onClose()}></button> */}
          </Modal.Card.Header>
          <Modal.Card.Body>
            <PromoCode promoId={editingPromoId} onDone={onPromoCodeDone} onCancel={() => setHideEditFields(true)} />
          </Modal.Card.Body>
          {/* <Modal.Card.Footer class="modal-card-foot">
          <button class="button is-success" onClick={() => send()}>Send</button>
          <button class="button" onClick={() => props.onClose()}>Cancel</button>
        </Modal.Card.Footer> */}
        </Modal.Card>
      </Modal>
    </>
  )
}

export default PromoCodes;
