import React, { createContext, useContext, useReducer } from 'react';
import { getMatched } from '../lib/api';

const initialState = { 
  user: null,
  apiRequestPending: false,
  apiRequestError: null,
  matched: null,
};

const store = createContext();

const { Provider, dispatch } = store;

function applySessionToStore(currentState, session) {
  let existingSessionIdx = currentState.matched.mentorship.sessions.findIndex(f => f.id === session.id);
  if (existingSessionIdx > -1) {
    currentState.matched.mentorship.sessions[existingSessionIdx] = { ...session };
  }
}

const reducer = (state, action) => {
  let currentState = { ...state };
  switch (action.type) {
    case 'MENTORSHIP_COMPLETED':
      currentState.mentorshipHasBeenCompleted = true;
      break;
      
    case 'CREATE_USER':
      currentState.pending = true;
      break;

    case 'CREATE_USER_ERROR':
      console.error('CREATE_USER_ERROR', action.apiRequestError);
      currentState.apiRequestError = action.apiRequestError;
      currentState.pending = false;
      break;
    
    case 'SET_SESSION':
      applySessionToStore(currentState, action.payload);
      break;

    case 'SET_SESSION_DATE':
      currentState.matched.mentorship.sessions.find(f => f.id === action.payload.sessionId)
        .session_date = action.payload.sessionDate;
      break;
    
    case 'SET_USER':
      currentState.user = { ...action.payload };
      break;
    
    case 'SET_MATCHED_WITH':
      currentState.matched = { ...action.payload };
      break;
    
    case 'SET_STATIC_VALUES':
      currentState.staticValues = { ...action.payload };
      break;
      
    case 'LOGOUT':
      currentState = { ...initialState };
      break;

    default:
      throw new Error();
  }

  console.log('STATE', currentState);

  return currentState;

};

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  initialState.dispatch = dispatch;

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export function useStore() {
  return useContext(store);
}

const Store = {
  StoreProvider: StoreProvider,
  Store: store
}

export default Store;