import { useMemo, useState, useEffect } from 'react';
import  { Heading, Dropdown, } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import { parseISO } from 'date-fns';

import { getReferrers } from '../../../lib/api';
import {  defaultDateString/*, defaultDateTimeString*/ } from '../../../lib/stringHelpers';
import { userTypeString } from '../../../lib/ternTypeHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import TernTable from '../../../components/TernTable/TernTable';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

function Referrals(props) {
  const history = useHistory();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ tableData, setTableData ] = useState([]);
  const [ referrersData, setReferrersData ] = useState([]);
  const [ selectedReferrersTypes, setSelectedReferrersTypes ] = useState(getDefaultSelectedReferrerTypes());
  
  const userTypes = [
    { id: 1, description: 'Mentee'},
    { id: 2, description: 'Mentor'},
    { id: 3, description: 'Donor'},
    { id: 4, description: 'Admin'},
  ]
  
  function getDefaultSelectedReferrerTypes() {
    let referrerTypesFilter = [1,2];
    return referrerTypesFilter;
  }

  useEffect(() => {
    getReferrers()
      .then((data) => {
        setReferrersData(data);
      })
      .catch((err) => {
        console.error('err', err)
      })
      .finally(() => setIsLoading(false));
  }, [])

  const data = useMemo(
    () => tableData,
    [tableData]
  )

  useEffect(() => {
    let theData = referrersData.filter(referral => (referral.referrer === null || selectedReferrersTypes.includes(referral.referrer?.activeProfile?.contactType?.id)));
    
    setTableData(theData.map((referral) => { return {
      referree_id: referral.referree.contactId,
      referree_first_name: referral.referree.firstName,
      referree_last_name: referral.referree.lastName,
      referree_profile_type: userTypeString(referral.referree.activeProfile?.contactType?.id),
      referrer_id: referral.referrer === null ? '0' : referral.referrer.contactId,
      referrer_first_name: referral.referrer === null ? '': referral.referrer.firstName,
      referrer_last_name: referral.referrer === null ? 'Unknown Referrer': referral.referrer.lastName,
      referrer_profile_type: referral.referrer === null ? '': userTypeString(referral.referrer.activeProfile?.contactType?.id),
      referrer_email: referral.referrer === null? '': referral.referrer.email,
      creation_date: parseISO(referral.createdOn),
      source: referral.source
    }}));
  }, [referrersData, selectedReferrersTypes])

  const onTableRowClick = (row) => {
    if (row.referrer_id !== '0')
      history.push(`/users/${row.referrer_id}`);
  }

  function updateSelectedReferrersTypes(userTypeId, include) {
    if (include) {
      setSelectedReferrersTypes([...selectedReferrersTypes, userTypeId]);
    }
    else {
      setSelectedReferrersTypes(selectedReferrersTypes.filter(id => id !== userTypeId));
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Referree Last Name',
        accessor: 'referree_last_name', 
      },
      {
        Header: 'Referree First Name',
        accessor: 'referree_first_name',
      },
      {
        Header: 'Referree Type',
        accessor: 'referree_profile_type',
      },
      {
        Header: 'Referrer Last Name',
        accessor: 'referrer_last_name', 
      },
      {
        Header: 'Referrer First Name',
        accessor: 'referrer_first_name',
      },
      {
        Header: 'Referrer Type',
        accessor: 'referrer_profile_type',
      },
      {
        Header: 'Referral',
        accessor: 'source',
      },
      {
        Header: 'Referral Date',
        accessor: 'creation_date',
        sortType: 'datetime',
        Cell: props => defaultDateString(props.value)
      },
    ],
    []
  )

return (
  <div className="admin-user-page">
    <Heading>Referrals</Heading>
    {isLoading ? <ProgressComponent /> :
      <>
      <div className="is-flex is-align-items-end is-justify-content-space-between mb-3">
       <Dropdown
          right={true}
          closeOnSelect={false}
          color=""
          icon={<FontAwesomeIcon icon={faAngleDown} className="ml-3" />}
          label="Referrer types"
          value=""
        >
          {userTypes.map((userType) =>
            <Dropdown.Item key={`dropdownitem_referrertype_input_${userType.id}`} renderAs="div">
              <div>
                <label className="checkbox">
                  <input id={`usertype_input_${userType.id}`} 
                    onChange={e => updateSelectedReferrersTypes(userType.id, e.target.checked)}
                    type="checkbox" 
                    name={`usertype_input_${userType.id}`} 
                    checked={selectedReferrersTypes.includes(userType.id)} className="mr-2" />
                      {userType.description}
                </label>
              </div>
            </Dropdown.Item>
          )}

        </Dropdown>
        </div>
        <TernTable hover={true} showTotal={false} columns={columns} data={data} onRowClick={onTableRowClick} />
      </>
    }
  </div>
  )
}

export default Referrals;
