import { useState, useEffect } from 'react';
import { Modal, Form, } from 'react-bulma-components';

function MetricsModal(props) {
  
  const [currentMetrics, setCurrentMetrics] = useState(props.metrics);
  const [canSave, setCanSave] = useState(false);
  const [minValueError, setMinValueError] = useState(false);
  
  useEffect(() => {
    let validForm = true;

    setMinValueError(false);
    if (currentMetrics.metricsTypeId === 3 && parseInt(currentMetrics.min) >= parseInt(currentMetrics.max)) {
      validForm = false;
      setMinValueError(true);
    }

    if (currentMetrics.metricsTypeId === 3 && (isNaN(currentMetrics.min) || isNaN(currentMetrics.max))) {
      validForm = false;
    }
    else if ((currentMetrics.metricsTypeId === 4 || currentMetrics.metricsTypeId === 5) && !currentMetrics.values) {
      validForm = false;
    }

    if (!currentMetrics.description) {
      validForm = false;
    }

    setCanSave(validForm);
    
  }, [currentMetrics, canSave])

  async function saveMetrics() {
    //to do: update currentMetrics from tempMetrics  
    if (props.onSave) {
        props.onSave(currentMetrics);
      }
      else {
        console.error('No onSave function provided');
      }
  }
  async function cancel() {
    if (props.onCancel) {
      props.onCancel();
    }
    else {
      console.error('No onCancel function provided');
    }
}

  return (
    <Modal show={props.open} onClose={cancel}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">{props.title}</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
          {/* <Form.Field>
              <Form.Label>Question Title</Form.Label>
              <Form.Input autoFocus placeholder="What is your favorite color?" value={currentMetrics.name} onChange={e => setCurrentMetrics({...currentMetrics,name: e.target.value})}></Form.Input>
        </Form.Field> */}
          <Form.Field>
              <Form.Label>Question</Form.Label>
              <Form.Textarea placeholder="Your favorite color can say a lot about your personality and mood!  Please let us know your favorite color." value={currentMetrics.description} onChange={e => setCurrentMetrics({...currentMetrics,description: e.target.value})}></Form.Textarea>
        </Form.Field>
        <Form.Field>
        <Form.Label>Answer Type</Form.Label>
              <Form.Select value={currentMetrics.metricsTypeId} onChange={e => setCurrentMetrics({...currentMetrics,metricsTypeId: parseInt(e.target.value)})} >
                { props.metricTypes.map(metricType => <option key={metricType.type} value={metricType.id}>{metricType.description}</option>) }
              </Form.Select>
        </Form.Field>
        {currentMetrics.metricsTypeId === 3 && <>
          <div className='is-flex mt-3'>
          <Form.Field className="mr-3">
              <Form.Label>Min</Form.Label>
              <Form.Input style={{width: 130}} type="number" min="1" step="1" placeholder="Min value" value={currentMetrics.min} onChange={e => setCurrentMetrics({...currentMetrics,min: parseInt(e.target.value)})}></Form.Input>
              {minValueError && <Form.Help color="danger">Min cannot be greater than max</Form.Help>}
          </Form.Field>
          <Form.Field>
              <Form.Label>Max</Form.Label>
              <Form.Input style={{width: 130}} placeholder="Max value" type="number" min="1" step="1" value={currentMetrics.max} onChange={e => setCurrentMetrics({...currentMetrics,max: parseInt(e.target.value)})}></Form.Input>
        </Form.Field>
          </div>
          </>}
          {(currentMetrics.metricsTypeId === 4 || currentMetrics.metricsTypeId === 5) && <>
          <div>
          <Form.Field>
              <Form.Label>Enter one value per line</Form.Label>
              <textarea className="textarea" placeholder="Enter possible answers for the metrics (1 per line)" value={currentMetrics.values && currentMetrics.values.join('\n')} onChange={e => setCurrentMetrics({...currentMetrics,values: e.target.value.split('\n')})}></textarea>
          </Form.Field>
          </div>
          </>}

          {props.metricTypes.find(f => f.id === currentMetrics.metricsTypeId).type.toLowerCase().startsWith('likert') && <>
          <div>
          <Form.Field>
              <Form.Label>Likert Values:</Form.Label>
              { props.metricTypes.find(f => f.id === currentMetrics.metricsTypeId).params && props.metricTypes.find(f => f.id === currentMetrics.metricsTypeId).params.map((val, idx) => { return <div key={`likert_${idx}`}>{val}</div>})}
          </Form.Field>
          </div>
          </>}

          </Modal.Card.Body>
          <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
            <button className="button is-success" disabled={!canSave} onClick={saveMetrics}>Save</button>
            <button className="button is-outlined" onClick={cancel}>Cancel</button>
          </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default MetricsModal;