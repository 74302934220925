const dev = {
  assetsUrl: "https://tern-assets.s3.amazonaws.com/web/",
  profileImagesUrl: "https://tern-assets.s3.amazonaws.com/profile/",
  s3Root: "https://tern-assets.s3.amazonaws.com/",
  appUrl: "http://localhost:3000/"
};

const prod = {
  assetsUrl: "https://s3.amazonaws.com/assets.ternmentoring.com/web/",
  profileImagesUrl: "https://s3.amazonaws.com/assets.ternmentoring.com/profile/",
  s3Root: "https://s3.amazonaws.com/assets.ternmentoring.com/",
  appUrl: "https://app.ternmentoring.com/"
};

const isProd = process.env.REACT_APP_ENV === "production";
const productConfig = prod;//isProd ? prod : dev;

const config = {
  isProd,
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...productConfig,
}

export default config;
