import { Modal } from 'react-bulma-components';
import PictureUploader from "../PictureUploader/PictureUploader";

function PictureUploaderModal(props) {
  return (
    <Modal showClose={false} show={props.show} onClose={props.onClose}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">Set Company Banner</p>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <PictureUploader groupId = {props.groupId} {...props} />
          <div className="is-size-6 has-text-centered has-text-grey">
            <div>Use a .jpg or .png file.</div>
            <div>Image dimenstions should be at least 1096x300 for best results. </div>
          </div>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export default PictureUploaderModal;
