import './SurveyStars.scss';
import { v4 as uuidv4 } from 'uuid';

function SurveyStars(props) {
  const controlUUID = uuidv4();
  const controlName = `${controlUUID}_stars`;
  const starIdName = `${controlUUID}_star`;

  function starSelected(event) {
    const starValue = event.target.value;

    if (props.onChange) {
      props.onChange(starValue);
    }
  }

  return (
    <div className={`surveystars ${props.disabled ? 'disabled' : ''}`} onChange={starSelected}>
        <input type="radio" id={`${starIdName}_5`} disabled={props.disabled} name={controlName} value="5" defaultChecked={parseInt(props.value) === 5} />
        <label htmlFor={`${starIdName}_5`} title="text">5 stars</label>
        <input type="radio" id={`${starIdName}_4`} disabled={props.disabled} name={controlName} value="4" defaultChecked={parseInt(props.value) === 4} />
        <label htmlFor={`${starIdName}_4`} title="text">4 stars</label>
        <input type="radio" id={`${starIdName}_3`} disabled={props.disabled} name={controlName} value="3" defaultChecked={parseInt(props.value) === 3} />
        <label htmlFor={`${starIdName}_3`} title="text">3 stars</label>
        <input type="radio" id={`${starIdName}_2`} disabled={props.disabled} name={controlName} value="2" defaultChecked={parseInt(props.value) === 2} />
        <label htmlFor={`${starIdName}_2`} title="text">2 stars</label>
        <input type="radio" id={`${starIdName}_1`} disabled={props.disabled} name={controlName} value="1" defaultChecked={parseInt(props.value) === 1} />
        <label htmlFor={`${starIdName}_1`} title="text">1 star</label>
    </div>
  );
}

export default SurveyStars;