export function userTypeString(profileType) {
  switch (profileType) {
    case 1:
      return 'Mentee';

    case 2:
      return 'Mentor';

    case 3:
      return 'Donor';

    case 4:
      return 'Admin';
      
    default:
      return 'Unknown';
  }
}