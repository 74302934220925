import { useMemo, useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import { Heading, Form, Icon, Button } from 'react-bulma-components';
import { getMentorships, rollMatch } from '../../../lib/api';
import { defaultDateString } from '../../../lib/stringHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import TernTable from '../../../components/TernTable/TernTable';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';
import MentorshipStatusTag from '../../../components/MentorshipStatusTag/MentorshipStatusTag';
import ManualMatchModal from '../../../components/ManualMatch/ManualMatchModal';
import { parseISO } from 'date-fns';

function Mentorships({ groupId, showHeading, showButtons }) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ filter, setFilter ] = useState('');
  const [ tableData, setTableData ] = useState([]);
  const [ filteredTableData, setFilteredTableData ] = useState([]);
  const [ rolling, setRolling ] = useState(false);
  const [ showManualMatch, setShowManualMatch ] = useState(false);

  // TODO: session count is hardcoded!!
  const totalSessions = 6;

  const retrieveMentorships = useCallback(() => {
    getMentorships(groupId).then((data) => {
      if (data) {
        const mentorships = data.map((match) => { 
            const sessions_scheduled = match.sessions.filter(f => f.session_date !== null).length;
            const sessions_completed = match.sessions.filter(f => f.completed).length;
            const mentor_sessions_completed = match.sessions.filter(f => f.completed && f.mentor_survey !== null).length;
            const mentee_sessions_completed = match.sessions.filter(f => f.completed && f.mentee_survey !== null).length;
            const completionPct = `${((sessions_completed / totalSessions) * 100).toFixed(0)}%`;

            return {
              id: match.id,
              menteeId: match.mentee_contact_id,
              menteeName: `${match.mentee_last_name}, ${match.mentee_first_name}`,
              mentorId: match.mentor_contact_id,
              mentorName: `${match.mentor_last_name}, ${match.mentor_first_name}`,
              statusId: match.aborted_date ? 99 : match.status_id,
              status: match.status,
              aborted_on: match.aborted_date,
              started_on: match.paid_date ? parseISO(match.paid_date) : parseISO(match.matched_date),
              sessions_scheduled: sessions_scheduled,
              sessions_completed: sessions_completed,
              mentee_sessions_completed: mentee_sessions_completed,
              mentor_sessions_completed: mentor_sessions_completed,
              sessions_completion: completionPct,
              mentorship_duration: match.mentorship_duration + ' weeks',
              groupId: match.group_id, 
            }
          })
          setTableData(mentorships);
          setFilteredTableData(mentorships);
      }

    })
    .catch((err) => {
      console.error('err', err)
    })
    .finally(() => setIsLoading(false));
  }, [groupId])

  useEffect(() => {
      retrieveMentorships();
  }, [retrieveMentorships])

  useEffect(() => {
    if (filter) {
      const filterLC = filter.toLowerCase();
      const filteredData = tableData.filter(f => f.menteeName.toLowerCase().includes(filterLC) || f.mentorName.toLowerCase().includes(filterLC));
      setFilteredTableData(filteredData);
    }
    else {
      setFilteredTableData(tableData);
    }
  }, [filter, tableData]);

  const columns = useMemo(
    () => [
      {
        Header: 'Mentee',
        accessor: 'menteeName',
        Cell: prop => <Link to={`/mentorships/${prop.row.original.id}`}>{prop.value}</Link>,
        // Cell: prop => <Link to={`/users/${prop.row.original.menteeId}`}>{prop.value}</Link>,
      },
      {
        Header: 'Mentor',
        accessor: 'mentorName',
        Cell: prop => <Link to={`/mentorships/${prop.row.original.id}`}>{prop.value}</Link>,
        // Cell: prop => <Link to={`/users/${prop.row.original.mentorId}`}>{prop.value}</Link>,
      },
      {
        Header: 'Status',
        accessor: 'statusId',
        Cell: props => <MentorshipStatusTag statusDescription={props.row.original.status} wasAborted={props.row.original.aborted_on}/>
      },
      {
        Header: 'Started On',
        accessor: 'started_on',
        sortType: 'datetime',
        Cell: props => defaultDateString(props.value)
      },
      {
        Header: <>Sessions<br/>Scheduled</>,
        accessor: 'sessions_scheduled',
      },
      // {
      //   Header: <>Total<br/>Sessions<br/>Completed</>,
      //   accessor: 'sessions_completed',
      // },
      {
        Header: <>Mentor<br/>Surveys<br/>Completed</>,
        accessor: 'mentor_sessions_completed',
      },
      {
        Header: <>Mentee<br/>Surveys<br/>Completed</>,
        accessor: 'mentee_sessions_completed',
      },
      {
        Header: <>Completion</>,
        accessor: 'sessions_completion',
        Cell: props => `${props.row.original.sessions_completed} of ${totalSessions}`
      },
      {
        Header: 'Duration',
        accessor: 'mentorship_duration',
      },
    ],
    []
  )

  async function confirmRollMatch() {
    if (window.confirm('Are you sure you want to match the mentors with mentees now?')) {
  
      setRolling(true);

      try {
        await rollMatch(groupId);
      }
      finally {
        setRolling(false);
        retrieveMentorships();
      }
    }
  }

  function manualMatchComplete(success) {
    setShowManualMatch(false);
    retrieveMentorships();
  }

  const MatchButtonGroup = () => (
    <Button.Group>
      <Button className="is-info" loading={rolling} disabled={rolling} onClick={() => confirmRollMatch()}>Match Now</Button>
      <Button className="is-info" onClick={() => setShowManualMatch(true)}>Manual Match</Button>
    </Button.Group>
  )

  
return (
  <>
    { isLoading ? <ProgressComponent /> :
      <div className="admin-matching-page">
        { (showHeading === true || showHeading === undefined) && <div className="mb-5"><Heading>Mentorships</Heading><Heading size={6} subtitle>Showing {filteredTableData.length} of {tableData.length} total</Heading></div> }

        { (filteredTableData && filteredTableData.length > 0) ? <>
          <div className="is-flex is-justify-content-space-between my-4 is-align-items-center">
            <div>
                {/* <Form.Label>Filter:</Form.Label> */}
                <Form.Field className="has-addons">
                  <Form.Control>
                    <Form.Input placeholder="Search..." value={filter} onChange={e => setFilter(e.target.value)} />
                    <Icon align="left"><FontAwesomeIcon icon={faSearch} /></Icon>
                  </Form.Control>
                  <Form.Control>
                    <Button disabled={filter === ''} onClick={e => setFilter('')}>
                      <Icon align="right"><FontAwesomeIcon icon={faTimesCircle} /></Icon>
                    </Button>
                  </Form.Control>
                </Form.Field>
                </div>
              { showButtons && <MatchButtonGroup /> }
          </div>

          <TernTable showTotal={true} columns={columns} data={filteredTableData} initialSort={[{ id: 'started_on', desc: true }]}/> 
          </>
        : 
          <div className="py-3 has-text-centered">
            <div>No mentorships currently</div>
            <div className="is-flex is-justify-content-center mt-5"><MatchButtonGroup /></div>
          </div> 
        }

      { showManualMatch && <ManualMatchModal show={showManualMatch} onClose={manualMatchComplete} groupId={groupId} />}
    </div>}
  </>)

}

export default Mentorships;
