import Joi from 'joi';

const string = Joi.string();
const passPattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*\\W)[a-zA-Z0-9\\S]{8,}$";

export const firstName = string.trim().required().messages({
  "string.empty": "First name is required.",
  // "any.required": "First name is required.",
});

export const lastName = string.trim().required().messages({
  "string.empty": "Last name is required.",
});

export const email = string.email({ tlds: {allow: false} }).trim().lowercase().required().messages({
  "string.email": "Not a valid email address.",
  "string.empty": "Email is required.",
});

export const password = string.pattern(new RegExp(passPattern)).messages({
  "string.pattern.base":
    "Password must be at least 8 characters and contain at least 1 lowercase, 1 uppercase, 1 number and 1 special character.",
});

export const confirmPassword = Joi.valid(Joi.ref("password")).messages({
  "any.only": "Passwords must match.",
});
