import { useMemo, useState, useEffect } from 'react';
import  { Form, Heading, Dropdown, Icon, Button } from 'react-bulma-components';
import { useHistory, useLocation } from 'react-router-dom';
import { parseISO } from 'date-fns';

import { getUsers } from '../../../lib/api';
import { boolString, defaultDateString/*, defaultDateTimeString*/ } from '../../../lib/stringHelpers';
import { userTypeString } from '../../../lib/ternTypeHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import TernTable from '../../../components/TernTable/TernTable';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

function Users(props) {
  const history = useHistory();
  const location = useLocation();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ tableData, setTableData ] = useState([]);
  const [ usersData, setUsersData ] = useState([]);
  const [ selectedUserTypes, setSelectedUserTypes ] = useState(getDefaultSelectedUserTypes());
  const [ userFilter, setUserFilter ] = useState("");
  const [ counts, setCounts ] = useState({ total: 0, mentors: 0, mentees: 0})

  const userTypes = [
    { id: 1, description: 'Mentee'},
    { id: 2, description: 'Mentor'},
    { id: 3, description: 'Donor'},
    { id: 4, description: 'Admin'},
  ]

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.set('usertypes', JSON.stringify(selectedUserTypes).replaceAll(/[[\]]+/g, ''));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }, [history, location.pathname, location.search, selectedUserTypes]);
  
  const onTableRowClick = (row) => {
    history.push(`/users/${row.id}`);
  }
  
  function getDefaultSelectedUserTypes() {
    let userTypesFilter = [1,2];
    const search = new URLSearchParams(location.search);
    if (search.get('usertypes')) {
      try
      {
        userTypesFilter = JSON.parse(`[${search.get('usertypes')}]`);
      }
      catch { }
    }
    return userTypesFilter;
  }

  function getAvailableString(user) {
    let availString = '-';
    if (user.active_profile === 1 || user.active_profile === 2) {
      availString = user.active_profile === 1 ? boolString(user.mentee_available) : boolString(user.mentor_available);
    }

    return availString;
  }
  
  function getStatusBadge(user) {
    let statusColor = user.match_id ? 'is-success' : 'is-info';
    let statusString = user.match_id ? 'Matched' : 'Unmatched';

    // let statusColor = 'is-info';
    // let statusString = '';

    // if (user.product_id >= 1) {
    //   statusColor = 'is-success has-text-weight-bold';
    //   statusString = user.product_id === 1 ? 'Regular' : 'Journey';
    // }
    // else if (user.active_profile === 2) {
    //   statusColor = 'is-warning';
    //   statusString = 'Unpaid';
    // }
    // else if (user.active_profile === 1 && user.match_id) {
    //   statusColor = 'is-success has-text-weight-bold';
    //   statusString = 'Paid';
    // }
    // else if (user.active_profile === 1 && !user.match_id) {
    //   statusColor = 'is-info';
    //   statusString = 'Unmatched';
    // }

    if (statusString) {
      return <span className={`tag mr-2 ${statusColor}`}>{statusString}</span>
    }
  }

  function getStatusString(user) {
    return user.match_id ? 'Matched' : 'Unmatched';

    // let statusColor = 'is-info';
    // let statusString = '';

    // if (user.product_id >= 1) {
    //   statusColor = 'is-success has-text-weight-bold';
    //   statusString = user.product_id === 1 ? 'Regular' : 'Journey';
    // }
    // else if (user.active_profile === 2) {
    //   statusColor = 'is-warning';
    //   statusString = 'Unpaid';
    // }
    // else if (user.active_profile === 1 && user.match_id) {
    //   statusColor = 'is-success has-text-weight-bold';
    //   statusString = 'Paid';
    // }
    // else if (user.active_profile === 1 && !user.match_id) {
    //   statusColor = 'is-info';
    //   statusString = 'Unmatched';
    // }

    // return statusString;
  }

  useEffect(() => {
    getUsers()
      .then((data) => {
        setUsersData(data);
        if (data){
          let newCounts = {
            total: data.length,
            mentors: data.filter(f => f.mentor_id).length,
            mentees: data.filter(f => f.mentee_id).length,
          }

          setCounts(newCounts);
        }
      })
      .catch((err) => {
        console.error('err', err)
      })
      .finally(() => setIsLoading(false));
  }, [])

  const data = useMemo(
    () => tableData,
    [tableData]
  )

  useEffect(() => {
    let theData = usersData.filter(user => selectedUserTypes.includes(user.active_profile));
    if (userFilter) {
      const userFilterLC = userFilter.toLowerCase();
      theData = theData.filter(user => user.last_name.toLowerCase().includes(userFilterLC) || user.first_name.toLowerCase().includes(userFilterLC) || user.email.toLowerCase().includes(userFilterLC));
    }

    setTableData(theData.map((user) => { return {
      id: user.cognito_id,
      last_name: user.last_name,
      first_name: user.first_name,
      profiletype: userTypeString(user.active_profile),
      // enabled: boolString(user.enabled),
      status: getStatusString(user),
      statusBadge: getStatusBadge(user),
      available: getAvailableString(user),
      email: user.email,
      group_name: user.group_name,
      creation_date: parseISO(user.creation_date),
      profile_complete: boolString(user.profile_complete),
      ready: boolString(user.match_ready_at !== null)
    }}));
  }, [usersData, selectedUserTypes, userFilter])

  function updateSelectedUserTypes(userTypeId, include) {
    if (include) {
      setSelectedUserTypes([...selectedUserTypes, userTypeId]);
    }
    else {
      setSelectedUserTypes(selectedUserTypes.filter(id => id !== userTypeId));
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Last Name',
        accessor: 'last_name', 
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Type',
        accessor: 'profiletype',
      },
      // {
      //   Header: 'Email',
      //   accessor: 'email',
      // },
      {
        Header: 'Group/Company',
        accessor: 'group_name',
      },
      {
        Header: 'Profile',
        accessor: 'profile_complete',
        collapse: true,
      },
      {
        Header: 'Ready',
        accessor: 'ready',
        collapse: true,
      },
      {
        Header: 'Available',
        accessor: 'available',
        collapse: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        collapse: true,
        Cell: props => props.row.original.statusBadge
      },
      // {
      //   Header: 'Enabled',
      //   accessor: 'enabled',
      //   collapse: true,
      // },
      {
        Header: 'Member Since',
        accessor: 'creation_date',
        sortType: 'datetime',
        Cell: props => defaultDateString(props.value)
      },
    ],
    []
  )

return (
  <div className="admin-user-page">
    <Heading>Users</Heading>
    {isLoading ? <ProgressComponent /> :
      <>
      <nav className="level has-background-light py-3">
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Mentors</p>
            <p className="title">{counts.mentors}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Mentees</p>
            <p className="title">{counts.mentees}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Total</p>
            <p className="title">{counts.total}</p>
          </div>
        </div>
      </nav>

      <div className="is-flex is-align-items-end is-justify-content-space-between mb-3">
        <Form.Field className="has-addons" style={{maxWidth: '44%'}}>
          <Form.Control>
            <Form.Input placeholder="Search..." value={userFilter} onChange={(e)=> setUserFilter(e.target.value)} />
            <Icon align="left"><FontAwesomeIcon icon={faSearch} /></Icon>
          </Form.Control>
          <Form.Control>
            <Button disabled={userFilter === ''} onClick={e => setUserFilter('')}>
              <Icon align="right"><FontAwesomeIcon icon={faTimesCircle} /></Icon>
            </Button>
          </Form.Control>
        </Form.Field>
        <Dropdown
          right={true}
          closeOnSelect={false}
          color=""
          icon={<FontAwesomeIcon icon={faAngleDown} className="ml-3" />}
          label="User types"
          value=""
        >
          {userTypes.map((userType) =>
            <Dropdown.Item key={`dropdownitem_usertype_input_${userType.id}`} renderAs="div">
              <div>
                <label className="checkbox">
                  <input id={`usertype_input_${userType.id}`} 
                    onChange={e => updateSelectedUserTypes(userType.id, e.target.checked)}
                    type="checkbox" 
                    name={`usertype_input_${userType.id}`} 
                    checked={selectedUserTypes.includes(userType.id)} className="mr-2" />
                      {userType.description}
                </label>
              </div>
            </Dropdown.Item>
          )}

        </Dropdown>
        </div>
        <TernTable hover={true} showTotal={false} columns={columns} data={data} initialSort={[{id: 'last_name', desc: false}]} onRowClick={onTableRowClick} />
      </>
    }
  </div>
  )
}

export default Users;
