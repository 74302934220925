import { Form } from 'react-bulma-components';
import { parse } from 'date-fns';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function datePartsToDate(dateParts) {
  if (dateParts.month > 0 && dateParts.day > 0 && dateParts.year > 0) {
    return new Date(dateParts.year, parseInt(dateParts.month) - 1, dateParts.day);
  }
  
  throw Error('Invalid date parts');
}

export function dateToDateParts(theDate) {
  let dateParts = null;

  if (theDate) {
    try {
      let dateObj = theDate instanceof Date ? theDate : parse(theDate, 'yyyy-MM-dd', new Date());
      dateParts = { month: dateObj.getMonth() + 1, day: dateObj.getDate(), year: dateObj.getFullYear() };
    }
    catch (err) {
    }
  }

  return dateParts;
}

export function alphaSort99(a, b) {
  // Sort an array of { id, description } objects based on case-insensitive sort but always
  // put objects with id 99 or description "Other" or "Write in" at the end
  function bottomCheck(obj) {
    return (obj.id === 99 || 
      obj.description.toLowerCase().startsWith('other') || 
      obj.description.toLowerCase().startsWith('write in'));
  }

  if (a instanceof Object) {
    if (bottomCheck(a)) {
      return 1;
    }
    else if (bottomCheck(b)) {
      return -1;
    }
    else 
      return a.description.localeCompare(b.description);
  }
  else {
    return a.localeCompare(b);
  }
}

export function getNumberArray(start, count, reverse) {
  return Array(count).fill().map((_, idx) => reverse ? start - idx : start + idx);
}

export function controlColor(errors, property, state) {
  let color = '';

  if (errors || state.pending) {
    return (errors[property] !== undefined && errors[property] !== '') ? 'danger' : '';
    // return errors[property] ? 'danger' : 'success';
  }

  return color;
}

export function registerFormComponents(register, errors) {

  return (name, type = 'input', options) => {
    const { ref: inputRef, ...inputProps } = register(name);

    // Options:
    // placeholder (string) - placeholder text for the control
    // className (string) - className to pass to React
    // includeHelp (bool) - include the Form.Help component with error information
    // disabled (bool) - is disabled
    // inner (React<>) - components to include within the control

    const includeHelp = options?.includeHelp === undefined || options?.includeHelp !== false;

    return (
      <>
        <Form.Control>
          { type === 'select' ?
            <Form.Select placeholder={options?.placeholder}
              className={options?.className}
              disabled={options?.disabled}
              color={controlColor(errors, name)}
              {...inputProps} domRef={inputRef}>
                {options?.inner}
            </Form.Select>
          : 
            <Form.Input placeholder={options?.placeholder}
              disabled={options?.disabled}
              color={controlColor(errors, name)}
              {...inputProps} domRef={inputRef}
            />
          }
        </Form.Control>

        {includeHelp ? <Form.Help color={controlColor(errors, name)}>{errors[name]?.message}</Form.Help> : null}

      </>
    );
  }
}

export function isValidEmail(email) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}