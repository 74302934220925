import { useEffect, useState } from 'react';
import { useStore } from '../../store/Store';
import { Auth } from 'aws-amplify';
import { Redirect } from "react-router-dom";

function Signout() {
  const { dispatch } = useStore();
  const [ doRedirect, setDoRedirect ] = useState(false);

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        setDoRedirect(true);
      })
      .catch(() => console.error('Error logging out'))
  }, []);

  useEffect(() => {
    if (doRedirect) {
      dispatch( { type: 'LOGOUT'} );
    }
  });

  return ( <> { doRedirect && <Redirect to="login" /> } </> )
}

export default Signout;