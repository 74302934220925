
import { useMemo, useState, useEffect } from 'react';
import { Heading, Button, Form, Icon } from 'react-bulma-components';
import MetricsModal from '../../../components/Metrics/MetricsModal';
import { getMetrics, updateMetrics, createMetrics, deleteMetrics, getMetricTypes } from '../../../lib/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import TernTable from '../../../components/TernTable/TernTable';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

function Metrics(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState();
  const [currentMetrics, setCurrentMetrics] = useState({ id: 0 });
  const [updateTable, setUpdateTable] = useState(true);
  const [selectedUserTypes, setSelectedUserTypes] = useState([3]);
  const [showMetricsModal, setShowMetricsModal] = useState(false);
  const [updateMetricsData, setUpdateMetricsData] = useState(false);

  const [metricTypes, setMetricTypes] = useState([]);

  const metricsUserTypes = [
    { id: 1, description: 'Mentees only' },
    { id: 2, description: 'Mentors only' },
    { id: 3, description: 'Mentees and mentors' },
  ]

  useEffect(() => {
    if (updateTable) {
      getMetrics().then((data) => {
        setMetricsData(data);

        return getMetricTypes();
      })
      .then((metricTypes) => {
        setMetricTypes(metricTypes);
      })
        .catch((err) => {
          console.error('err', err)
        })
        .finally(() => { setIsLoading(false); setUpdateTable(false) });
    }
  }, [updateTable])

  function metricTypeToFriendlyName(metricType) {

    if (metricType === 'integer') return 'Number';
    if (metricType === 'boolean') return 'Yes/No';
    if (metricType === 'free text') return 'Free Text';
    if (metricType === 'predefined values') return 'Selection';
    if (metricType === 'likert') return 'Likert Scale';
    if (metricType === 'stars') return '5 stars';

    return metricType;
  }

  useEffect(() => {
    if (showMetricsModal === false) {
      if (metricsData && metricsData.length > 0) {
        let theData = metricsData.filter(metrics =>
          metrics.userType === selectedUserTypes[0]);

        setTableData(theData.map((metrics) => {
          return {
            id: metrics.id,
            name: metrics.name,
            description: metrics.description,
            matchOnCount: metrics.matchOnCount,
            values: metrics.values ? metrics.values === '{}' ? [] : JSON.parse(metrics.values) : [],
            userType: metrics.userType,
            metricsType: metricTypeToFriendlyName(metrics.metricsType),
            metricsTypeId: metrics.metricsTypeId,
            min: metrics.min,
            max: metrics.max,
          }
        }));
      }
      else setTableData([]);
    }
  }, [metricsData, selectedUserTypes, showMetricsModal])

  useEffect(() => {
    if (updateMetricsData) {
      setIsLoading(true);
      getMetrics()
        .then((metrics) => {
          setMetricsData(metrics);
        })
        .finally(() => {
          setCurrentMetrics({ id: 0 });
          setIsLoading(false);
        });
    }
    setUpdateMetricsData(false);
  }, [updateMetricsData])

  useEffect(() => {
    if (filter) {
      setFilteredData(tableData.filter(f => f.description && f.description.toLowerCase().includes(filter.toLowerCase())));
    }
    else {
      setFilteredData([...tableData]);
    }
  }, [filter, tableData])

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Question Title',
      //   accessor: 'name',
      // },
      {
        Header: 'Question',
        accessor: 'description',
      },
      {
        Header: 'Type',
        accessor: 'metricsType',
      },
      {
        Header: '',
        accessor: 'id',
        collapse: true,
        Cell: prop => <button name="Delete" onClick={(e) => { removeMetrics(prop.value); e.stopPropagation(); }}><FontAwesomeIcon icon={faTrash} /></button>
      },
    ],
    []
  )


  function onTableRowClick(row) {
    setCurrentMetrics(row);
    setShowMetricsModal(true);

  }
  function newMetrics() {
    setCurrentMetrics({
      id: 0, userType: selectedUserTypes[0], values: '', min: 1, max: 5, matchOnCount: 1,
      metricsTypeId: 1
    });
    setShowMetricsModal(true);

  }
  function removeMetrics(metricsId) {
    if (window.confirm('Do you really want to delete this question? It will remove it from all companies!')) {
      if (metricsId > 0) {
        //delete metrics in db
        deleteMetrics(metricsId)
          .then(() => {
            setUpdateMetricsData(true);
          })
      }
    }
  }

  function getCurrentMetricsTitle() {
    let title = "";
    if (currentMetrics.id === 0)
      title = 'New ';
    if (currentMetrics.userType === 1)
      return title + 'Question for Mentees';
    else if (currentMetrics.userType === 2)
      return title + 'Question for Mentors';
    else if (currentMetrics.userType === 3)
      return title + 'Question for Mentors & Mentees';
  }

  function saveMetrics(updatedmetrics) {
    setShowMetricsModal(false);

    if (updatedmetrics.values instanceof Array) {
      // Filter out empty strings
      updatedmetrics.values = updatedmetrics.values.filter(f => f !== '');
    }

    //save metrics in db
    if (updatedmetrics.id > 0)
      updateMetrics(updatedmetrics.id, updatedmetrics)
        .then(() => { setUpdateMetricsData(true); })
    else
      createMetrics(updatedmetrics)
        .then(() => { setUpdateMetricsData(true); })

  }
  function cancelMetrics(updatedMetrics) {
    setShowMetricsModal(false);
  }

  return (
    <>
      {isLoading ? <ProgressComponent /> :
        <>
          <Heading>Questions Catalog</Heading>
          {metricsData && metricsData.length > 0 ? <>
            <div className="is-flex is-justify-content-space-between mb-3">
              <div className="is-flex">
                <Form.Field className="mr-3">
                  <Form.Label>Filter:</Form.Label>
                  <Form.Field className="has-addons">
                  <Form.Control>
                    <Form.Input placeholder="Search..." value={filter} onChange={e => setFilter(e.target.value)} />
                    <Icon align="left"><FontAwesomeIcon icon={faSearch} /></Icon>
                  </Form.Control>
                  <Form.Control>
                    <Button disabled={filter === ''} onClick={e => setFilter('')}>
                      <Icon align="right"><FontAwesomeIcon icon={faTimesCircle} /></Icon>
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Form.Field>
              <Form.Field>
                <Form.Label className="mr-3">Audience:</Form.Label>
                <Form.Select value={selectedUserTypes[0]} onChange={e => setSelectedUserTypes([parseInt(e.target.value)])}>
                  {metricsUserTypes.map((userType) => <option key={`dropdown_metrics_usertype_${userType.id}`} value={userType.id}>{`${userType.description}`}</option>)}
                </Form.Select>
              </Form.Field>
              </div>
              {metricsData && metricsData.length > 0 && <Button className="is-success" onClick={newMetrics}>Create A Question</Button>}
            </div>
            <TernTable hover={true} showTotal={false} columns={columns} data={filteredData} initialSort={[{id: 'description', desc: false}]} onRowClick={onTableRowClick} />
          </>
            : <>
              <div className="has-text-centered">
                <div>No questions yet.</div>
                <Button className="mt-3 is-success" onClick={newMetrics}>Create A Question</Button>
              </div>
            </>}
          {showMetricsModal === true &&
            <MetricsModal title={getCurrentMetricsTitle()} onSave={saveMetrics} open={showMetricsModal === true} onCancel={cancelMetrics} metrics={currentMetrics} metricTypes={metricTypes} />
          }
        </>}
    </>
  )
}


export default Metrics;
