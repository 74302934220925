import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import UserProfile from '../../../screens/UserProfile/UserProfile.js';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

import { getUser } from '../../../lib/api';

function User(props) {
  const { userId } = useParams();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ userProfile, setUserProfile ] = useState(null);

  useEffect(() => {
    getUser(userId)
      .then((data) => {
        console.log('data', data);
        setUserProfile(data);
      })
      .catch((err) => {
        console.error('err', err)
      })
      .finally(() => setIsLoading(false));
  }, [userId])

  return (
    <div className="">
      { isLoading ? <ProgressComponent /> : <>
        { userProfile ? <UserProfile profile={userProfile} />
        : <> No such user </>
        }
      </>}
    </div>
  )
}

export default User;
