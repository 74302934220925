import { useState, useEffect } from 'react';
import { Modal, Form, } from 'react-bulma-components';

function SurveyQuestionModal(props) {
  
  const [currentSurveyQuestion, setCurrentSurveyQuestion] = useState(props.surveyQuestion);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setCanSave(currentSurveyQuestion.description.trim());
  }, [currentSurveyQuestion])

  async function saveSurveyQuestion() {
      if (props.onSave) {
        props.onSave(currentSurveyQuestion);
      }
      else {
        console.error('No onSave function provided');
      }
  }
  async function cancel() {
    if (props.onCancel) {
      props.onCancel();
    }
    else {
      console.error('No onCancel function provided');
    }
}

  return (
    <Modal show={props.open} onClose={cancel}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">{props.title}</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
          <Form.Field>
              <Form.Label>Survey Question</Form.Label>
              <Form.Input autoFocus placeholder="Survey Question" value={currentSurveyQuestion.description} onChange={e => setCurrentSurveyQuestion({...currentSurveyQuestion,description: e.target.value})}></Form.Input>
        </Form.Field>
        <Form.Field>
        <Form.Label>Answer Type</Form.Label>
              <Form.Select value={currentSurveyQuestion.values} onChange={e => setCurrentSurveyQuestion({...currentSurveyQuestion,values: parseInt(e.target.value)})} >
                <option key="rating" value="1">Rating (1 to 5)</option>
                <option key="boolean" value="2">Yes/No/NA</option>
                <option key="text" value="3">Free text</option>
              </Form.Select>
        </Form.Field>
          </Modal.Card.Body>
          <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
            <button className="button is-success" disabled={!canSave} onClick={saveSurveyQuestion}>Save</button>
            <button className="button is-outlined" onClick={cancel}>Cancel</button>
          </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default SurveyQuestionModal;