import { useState, useEffect } from 'react';
import { Modal, Block, Form } from 'react-bulma-components';
import { sendFeedback } from '../../lib/api';

function FeedbackModal(props) {
  const [ feedbackType, setFeedbackType ] = useState('Question');
  const [ comments, setComments ] = useState('');
  const [ sent, setSent ] = useState(false);

  function send() {
    sendFeedback(feedbackType, comments);
    setSent(true);
  }

  useEffect(() => {
    if (props.open) {
      setSent(false);
      setComments('');
      setFeedbackType('Question');
    }
  }, [props.open]);

  return (
    <Modal showClose={false} show={props.open} onClose={() => props.onClose()}>
      <Modal.Card>
        <Modal.Card.Header showClose={false}>
          <p class="modal-card-title">Contact Us</p>
          {/* <button class="delete" aria-label="close" onClick={() => props.onClose()}></button> */}
        </Modal.Card.Header>
        { !sent ? <>
          <Modal.Card.Body>
            <Block>
            Thanks for reaching out to us!  Please fill out the form below and we'll get back to you as soon as we can.
            </Block>
              <Form.Field>
                <Form.Label>Topic</Form.Label>
                <Form.Select value={feedbackType} onChange={e => setFeedbackType(e.target.value)} >
                  <option key={`Question`} value="Question">Question</option>
                  <option key={`Comment`} value="Comment">Comment</option>
                  <option key={`PhenomenalWins`} value="PhenomenalWins">Phenomenal Wins</option>
                  <option key={`Other`} value="Other">Other</option>
                </Form.Select>
              </Form.Field>
              <Form.Field>
                <Form.Label>Comments</Form.Label>
                  <Form.Textarea placeholder="Enter your comments here..."
                    value={comments} onChange={e => setComments(e.target.value)}/>
              </Form.Field>
          </Modal.Card.Body>
          <Modal.Card.Footer class="modal-card-foot">
            <button class="button is-success" onClick={() => send()}>Send</button>
            <button class="button" onClick={() => props.onClose()}>Cancel</button>
          </Modal.Card.Footer>
        </>
        : <>
          <Modal.Card.Body>
            <Block>
            Thanks for reaching out to us!  We'll get back to you as soon as we can.
            </Block>
          </Modal.Card.Body>
          <Modal.Card.Footer class="modal-card-foot">
            <button class="button is-success" onClick={() => props.onClose()}>OK</button>
          </Modal.Card.Footer>
        </>}

      </Modal.Card>
    </Modal>
  )
};

export default FeedbackModal;