import { v4 as uuidv4 } from 'uuid';
import EmptyProfilePic from '../../assets/empty-profile.png';
import EinsteinProfilePic from '../../assets/Albert_Einstein_Head.jpg';
import config from '../../config'

function ProfilePicture(props) {

  function getDefaultProfilePic() {
    return (props.isMentor || props.useEmptyProfilePic) ? EmptyProfilePic : EinsteinProfilePic;
  }

  function getProfilePic() {
    if (props.picture) {
      let profileUrl = `${config.profileImagesUrl}${props.picture}?chk=${uuidv4()}`;
      return profileUrl;
    }
    else {
      return getDefaultProfilePic();
    }
  }

  return (
    <div style={{ height: '100%', width: '100%'}}>
    <img style={{ objectFit: 'contain', height: '100%' }} src={getProfilePic()} alt="Profile" onError={({ currentTarget }) => { 
      currentTarget.onerror = null;
      currentTarget.src = getDefaultProfilePic();
    }}/>
    </div>
  );
}

export default ProfilePicture;