function MentorshipStatusTag({statusDescription, wasAborted, style}) {

  let className = 'tag is-uppercase ';
  let text = statusDescription;

  if (wasAborted) className += 'is-danger';
  else if (statusDescription === 'green') className += 'is-success';
  else if (statusDescription === 'yellow') className += 'is-warning';
  else if (statusDescription === 'red') className += 'is-danger';
  else className += 'is-info';

  if (wasAborted) {
    text = 'ABORTED';
  }

  return <div style={style} className={className}>{text}</div>
}

export default MentorshipStatusTag;