import { Heading } from 'react-bulma-components';
import { Link } from 'react-router-dom';

function PageNotFound(props) {
  return (
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center" style={{height: '100vh'}}>
      <Heading style={{fontSize: 'xxx-large'}}>Sorry!</Heading>
      <Heading subtitle size="3">That page doesn't exist!</Heading>
      <Heading className="mt-4">We can take you back to <Link to="/">your dashboard</Link> though!</Heading>
    </div>
  )
}

export default PageNotFound;