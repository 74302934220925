import './ProgressComponent.scss';

function ProgressComponent(props) {
  if (!props.value) {
    return <progress className="progress is-small is-info progress-component" max="100" />
  }
  else {
    let textColor = 'initial';
    let percentComplete = parseInt(props.value) / parseInt(props.max || 100);

    if (percentComplete >= 0.5) {
      textColor = 'white';
    }
  
    return (
      <div className="progress-component-container" style={{color: textColor}} data-text={props.label}>
        <progress className="progress is-medium is-info" value={props.value} max={props.max || "100"} />
      </div>)
  }
}

export default ProgressComponent;