import './Bulma.scss';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import '@aws-amplify/ui-react/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import './lib/auth';

import React from 'react';
import Store from './store/Store';

import { ToastContainer } from 'react-toastify';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';

import Login from './screens/Login/Login';
import Signout from './screens/Signout/Signout';
// import Admin from './screens/Admin/Admin';
import PageNotFound from './screens/PageNotFound/PageNotFound';
import AdminDashboard from './screens/Admin/AdminDashboard/AdminDashboard';
import Mentorships from './screens/Admin/Mentorships/Mentorships';
import Mentorship from './screens/Admin/Mentorship/Mentorship';
import PromoCodes from './screens/Admin/PromoCodes/PromoCodes';
import Users from './screens/Admin/Users/Users';
import User from './screens/Admin/Users/User';
import Groups from './screens/Admin/Groups/Groups';
import Metrics from './screens/Admin/Metrics/Metrics';
import Referrals from './screens/Admin/Referrals/Referrals';
import Group from './screens/Admin/Groups/Group';

function App() {
  console.info(`Hi! This is v${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_ENV}`)
  const [ state, dispatch ] = React.useReducer(Store.reducer);

  return (
    <Authenticator.Provider>
      <Store.StoreProvider value={{state, dispatch}}>
        <ToastContainer autoClose={3000} pauseOnFocusLoss={false} />
        <MainApp />
      </Store.StoreProvider>
    </Authenticator.Provider>
  );
}

function MainApp() {

  return (
    <>
      <Router>
        <Switch>

          <Route path="/login">
            <Login />
          </Route>

          <Route path="/signout">
            <Signout />
          </Route>

          <Route path="/logout">
            <Signout />
          </Route>

          <ProtectedRoute path="/dashboard">
            <AdminDashboard />
          </ProtectedRoute>

          {/* <ProtectedRoute path="/admin">
            <Admin />
          </ProtectedRoute> */}

          {/* <ProtectedRoute exact path="/admin/pendingmatches">
              <PendingMatches />
            </ProtectedRoute> */}

            <ProtectedRoute exact path="/mentorships">
              <Mentorships />
            </ProtectedRoute>

            <ProtectedRoute exact path="/mentorships/:mentorshipId">
              <Mentorship />
            </ProtectedRoute>

            <ProtectedRoute exact path="/promocodes">
              <PromoCodes />
            </ProtectedRoute>
            
            <ProtectedRoute exact path="/users">
              <Users />
            </ProtectedRoute>

            <ProtectedRoute exact path="/groups/:groupId">
              <Group />
            </ProtectedRoute>

            <ProtectedRoute exact path="/groups">
              <Groups />
            </ProtectedRoute>

            <ProtectedRoute exact path="/metrics">
              <Metrics />
            </ProtectedRoute>

            <ProtectedRoute exact path="/referrals">
              <Referrals />
            </ProtectedRoute>

            <ProtectedRoute exact path="/users/:userId">
              <User />
            </ProtectedRoute>

          <ProtectedRoute path="/">
            <Redirect to="/dashboard" />
          </ProtectedRoute>

          <ProtectedRoute path="*">
            <PageNotFound />
          </ProtectedRoute>

        </Switch>
    </Router>
    </>
  )
}

export default App;