import config from '../../../config';
import { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import  { Level, Heading, Button } from 'react-bulma-components';
import { getGroups } from '../../../lib/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faLink } from '@fortawesome/free-solid-svg-icons'

import TernTable from '../../../components/TernTable/TernTable';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

function Groups(props) {
  const history = useHistory();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ tableData, setTableData ] = useState([]);
  const [ groupData, setGroupData ] = useState([]);
  const [ updateTable, setUpdateTable ] = useState(true);

  useEffect(() => {
    if (updateTable) {
      getGroups().then((data) => {
      console.log('data', data);
      setGroupData(data);
    })
    .catch((err) => {
      console.error('err', err)
    })
    .finally(() => { setIsLoading(false);setUpdateTable(false)});
  }}, [updateTable])

  useEffect(() => {
    
    groupData.sort((a,b) => a.name.localeCompare(b.name));
      setTableData(groupData.map((group) => { 
        return {
          id: group.id,
          name: group.name,
          description: group.description,
          companyName: group.companyName,
          companyWebsite: group.companyWebsite,
          color1: group.color1,
          color2: group.color2,
          signupCode: group.signupCode,
          enabled: group.enabled === true? 'Yes': 'No',
          rowDisabled: group.id === 1,
        }
      }));
  }, [ groupData])


  const data = useMemo(
    () => tableData,
    [tableData]
  )


  const columns = useMemo(
    () => [
      // {
      //   Header: 'Name',
      //   accessor: 'name', 
      // },
      // {
      //   Header: 'Description',
      //   accessor: 'description',
      // },
      {
        Header: 'Company name',
        accessor: 'companyName',
      },
      {
        Header: 'Company website',
        accessor: 'companyWebsite',
      },
      {
        Header: 'Signup Code',
        accessor: 'signupCode',
        Cell: props => <span>{props.value}<button className="ml-2" title="Copy Code" onClick={(e) => { copySignup(props.value, false); e.stopPropagation(); }}><FontAwesomeIcon icon={faCopy} /></button><button className="ml-2" title="Copy URL" onClick={(e) => { copySignup(props.value, true); e.stopPropagation(); }}><FontAwesomeIcon icon={faLink} /></button></span>
      },
      {
        Header: 'Enabled',
        accessor: 'enabled',
        collapse: true,
      },
    ],
    []
  )
  
  function copySignup(code, asUrl) {
    const toCopy = asUrl ? `${config.appUrl}signup?signupcode=${code}` : code;
    navigator.clipboard.writeText(toCopy);
    toast.success(`Signup ${asUrl ? 'URL' : 'code'} copied to clipboard`);
    return false;
  }

  function onTableRowClick(row){
    history.push(`/groups/${row.id}`);
  }

  function newGroup(){
    history.push(`/groups/new`);
  }

return (
  <>
    {isLoading ? <ProgressComponent /> :
      <div className="admin-group-page">
      <Level>
      <div className="level-left">
      <div className="level-item">
      <Heading>Cohorts</Heading>
      </div>
      </div>
      <div className="level-right">
      <Button className="is-success" onClick={newGroup}>Create Group</Button>
      </div>
      </Level>

        <TernTable hover={true} showTotal={false} columns={columns} data={data} initialSort={[{id: 'companyName', desc: false}]} onRowClick={onTableRowClick} />
      </div>
    }
  </>
  )
}

export default Groups;
