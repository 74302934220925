import logo from '../../assets/logo-horizontal.png';
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import { Redirect, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import ProgressComponent from '../../components/ProgressComponent/ProgressComponent';
import { I18n, Auth } from 'aws-amplify';
import { View, Button } from '@aws-amplify/ui-react';
import { getNonProdName } from '../../lib/auth';

const originalMessage="1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$";
const translatedMessage="Your password must be at least 8 characters and include at least 1 number, uppercase letter and lower case letter.";

I18n.putVocabularies({
  en: {
    [originalMessage]: [translatedMessage]
  }
});

function Login() {

  const { route } = useAuthenticator((context) => [context.user, context.route]);
  const [ redirectUrl, setRedirectUrl ] = useState();
  const [ processing, setProcessing ] = useState(false);
  const location = useLocation();

  const services = {
    async handleSignIn(formData) {
      const { username, password } = formData;
      try {
        const cognitoUser = await Auth.signIn({username, password});

        if (cognitoUser?.attributes?.['custom:isadmin'] === '1') {
          return Promise.resolve(cognitoUser);
        }
      }
      catch (err) {
        console.log('REJECTED!', err);
        return Promise.reject(err);
      }

      Auth.signOut();
      return Promise.reject(new Error('Incorrect username or password.'));
    }
  }

  const components = {
    Header() {
      return LogoBanner();
    },

    SignIn: {
      Footer() {
        const { toResetPassword } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button className="mb-3" fontWeight="normal" size="small" variation="link" onClick={toResetPassword}>
              Reset Password
            </Button>
          </View>
        );
      },
    },

    ConfirmResetPassword: {
      Header() {
        return (<>
          <h3 class="amplify-heading amplify-heading--3">Check your email</h3>
          We sent a six digit code to your email.<br/>Enter the code below along with your new password.
        </>)
      }
    },
  }

  const LogoBanner = () => {
    return (
      <div className="is-flex is-flex-direction-column is-align-items-center">
        <img style={{width: 300, marginTop: 60}} src={logo} alt="Logo" />
        <h1 className="has-text-weight-bold is-uppercase is-size-2 mb-6 has-text-centered">Admin Portal{ getNonProdName() && <span className="is-uppercase" style={{color: 'orange'}}><br/>{getNonProdName()} Server</span>}</h1>
      </div>
    )
  }

  useEffect(() => {
    let theRedirectUrl = (location.state?.from?.pathname + location.state?.from?.search) || '/';
    if (theRedirectUrl.includes('login') || theRedirectUrl.includes('logout') || theRedirectUrl.includes('signout')) theRedirectUrl = '/';

    async function getTheUser() {
      try {
        setRedirectUrl(theRedirectUrl);
      }
      catch (err) {
        if (err.response.status === 404) {
          console.error('LOGIN No user');
          setRedirectUrl('/signup');
          }
      }
      finally {
        setProcessing(false);
      }
    }

    if (route === 'authenticated') {
      setProcessing(true);
      getTheUser();
    }
  }, [route, location.state?.from?.pathname, location.state?.from?.search])


  return (
    <div className="is-flex is-justify-content-center is-align-items-center">
      { processing ?
          <div style={{width: '25%'}}><ProgressComponent /></div>
        : 
          <Authenticator services={services} components={components} loginMechanisms={['email']} hideSignUp={true}>
            {({ signOut, user }) => (
              <>{ redirectUrl ? <Redirect to={redirectUrl} /> : null}</>
            )}
          </Authenticator>
      }
    </div>
  )
}

export default Login;