import { useState } from 'react';
import { Modal, Form, } from 'react-bulma-components';

function UserPickModal(props) {
  const [currentUserId, setCurrentUserId] = useState('');

  function addUser() {
    if (props.onSwitchUser) {
      props.onSwitchUser(currentUserId);
    }
    else {
      console.error('No onSwitchUser function provided');
    }
  }
  async function cancel() {
    if (props.onCancel) {
      props.onCancel();
    }
    else {
      console.error('No onCancel function provided');
    }
  }
  return (
    <Modal show={props.open} onClose={cancel}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">Transfer User</p>
        </Modal.Card.Header>
        <Modal.Card.Body>
          This will move a user into this company/group from another.  Use this feature if a user is in the wrong company.  Only users that are not currently in a mentorship can be moved.
          <Form.Field className="mt-3">
            <Form.Label>User</Form.Label>
            <Form.Select className="is-fullwidth" onChange={e => setCurrentUserId(e.target.value)} placeholder='User' >
              <option key="phaseselect" value="Select" hidden>Select a User...</option>
              {props.users && props.users.length > 0 && props.users.map((user) =>
                <option key={`user_${user.id}`} value={user.id}>{user.fullName} ({user.companyName} - {user.email})</option>)
              }
            </Form.Select>
          </Form.Field>
        </Modal.Card.Body>
        <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
          <button className="button is-success" disabled={currentUserId === ''} onClick={addUser}>Transfer</button>
          <button className="button is-outlined" onClick={cancel}>Cancel</button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>

  )
};

export default UserPickModal;