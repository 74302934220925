import axios from 'axios';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {

  return new Promise((resolve) => {
    Auth.currentSession().then(session=>{
      let accessToken = session.getAccessToken();
      
      config.headers['Authorization'] = `Bearer ${accessToken.getJwtToken()}`;

      resolve(config);
    })
    .catch(() => {
      console.log('No JTW');
      resolve(config);
    })
  })
});

axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (err) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  
  // Some errors don't get the toast 
  if (err.response?.status === 404) {
    return Promise.reject(err);
  }

  // Others shall be toasted
  let text = err.message;
  if (err.response.status === 400) {
    text = 'Bad data'
  }

  text = `${text}.  Contact Tech Support.`;

  toast.error(text, { autoClose: 10000 });

  return Promise.reject(err);
});

function getEndpoint(isMentor, endpoint) {
  return `/api/${isMentor ? 'mentors' : 'mentees'}/${endpoint}`;
}

export function groupAnswersReport(groupId, audience, onlyMentorships) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.get(`/api/reports/groupanswerscsv?group=${groupId}&audience=${audience}&onlymentorships=${onlyMentorships}`, {responseType: 'arraybuffer',});

      resolve(response.data);
    }
    catch (err) {
      console.error('groupAnswersReport err', err);
      reject(err);
    }
  })
}

export function groupSurveyAnswersReport(groupId, audience) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.get(`/api/reports/groupsurveyanswerscsv?group=${groupId}&audience=${audience}`, {responseType: 'arraybuffer',});

      resolve(response.data);
    }
    catch (err) {
      console.error('groupSurveyAnswersReport err', err);
      reject(err);
    }
  })
}

export function groupOffboardingAnswersReport(groupId, audience) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.get(`/api/reports/groupoffboardinganswerscsv?group=${groupId}&audience=${audience}`, {responseType: 'arraybuffer',});

      resolve(response.data);
    }
    catch (err) {
      console.error('groupOffboardingAnswersReport err', err);
      reject(err);
    }
  })
}

export function uploadProfilePic(data) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.post('/api/profile/pic', data);

      console.log('uploadProfilePic res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('uploadProfilePic err', err);
      reject(err);
    }
  })
}
export function moveUserToGroup(contactId, groupId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/${groupId}/importcontacts/${contactId}`);

      console.log('getImportableUsers res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('addUserToGroup err', err);
      reject(err);
    }
  })
}

export function getImportableUsers(groupId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/importcontacts`);

      console.log('getImportableUsers res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getImportableUsers err', err);
      reject(err);
    }
  })
}

export function getCohortNotifications(groupId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/notificationtemplate`);

      console.log('getCohortNotifications res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getCohortNotifications err', err);
      reject(err);
    }
  })
}

export function updateCohortNotification(groupId, notifyType, subject, emailHtmlBody, smsBody) {
  return new Promise(async (resolve, reject) => {
    try {
      const dataToSend = [{
        notif_type: notifyType,
        email_subject: subject,
        email_html_body: emailHtmlBody,
        sms_body: smsBody,
      }];

      let response = await axiosInstance.patch(`/api/groups/${groupId}/notificationtemplate`, dataToSend);

      console.log('getCohortNotifications res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getCohortNotifications err', err);
      reject(err);
    }
  })
}

export function getPreviewNotificationText(groupId, templateText) {
  return new Promise(async (resolve, reject) => {
    try {
      const dataToSend = { templateText };

      let response = await axiosInstance.patch(`/api/groups/${groupId}/notificationpreview`, dataToSend);

      console.log('getPreviewNotificationText res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getPreviewNotificationText err', err);
      reject(err);
    }
  })
}

export function sendNotificationPreview(groupId, notifType, templateText, email, phoneNumber, subject) {
  return new Promise(async (resolve, reject) => {
    try {
      const dataToSend = {
        notifType, templateText, email, phoneNumber, subject
      };

      let response = await axiosInstance.patch(`/api/groups/${groupId}/notificationtest`, dataToSend);

      console.log('notificationtest res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('notificationtest err', err);
      reject(err);
    }
  })
}

export function getCompanyUsers(groupId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/contacts`);

      console.log('getCompanyUsers res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getCompanyUsers err', err);
      reject(err);
    }
  })
}
export function uploadBannerPic(groupId, data) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/${groupId}/bannerpic`, data);

      console.log('uploadBannerPic res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('uploadBannerPic err', err);
      reject(err);
    }
  })
}
export function setMentorshipFeedback(isMentor, mentorshipId, surveyData, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      const data = { 
        ...surveyData
      }

      let response = await axiosInstance.post(`/api/mentorship/${mentorshipId}/${isMentor ? 'mentorfeedback' : 'menteefeedback'}`, data);

      resolve(response.data);
    }
    catch (err) {
      console.error('setSessionSurvey err', err);
      reject(err);
    }
  })
}

export function setSessionSurvey(sessionId, surveyData, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      const data = { 
        ...surveyData
      }

      let response = await axiosInstance.post(`/api/sessions/${sessionId}/survey`, data);

      resolve(response.data);
    }
    catch (err) {
      console.error('setSessionSurvey err', err);
      reject(err);
    }
  })
}

export function getSession(sessionId, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      let response = await axiosInstance.get(`/api/sessions/${sessionId}`);

      console.log('get res:', response);

      if (dispatch) {
        dispatch( { type: 'SET_SESSION', payload: response.data });
      }

      resolve(response.data);
    }
    catch (err) {
      console.error('setSessionSurvey err', err);
      reject(err);
    }
  })
}

export function scheduleSession(sessionId, sessionDateString, sendInvite, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {

      const data = {
        sessionDate: sessionDateString,
        sendInvite: sendInvite,
      }

      let response = await axiosInstance.patch(`/api/sessions/${sessionId}/schedule`, data);

      if (dispatch) {
        dispatch( { type: 'SET_SESSION_DATE', payload: { sessionId: sessionId, ...data }});
      }

      resolve(response.data);
    }
    catch (err) {
      console.error('scheduleSession err', err);
      reject(err);
    }
  })
}

export function patchUser(isMentor, user, data, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!user) {
        reject(Error('Missing user'))
        return;
      }

      let endpoint = '';
      let userTypeId = user?.activeProfile?.contactType?.id;
      if (userTypeId) {
        switch (userTypeId) {
          case 1:
            endpoint = 'mentees';
            break;

          case 2:
            endpoint = 'mentors';
            break;

          default:
            endpoint = '';
        }
      }

      if (endpoint === '') {
        reject(Error('Unknown user type'));
        return;
      }

      let response = await axiosInstance.patch(`/api/${endpoint}/${user.contactId}`, data);

      console.log('patch res:', response);

      if (dispatch) {
        dispatch( { type: 'SET_USER', payload: response.data });
      }

      resolve(response.data);
    }
    catch (err) {
      console.error('patchUser err', err);
      reject(err);
    }
  })
}

export function createUser(asMentor, cognitoUserId, data, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      const endpoint = asMentor ? 'mentors' : 'mentees';
      let response = await axiosInstance.post(`/api/${endpoint}/${cognitoUserId}`, data);

      if (dispatch) {
        dispatch( { type: 'SET_USER', payload: response.data });
      }

      resolve(response.data);
    }
    catch (err) {
      console.error('createUser err', err);
      reject(err);
    }
  })
}

export function getMe() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/me`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMe err', err);
      reject(err);
    }
  })
}

export function getUser(id) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/contacts/${id}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getUser err', err);
      reject(err);
    }
  })
}

export function addGroup(data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('addGroup err', err);
      reject(err);
    }
  })
}

export function setNewPageOrder(groupId, pageOneId, pageOneOrderNum, pageTwoId, pageTwoOrderNum) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/metricpagesorder`, {pageOneId, pageOneOrderNum, pageTwoId, pageTwoOrderNum});

      resolve(response.data);
    }
    catch (err) {
      console.error('getMetricTypes err', err);
      reject(err);
    }
  })
}

export function getNotificationTypeNames(groupId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/notificationnames`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getNotificationTypeNames err', err);
      reject(err);
    }
  })
}

export function getMetricTypes(){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/metrictypes`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMetricTypes err', err);
      reject(err);
    }
  })
}

export function addGroupMetrics(groupId,data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/${groupId}/metrics`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('addGroupMetrics err', err);
      reject(err);
    }
  })
}

export function editGroupMetrics(groupId,data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/metrics`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('editGroupMetrics err', err);
      reject(err);
    }
  })
}

export function createMetrics(data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/metrics`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('createMetrics err', err);
      reject(err);
    }
  })
}


export function updateMetrics(metricsId,data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/metrics/${metricsId}`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateMetrics err', err);
      reject(err);
    }
  })
}


export function swapGroupMetrics(groupId, pageId, sourceQuestionId, destQuestionId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/metricpages/${pageId}/order`, { source: sourceQuestionId, dest: destQuestionId });

      resolve(response.data);
    }
    catch (err) {
      console.error('swapGroupMetrics err', err);
      reject(err);
    }
  })
}

export function updateGroupMetricsOrderNum(metricsId, groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/metrics/${metricsId}`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateGroupMetricsOrderNum err', err);
      reject(err);
    }
  })
}
export function updateGroupSurveyQuestion(surveyId, groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/surveys/${surveyId}`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateGroupSurveyQuestion err', err);
      reject(err);
    }
  })
}

export function getOnboardingQuestionsPages(groupId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/metricpages`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getOnboardingQuestionsPages err', err);
      reject(err);
    }
  })
}

export function addGroupMetricsPage(groupId, page){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/${groupId}/metricpages`, page);

      resolve(response.data);
    }
    catch (err) {
      console.error('addGroupMetricsPage err', err);
      reject(err);
    }
  })
}

export function editGroupMetricsPage(groupId, pageId, page){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/metricpages/${pageId}`,page);

      resolve(response.data);
    }
    catch (err) {
      console.error('getOnboardingQuestions err', err);
      reject(err);
    }
  })
}

export function deleteGroupMetricsPage(groupId, pageId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.delete(`/api/groups/${groupId}/metricpages/${pageId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getOnboardingQuestions err', err);
      reject(err);
    }
  })
}

export function getOnboardingQuestions(groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/metrics`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('getOnboardingQuestions err', err);
      reject(err);
    }
  })
}
export function getGroupSurveys(groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/surveys`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('getGroupSurveys err', err);
      reject(err);
    }
  })
}
export function createGroupSurveyQuestion(groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/${groupId}/surveys`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateGroup err', err);
      reject(err);
    }
  })
}

export function deleteMetrics(metricsId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.delete(`/api/metrics/${metricsId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('deleteMetrics err', err);
      reject(err);
    }
  })
}

export function removeGroupMetrics(metricsId, groupId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.delete(`/api/groups/${groupId}/metrics/${metricsId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('removeGroupMetrics err', err);
      reject(err);
    }
  })
}
export function deleteGroupSurveyQuestion(surveyQuestionId, groupId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.delete(`/api/groups/${groupId}/surveys/${surveyQuestionId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('deleteGroupSurveyQuestion err', err);
      reject(err);
    }
  })
}


export function getGroupSessions(groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/sessions`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateGroup err', err);
      reject(err);
    }
  })
}


export function deleteGroupSession(sessionId, groupId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.delete(`/api/groups/${groupId}/sessions/${sessionId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateGroup err', err);
      reject(err);
    }
  })
}

export function createGroupSession(groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/${groupId}/sessions`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateGroup err', err);
      reject(err);
    }
  })
}


export function updateGroupSession(sessionId, groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/sessions/${sessionId}`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateGroup err', err);
      reject(err);
    }
  })
}

export function updateGroup(id,data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${id}`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updateGroup err', err);
      reject(err);
    }
  })
}


export function getGroup(id) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${id}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getGroup err', err);
      reject(err);
    }
  })
}

export function getPossibleManualMatches() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/manualmatch`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getPossibleManualMatches err', err);
      reject(err);
    }
  })
}

export function getMatched() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/matched`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMatched err', err);
      reject(err);
    }
  })
}

export function getMatchRuns() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/matchruns`);

      console.log('matchruns res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('matchruns err', err);
      reject(err);
    }
  })
}

export function getMentees() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/mentees`);

      console.log('getMentees res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMentees err', err);
      reject(err);
    }
  })
}

export function getMentors(forMatching) {
  return new Promise(async (resolve, reject) => {
    try {
      let queryParams = {
        forMatching,
      };

      let response = await axiosInstance.get(`/api/mentors?${queryString.stringify(queryParams)}`);

      console.log('getMentors res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMentors err', err);
      reject(err);
    }
  })
}

export function getMatches(isMentor, id) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(getEndpoint(isMentor, `${id}/matched`));

      console.log('getMatches res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMatches err', err);
      reject(err);
    }
  })
}

export function getMatchesByRunDate(runDate) {
  return new Promise(async (resolve, reject) => {
    try {
      let queryParams = {
        matchrundate: runDate.toISOString()
      };

      let response = await axiosInstance.get(`/api/matches?${queryString.stringify(queryParams)}`);

      console.log('getMatchesByRunDate res:', response);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMatchesByRunDate err', err);
      reject(err);
    }
  })
}

export function getMetrics() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/metrics');

      resolve(response.data);
    }
    catch (err) {
      console.error('getMetrics err', err);
      reject(err);
    }
  })
}
export function getGroups() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/groups');

      resolve(response.data);
    }
    catch (err) {
      console.error('getGroups err', err);
      reject(err);
    }
  })
}

export function getReferrers() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/referrer_report');

      resolve(response.data);
    }
    catch (err) {
      console.error('getReferrers err', err);
      reject(err);
    }
  })
}


export function getUsers() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/contacts');

      resolve(response.data);
    }
    catch (err) {
      console.error('getUsers err', err);
      reject(err);
    }
  })
}

export function getAdminDashboardReport() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/admin_report');

      resolve(response.data);
    }
    catch (err) {
      console.error('getAdminDashboardReport err', err);
      reject(err);
    }
  })
}

export function getStaticOptions() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/static_values');

      resolve(response.data);
    }
    catch (err) {
      console.error('getStaticOptions err', err);
      reject(err);
    }
  })
}

export function rollMatch(groupId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/match',
        {
          "groupId": groupId,
          "matchCriteria":["enneagram","location","language","businessInterest","personalInterest","major","minor","university"]        
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('getStaticOptions err', err);
      reject(err);
    }
  })
}

export function sendFeedback(feedbackType, comment) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/feedback',
        {
          feedbackType:feedbackType,
          comment: comment,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('sendFeedback err', err);
      reject(err);
    }
  })
}

export function getPricing() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/pricing');

      resolve(response.data);
    }
    catch (err) {
      console.error('getPricing err', err);
      reject(err);
    }
  })
}

export function startPaymentIntent() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/startPaymentIntent');

      resolve(response.data);
    }
    catch (err) {
      console.error('startPaymentIntent err', err);
      reject(err);
    }
  })
}

export function startMenteePaymentIntent() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/startMenteePaymentIntent');

      resolve(response.data);
    }
    catch (err) {
      console.error('startMenteePaymentIntent err', err);
      reject(err);
    }
  })
}

export function submitMenteePaymentIntent(indentId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/submitMenteePaymentIntent',
        {
          id: indentId,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('submitMenteePaymentIntent err', err);
      reject(err);
    }
  })
}

export function completeMenteePaymentIntent(indentId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/completeMenteePaymentIntent',
        {
          id: indentId,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('completeMenteePaymentIntent err', err);
      reject(err);
    }
  })
}

export function updatePaymentIntentForUser(indentId, productId, donationAmount) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/updatePaymentIntentForUser',
        {
          id: indentId,
          productId: productId,
          donationAmount: donationAmount,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('updatePaymentIntentForUser err', err);
      reject(err);
    }
  })
}

export function completePaymentIntentForUser(indentId, productId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/completePaymentIntentForUser',
        {
          id: indentId,
          productId: productId,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('completePaymentIntentForUser err', err);
      reject(err);
    }
  })
}

export function updatePaymentIntentForGuest(indentId, donationAmount, name, email) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/updatePaymentIntentForGuest',
        {
          id: indentId,
          donationAmount: donationAmount,
          name: name,
          email: email,
        });

      resolve(response.data);
    }
    catch (err) {
      console.error('updatePaymentIntentForGuest err', err);
      reject(err);
    }
  })
}

export function getPendingMatches() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/matches/pending');

      resolve(response.data);
    }
    catch (err) {
      console.error('getPendingMatches err', err);
      reject(err);
    }
  })
}
export function getPromoCodes() {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get('/api/promo');

      resolve(response.data);
    }
    catch (err) {
      console.error('getPromoCodes err', err);
      reject(err);
    }
  })
}
export function getPromoCode(promoCode) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/promo/${promoCode}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getPromoCode err', err);
      reject(err);
    }
  })
}
export function addPromoCode(promoCode,data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/promo/${promoCode}`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('addPromoCode err', err);
      reject(err);
    }
  })
}

export function updatePromoCode(promoCode,data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/promo/${promoCode}`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('updatePromoCode err', err);
      reject(err);
    }
  })
}

export function abortMentorship(mentorshipId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/mentorships/${mentorshipId}/abort`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMentorship err', err);
      reject(err);
    }
  })
}

export function getMentorship(mentorshipId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/mentorships/${mentorshipId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMentorship err', err);
      reject(err);
    }
  })
}

export function getMentorships(groupId) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = groupId ? `groups/${groupId}/mentorships` : `mentorships`;
      let response = await axiosInstance.get(`/api/${url}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getMentorships err', err);
      reject(err);
    }
  })
}

export function manualMatch(mentorId, menteeId, menteeFree) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post('/api/manualmatch', { mentorId: mentorId, menteeId: menteeId, menteeFree: menteeFree });

      resolve(response.data);
    }
    catch (err) {
      console.error('manualMatch err', err);
      reject(err);
    }
  })
}

export function getUserActivity(contactId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/contacts/${contactId}/activities`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getUserActivity err', err);
      reject(err);
    }
  })
}

export function getOffboardingQuestionsPages(groupId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/offboardingpages`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getOffboardingQuestionsPages err', err);
      reject(err);
    }
  })
}

export function addOffboardingQuestionsPage(groupId, page){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/${groupId}/offboardingpages`, page);

      resolve(response.data);
    }
    catch (err) {
      console.error('addOffboardingQuestionsPage err', err);
      reject(err);
    }
  })
}

export function editOffboardingQuestionsPage(groupId, pageId, page){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/offboardingpages/${pageId}`,page);

      resolve(response.data);
    }
    catch (err) {
      console.error('editOffboardingQuestionsPage err', err);
      reject(err);
    }
  })
}

export function deleteOffboardingQuestionsPage(groupId, pageId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.delete(`/api/groups/${groupId}/offboardingpages/${pageId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('getOnboardingQuestions err', err);
      reject(err);
    }
  })
}

export function swapOffboardingQuestionsPages(groupId, pageOneId, pageTwoId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/offboardingpagesorder`, {pageOneId, pageTwoId});

      resolve(response.data);
    }
    catch (err) {
      console.error('swapOffboardingQuestionsPages err', err);
      reject(err);
    }
  })
}

// Offboarding questions
export function swapOffboardingQuestions(groupId, questionOneId, questionTwoId) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/offboardingquestionsorder`, {questionOneId, questionTwoId});

      resolve(response.data);
    }
    catch (err) {
      console.error('swapOffboardingQuestionsPages err', err);
      reject(err);
    }
  })
}

export function getOffboardingQuestions(groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/groups/${groupId}/offboardingquestions`,data);

      resolve(response.data);
    }
    catch (err) {
      console.error('getOffboardingQuestions err', err);
      reject(err);
    }
  })
}

export function addOffboardingQuestionToGroup(groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`/api/groups/${groupId}/offboardingquestions`, data);

      resolve(response.data);
    }
    catch (err) {
      console.error('addOffboardingQuestionToGroup err', err);
      reject(err);
    }
  })
}

export function editOffboardingQuestionForGroup(groupId, data){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.patch(`/api/groups/${groupId}/offboardingquestions`, data);

      resolve(response.data);
    }
    catch (err) {
      console.error('editOffboardingQuestionForGroup err', err);
      reject(err);
    }
  })
}

export function removeOffboardingQuestion(questionId, groupId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.delete(`/api/groups/${groupId}/offboardingquestions/${questionId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('removeOffboardingQuestion err', err);
      reject(err);
    }
  })
}

export function profileOnboardingAnswers(contactId, groupId){
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`/api/contacts/${contactId}/onboarding?groupid=${groupId}`);

      resolve(response.data);
    }
    catch (err) {
      console.error('removeOffboardingQuestion err', err);
      reject(err);
    }
  })
}