import { useHistory, useParams } from 'react-router-dom';
import GroupComponent from '../../../components/Group/Group'

function Group(props) {
  const history = useHistory();
  const { groupId } = useParams();
  const groupIdToUse = groupId === 'new' ? null : groupId;

  function onNewGroup(newGroup) {
    // Navigate to the edit page
    history.push(`/groups/${newGroup.id}`);
  }
  
  function onUpdateGroup(updatedGroup) {
    history.push(`/groups`);
  }

  return <div className="mb-6"><GroupComponent groupId={groupIdToUse} onCreateGroup={onNewGroup} onUpdateGroup={onUpdateGroup} onCancel={() => history.push(`/groups`)} /></div>;

}

export default Group;
