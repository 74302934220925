import './BannerPicture.scss';
import { v4 as uuidv4 } from 'uuid';
import DefaultBannerPic from '../../assets/placeholder_banner_pic.jpg';
import config from '../../config';

function BannerPicture(props) {
  
  function getLinearGradient() {
    const otherColor = props.color2 || props.color1;
    return `linear-gradient(90deg, ${props.color1} 0%, ${otherColor} 100%)`;
  }

  function getBannerPic() {
    if (props.picture) {
      let bannerUrl = `${config.s3Root}${props.picture}?chk=${uuidv4()}`;
      return bannerUrl;
    }
    else {
      return DefaultBannerPic;
    }
  }

  return (<>
    { props.color1 && <div style={{ width: '100%', height: 20, background: getLinearGradient() }}></div> }
    <div className="is-flex is-justify-content-center" style={{ position: 'relative', width: '100%', height: 300, backgroundColor: '#efefef' }}>
      <div style={{ height: '100%', width: '100%'}}>
        <img className="banner-picture-img" src={getBannerPic()} alt="Banner" onError={({ currentTarget }) => { 
          currentTarget.onerror = null;
          currentTarget.src = DefaultBannerPic;
        }}/>
      </div>
      { props.onChangeClick && <button className="button m-2 is-info" style={{ position: 'absolute', left: 0, bottom: 0 }} onClick={props.onChangeClick}>Change...</button> }
    </div>
    { props.color1 && <div style={{ width: '100%', height: 20, background: getLinearGradient() }}></div> }
    </>
  );
}

export default BannerPicture;