import { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from "react-router-dom";
import { Heading, Button, Columns } from 'react-bulma-components';
import { getMentorship, abortMentorship } from '../../../lib/api';
import { notProvidedString, defaultDateString, defaultDateTimeString } from '../../../lib/stringHelpers.js';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';
import ProfilePicture from '../../../components/ProfilePicture/ProfilePicture';
import ContactInfo from '../../../components/ContactInfo/ContactInfo';
import MentorshipStatusTag from '../../../components/MentorshipStatusTag/MentorshipStatusTag';
import SessionStatusTable from './SessionStatusTable.js';
import { toast } from 'react-toastify';

function Mentorship(props) {
  const { mentorshipId } = useParams();
  const history = useHistory();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ mentorshipData, setMentorshipData ] = useState(true);

  useEffect(() => {
    getMentorship(mentorshipId).then((data) => {
      console.log('mentorship data', data);
      setMentorshipData(data);
    })
    .catch((err) => {
      console.error('err', err)
    })
    .finally(() => setIsLoading(false));
  }, [mentorshipId])

  async function doAbortMentorship() {
    if (window.confirm('WARNING!  Are you sure you want to cancel and abort this mentorship?')) {
      try {
        setIsLoading(true);
        await abortMentorship(mentorshipId);
        toast.success('Mentorship aborted');
        history.push('/mentorships');
      }
      catch (err) {
        console.error('Error aborting mentorship', err);
        toast.error('Mentorship could not be aborted');
        setIsLoading(false);
      }
    }
  }

  const StateTag = ((props) => {
    let tagColor = ' ';
    let stateText = 'Unknown';

    if (!mentorshipData.completion_date && !mentorshipData.aborted_date) {
      tagColor = 'is-success';
      stateText = 'In progress';
    }
    else if (mentorshipData.completion_date) {
      tagColor = 'is-info';
      stateText = 'Completed';
    }

    return <div className={`tag ${tagColor}`}>{stateText}</div>;
  });

  const UserInfoSection = (({user, isMentor}) => {
    return (<>
      <Heading size={4}>{ user.firstName} {user.lastName}</Heading>
      <Heading size={5} subtitle>{ isMentor ? 'Mentor' : 'Mentee'}</Heading>
      <div className="mb-3" style={{height: 250, width: 250}}>
        <ProfilePicture picture={user.profilePic} isMentor={isMentor} />
      </div>
      <div>
        <ContactInfo profile={user} />
      </div>
      <Link to={`/users/${user.contactId}`}>View Full Profile</Link>
    </>)
  })

  const EndResult = () => {
    if (!mentorshipData.aborted_date && !mentorshipData.completion_date)
      return <li><label>Completed On:</label>In progress</li>;
    else if (!mentorshipData.aborted_date && mentorshipData.completion_date)
      return <li><label>Completed On:</label>{defaultDateString(mentorshipData.completion_date)}</li>;
    else if (mentorshipData.aborted_date)
      return <li><label>Aborted On:</label>{defaultDateTimeString(mentorshipData.aborted_date)}</li>;
    else if (mentorshipData.aborted_date)
      return <li><label>Aborted Reason:</label>{notProvidedString(mentorshipData.aborted_reason)}</li>;
    
    return <li></li>;
  }

  return (
  <>
    { isLoading ? <ProgressComponent /> :
      <div>
        <div className="is-flex is-justify-content-space-between is-align-items-center">
          <div>
            <Heading className="mb-2">Mentorship</Heading>
            <div className="is-flex">
              { !mentorshipData.aborted_date && <div className="mr-3"><StateTag /></div> }
              { !mentorshipData.completion_date && <MentorshipStatusTag statusDescription={mentorshipData.mentorship_status.description} wasAborted={mentorshipData.aborted_date}/> }
            </div>
            <div className="is-flex">
              <div className="info-block constrain-info-block pl-0 pb-0 mr-5">
                <ul>
                  <li><label>Group:</label>{mentorshipData.mentee.group.name}</li>
                  <li><label>Duration:</label>{mentorshipData.mentorshipDuration} weeks</li>
                </ul>
              </div>
              <div className="info-block constrain-info-block pl-0 pb-0">
                <ul>
                  <li><label>Started On:</label>{defaultDateString(mentorshipData.creation_date)}</li>
                  <EndResult />
                </ul>
              </div>
            </div>
          </div>
          <div className="is-flex is-align-items-center">
            { !mentorshipData.completion_date && !mentorshipData.aborted_date && <Button className="is-danger is-outlined" onClick={() => doAbortMentorship()}>Abort</Button>}
          </div>
        </div>
        <hr/>
        <Columns>
          <Columns.Column>
            <UserInfoSection user={mentorshipData.mentor} isMentor={true} />
          </Columns.Column>
          <Columns.Column>
            <UserInfoSection user={mentorshipData.mentee} isMentor={false} />
          </Columns.Column>
        </Columns>

        < hr />
     
        {/* <div className="info-block constrain-info-block pl-0">
          <Heading size={4} className="has-text-grey">Details</Heading>
          <ul>
            <li><label>Status:</label><MentorshipStatusTag statusDescription={mentorshipData.mentorship_status.description} wasAborted={mentorshipData.aborted_date}/></li>
            <li><label>Started On:</label>{defaultDateString(mentorshipData.creation_date)}</li>
            {!mentorshipData.aborted_date && !mentorshipData.completion_date && <li><label>Completed On:</label>In progress</li>}
            {!mentorshipData.aborted_date && mentorshipData.completion_date && <li><label>Completed On:</label>{defaultDateString(mentorshipData.completion_date)}</li>}
            {mentorshipData.aborted_date && <li><label>Aborted On:</label>{defaultDateTimeString(mentorshipData.aborted_date)}</li>}
            {mentorshipData.aborted_date && <li><label>Aborted Reason:</label>{notProvidedString(mentorshipData.aborted_reason)}</li>}
          </ul>
        </div>

        <hr/> */}
        
        <div>
          <Heading size={4} className="has-text-grey">Sessions</Heading>
          <SessionStatusTable sessions={mentorshipData.sessions} />
        </div>
    </div>}
  </>)

}

export default Mentorship;
