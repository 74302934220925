import { useState, useEffect } from 'react';
import { Modal, Form, } from 'react-bulma-components';

function SessionModal(props) {
  
  const [currentSession, setCurrentSession] = useState(props.session);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setCanSave(currentSession.title.trim() && currentSession.description.trim());
  }, [currentSession])

  async function saveSession() {
      if (props.onSave) {
        props.onSave(currentSession);
      }
      else {
        console.error('No onSave function provided');
      }
  }
  async function cancel() {
    if (props.onCancel) {
      props.onCancel();
    }
    else {
      console.error('No onCancel function provided');
    }
}
async function deleteSession() {
  if (props.onDelete) {
    props.onDelete(currentSession);
  }
  else {
    console.error('No onDelete function provided');
  }
}

  return (
    <Modal show={props.open} onClose={cancel}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">Session #{currentSession.num}</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
          <Form.Field>
              <Form.Label>Title</Form.Label>
              <Form.Input autoFocus placeholder="Session title" value={currentSession.title} onChange={e => setCurrentSession({...currentSession,title: e.target.value})}></Form.Input>
        </Form.Field>
        <Form.Field>
        <Form.Label>Description / Goals</Form.Label>
          <textarea className="textarea" placeholder="Describe the purpose and/or goals of this session" value={currentSession.description} onChange={e => setCurrentSession({...currentSession,description: e.target.value})}></textarea>
          </Form.Field>
          </Modal.Card.Body>
          <Modal.Card.Footer className="modal-card-foot field is-grouped is-grouped-right">
            {/* <button className="button is-danger" disabled = {currentSession.id === 0} onClick={deleteSession}>Delete</button> */}
            <button className="button is-success" disabled={!canSave} onClick={saveSession}>Save</button>
            <button className="button is-outlined" onClick={cancel}>Cancel</button>
          </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default SessionModal;